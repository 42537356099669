import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader/Loader';
import g from '../../assets/scss/Main.module.scss';
import s from './index.module.scss';
import { useLocation, useHistory } from 'react-router-dom';
import FormContainer from '../../components/Forms/FormContainer';
import { useDispatch, useSelector } from 'react-redux';
import { startAddCompnay, resetSuccessCreate } from '../../redux/LegalFaces/legalFacesReducer';
import { PrimaryButton } from '@fluentui/react';
import { NEW_COMPANY_VALIDATION_SCHEMA } from '../../constants/VALIDATION_SCHEME';
import { Alert } from '../../components/Alert/Alert';
import CompanyConfig from './components/CompanyConfig';
import { useCrumbsContext } from '../../context/CrumbsContext';
import {
  getSuccessCreate,
  getCompanyInfo,
  getIsLoadingCompany
} from '../../redux/LegalFaces/legalFacesSelectors';

const AddCompany = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingCompany);
  const location = useLocation();
  const { setCrumbs } = useCrumbsContext();
  const [showAlert, setShowAlert] = useState(false);
  const isSuccessCreate = useSelector(getSuccessCreate);
  const companyInfo = useSelector(getCompanyInfo);
  const history = useHistory();

  useEffect(() => {
    if (isSuccessCreate) setShowAlert(true);
  }, [isSuccessCreate]);

  useEffect(() => {
    setCrumbs([location.pathname.slice(0, location.pathname.lastIndexOf('/')), 'Добавить юрлицо']);
  }, []);

  const initialValues = {
    name: ''
  };

  const onSubmit = (data) => {
    dispatch(startAddCompnay(data));
  };

  const handleCloseSuccess = () => {
    setShowAlert(false);
    dispatch(resetSuccessCreate());
    if (companyInfo.id) history.push(`/settings/legal/${companyInfo.id}`);
  };

  return (
    <div className={g.contentWrapper}>
      {showAlert && (
        <Alert
          title="Сохранение юридического лица"
          text="Юридическое лицо сохранено успешно"
          onClose={handleCloseSuccess}
        />
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={g.titleWrapper}>
            <h1 className={g.title}>Добавить юридическое лицо</h1>
          </div>

          <FormContainer
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            className={s.form}
            validationSchema={NEW_COMPANY_VALIDATION_SCHEMA}
          >
            {(formik) => {
              const { isValid } = formik;
              return (
                <>
                  <CompanyConfig isAdding />
                  <PrimaryButton
                    type="submit"
                    className={s.btn}
                    disabled={!isValid}
                    text="Сохранить"
                  />
                </>
              );
            }}
          </FormContainer>
        </>
      )}
    </div>
  );
};

export default AddCompany;
