export const printingTableHeader = [
  { name: 'productName', title: 'Продукт', maxLength: 15, width: '20', sortable: true },
  { name: 'partNumber', title: 'Задание', width: '20', sortable: true },
  { name: 'creationDate', title: 'Дата создания', width: '20', sortable: true },
  { name: 'archivingDate', title: 'Дата архивирования', width: '20', sortable: true },
  { name: 'sendingDate', title: 'Дата отправки', width: '20', sortable: true },
  { name: 'productCreationDate', title: 'Дата производства', width: '20', sortable: true },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '20', sortable: true },
  { name: 'productGtin', title: 'Gtin', width: '20', sortable: true },
  { name: 'status', title: 'Статус', width: '20', sortable: true, minWidth: 120 },
  { name: 'suzId', title: 'Код отчёта', maxLength: 10, width: '20', sortable: true },
  { name: 'serialNumber', title: 'Серия', width: '20', sortable: true },
  { name: 'unarchiveButton', title: 'Действие', minWidth: '100', width: '20', sortable: false }
];

export const aggregationTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10, width: '20', sortable: true },
  { name: 'partNumber', title: 'Задание', width: '20', sortable: true },
  { name: 'unitSerialNumber', title: 'Код агрегата', maxLength: 10, width: '20', sortable: true },
  { name: 'creationDate', title: 'Дата создания', width: '20', sortable: true },
  { name: 'archivingDate', title: 'Дата архивирования', width: '20', sortable: true },
  { name: 'sendingDate', title: 'Дата отправки', width: '20', sortable: true },
  { name: 'status', title: 'Статус', width: '20', sortable: true, minWidth: 120 },
  { name: 'productName', title: 'Продукт', maxLength: 15, width: '20', sortable: true },
  { name: 'productGtin', title: 'Gtin', width: '20', sortable: true },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '20', sortable: true },
  { name: 'serialNumber', title: 'Серия', width: '20', sortable: true },
  { name: 'unarchiveButton', title: 'Действие', minWidth: '100', width: '20', sortable: false }

];

export const enteringTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10, width: '20', sortable: true },
  { name: 'partNumber', title: 'Задание', width: '20', sortable: true },
  { name: 'creationDate', title: 'Дата создания', width: '20', sortable: true },
  { name: 'archivingDate', title: 'Дата архивирования', width: '20', sortable: true },
  { name: 'sendingDate', title: 'Дата отправки', width: '20', sortable: true },
  { name: 'productCreationDate', title: 'Дата производства', width: '20', sortable: true },
  { name: 'status', title: 'Статус', width: '20', sortable: true, minWidth: 120 },
  { name: 'productName', title: 'Продукт', maxLength: 15, width: '20', sortable: true },
  { name: 'productGtin', title: 'Gtin', width: '20', sortable: true },
  { name: 'vsd', title: 'ВСД', width: '20', sortable: true },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '20', sortable: true },
  { name: 'serialNumber', title: 'Серия', width: '20', sortable: true },
  { name: 'unarchiveButton', title: 'Действие', minWidth: '100', width: '20', sortable: false }
];

export const rejectTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10, width: '20', sortable: true },
  { name: 'partNumber', title: 'Задание', width: '20', sortable: true },
  { name: 'creationDate', title: 'Дата создания', width: '20', sortable: true },
  { name: 'archivingDate', title: 'Дата архивирования', width: '20', sortable: true },
  { name: 'sendingDate', title: 'Дата отправки', width: '20', sortable: true },
  { name: 'status', title: 'Статус', width: '20', sortable: true, minWidth: 120 },
  { name: 'dropoutReason', title: 'Причины отсева', width: '20', sortable: true },
  { name: 'productName', title: 'Продукт', width: '20', sortable: true },
  { name: 'productGtin', title: 'Gtin', width: '20', sortable: true },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '20', sortable: true },
  { name: 'serialNumber', title: 'Серия', width: '20', sortable: true },
  { name: 'unarchiveButton', title: 'Действие', minWidth: '100', width: '20', sortable: false }
];

export const setsTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10, width: '20', sortable: true },
  { name: 'partNumber', title: 'Задание', width: '20', sortable: true },
  { name: 'creationDate', title: 'Дата создания', width: '20', sortable: true },
  { name: 'archivingDate', title: 'Дата архивирования', width: '20', sortable: true },
  { name: 'sendingDate', title: 'Дата отправки', width: '20', sortable: true },
  { name: 'productCreationDate', title: 'Дата производства', width: '20', sortable: true },
  { name: 'status', title: 'Статус', width: '20', sortable: true, minWidth: 120 },
  { name: 'productName', title: 'Продукт', maxLength: 15, width: '20', sortable: true },
  { name: 'productGtin', title: 'Gtin', maxLength: 10, width: '20', sortable: true },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '20', sortable: true },
  { name: 'serialNumber', title: 'Серия', width: '20', sortable: true },
  { name: 'unarchiveButton', title: 'Действие', minWidth: '100', width: '20', sortable: false }
];

export const withdrInternConsupmTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10, width: '20', sortable: true },
  { name: 'partNumber', title: 'Задание', width: '20', sortable: true },
  { name: 'creationDate', title: 'Дата создания', width: '20', sortable: true },
  { name: 'archivingDate', title: 'Дата архивирования', width: '20', sortable: true },
  { name: 'sendingDate', title: 'Дата отправки', width: '20', sortable: true },
  { name: 'actionDate', title: 'Дата вывода', width: '20', sortable: true },
  { name: 'status', title: 'Статус', width: '20', sortable: true, minWidth: 120 },
  { name: 'productName', title: 'Продукт', maxLength: 15, width: '20', sortable: true },
  { name: 'productGtin', title: 'Gtin', maxLength: 10, width: '20', sortable: true },
  { name: 'action', title: 'Причина выбытия', width: '20', sortable: true },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '20', sortable: true },
  { name: 'unarchiveButton', title: 'Действие', minWidth: '100', width: '20', sortable: false }
];

export const writeOffTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10, width: '20', sortable: true },
  { name: 'creationDate', title: 'Дата создания', width: '20', sortable: true },
  { name: 'archivingDate', title: 'Дата архивирования', width: '20', sortable: true },
  { name: 'sendingDate', title: 'Дата отправки', width: '20', sortable: true },
  { name: 'sourceDocDate', title: 'Дата вывода', width: '20', sortable: true },
  { name: 'status', title: 'Статус', width: '20', sortable: true, minWidth: 120 },
  { name: 'productName', title: 'Продукт', maxLength: 15, width: '20', sortable: true },
  { name: 'productGtin', title: 'Gtin', maxLength: 10, width: '20', sortable: true },
  { name: 'dropoutReason', title: 'Причина выбытия', width: '20', sortable: true },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '20', sortable: true },
  { name: 'unarchiveButton', title: 'Действие', minWidth: '100', width: '20', sortable: false }
];

export const isChkboxDisbabled = (status) => status !== 'CREATED' && status !== 'REJECTED';
export const isChkboxDisbabledEnt = (status) => status !== 'CREATED' && status !== 'REJECTED';

export const mockSetsReport = {
  quantity: 3,
  list: [
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    },
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    },
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    }
  ]
};

export const getTypesInfo = () => ({
  printing: {
    title: 'нанесения',
    tableHeader: printingTableHeader
  },
  aggregation: {
    title: 'агрегации',
    tableHeader: aggregationTableHeader
  },
  entering: {
    title: 'ввода в оборот',
    tableHeader: enteringTableHeader
  },
  rejection: {
    title: 'отбраковка',
    tableHeader: rejectTableHeader
  },
  sets: {
    title: 'наборы',
    tableHeader: setsTableHeader
  },
  withrdIntrnlConsmpt: {
    title: 'вывод из оборота',
    tableHeader: withdrInternConsupmTableHeader
  },
  writeOff: {
    title: 'списания',
    tableHeader: writeOffTableHeader
  }
});
