import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import settingsAPI from '../../../api/settingsAPI';
import ReportsAPI from '../../../api/reportsAPI';
import g from '../../../assets/scss/Main.module.scss';
import { Alert } from '../../../components/Alert/Alert';
import { Pagination } from '../../../components/Pagination/Pagination';
import { WaitingScreen } from '../../../components/WaitingScreen/WaitingScreen';
import { searchBoxStyles, reportStatuseForPrinting } from '../../../constants';
import { formatTwoPartDate, isShipped, getDateFromTimestamp, mapItemsForDropdown } from '../../../functions';
import { useAlert, usePaginationAndSearch, useSendRequest, useDataFilter } from '../../../hooks';
import { getGeneralSettings, getGlobalErrorText } from '../../../redux/App/appSelectors';
import s from './ReportsBasket.module.scss';
import { getTypesInfo } from './constantsBasket';
import { SyncStatus } from '../../../components/SyncStatus/SyncStatus';
import { setGenSettings, setPrintReports } from '../../../redux/App/appReducer';
import { getShouldCheckExpDateForPrintRep } from '../../../redux/App/appSelectors';
import { SearchBlock } from '../../../components/SearchBlock/SearchBlock';
import { ReportsFilterNew } from '../../../components/Filters/ReportsFilterNew/ReportsFilterNew';
import { ButtonToggle } from '../../../components/ButtonToggle/ButtonToggle';
import LegalApi from '../../../api/legalAPI';
import { useTableColumnResizing } from '../../../components/Table/TableColumnResizingHook';
import { useDragAndDrop } from '../../../components/Table/DragAndDropHook';

import СreatedIcon from '../../../assets/img/СreatedIcon.svg';
import CreatedAndKmArchivedIcon from '../../../assets/img/CreatedAndKmArchivedIcon.svg';
import ProcessedWithErrorKmArchivedIcon from '../../../assets/img/ProcessedWithErrorKmArchivedIcon.svg';
import SuccessfullyProcessedAndKmArchivedIcon from '../../../assets/img/SuccessfullyProcessedAndKmArchivedIcon.svg';
import ProcessedWithErrorIcon from '../../../assets/img/ProcessedWithErrorIcon.svg';
import SuccessfullyProcessedIcon from '../../../assets/img/SuccessfullyProcessedIcon.svg';
import FragmentedIcon from '../../../assets/img/FragmentedIcon.svg';
import SendedIcon from '../../../assets/img/SendedIcon.svg';

import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  useTableFeatures,
  useTableSort,
  Spinner,
  Tooltip,
} from "@fluentui/react-components";
import { LinkELement } from 'components/LinkELement/LinkELement';
import clsx from 'clsx';
import { ArrowDownRegular, ArrowUpRegular } from '@fluentui/react-icons';
import { FixedSizeList as List } from "react-window";
import { useCrumbsContext } from 'context/CrumbsContext';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

export const ReportsBasket = ({ type, children }) => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [reportStatus, setreportStatus] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);
  const [selectedReports, setSelectedReports] = useState(new Map());
  const globalErrorText = useSelector(getGlobalErrorText);
  const [query, setQuery] = useDataFilter('reports', type, 'basket');
  const typesInfo = getTypesInfo();

  const dispatch = useDispatch();
  const shouldCheckExpDateForPrintRepFromRdx = useSelector(getShouldCheckExpDateForPrintRep);
  const [shouldCheckExpDateForPrintRep, setShouldCheckExpDateForPrintRep] = useState(false);
  const genSettingsFromRedx = useSelector(getGeneralSettings);
  const [selectedRows, setSelectedRows] = useState(() => new Set([]));
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const { crumbs, setCrumbs, notify } = useCrumbsContext();
  const [tableType, setTableType] = useState(type);

  const getColumns = useCallback((tableType) => {
    // Пытаемся получить сохраненное состояние
    const savedState = sessionStorage.getItem(`table_basket_${tableType}_state`);
    let savedColumnOrder = [];
    let savedColumnWidths = {};

    if (savedState) {
      try {
        const { columnOrder, columnWidths } = JSON.parse(savedState);
        if (columnOrder && Array.isArray(columnOrder)) {
          savedColumnOrder = columnOrder;
        }
        if (columnWidths && typeof columnWidths === 'object') {
          savedColumnWidths = columnWidths;
        }
      } catch (error) {
        console.warn('Error loading saved column state:', error);
      }
    }

    // Получаем базовые колонки
    const baseColumns = typesInfo[tableType].tableHeader?.filter(column => {
      if (!genSettingsFromRedx?.specifySeriesWhenCreatingShiftTask) {
        return column.name !== 'serialNumber'
      }
      return true
    });

    // Создаем Map для быстрого доступа к колонкам
    const columnMap = new Map(baseColumns.map(col => [col.name, col]));

    // Формируем итоговый массив колонок в правильном порядке
    const orderedColumns = savedColumnOrder.length > 0
      ? savedColumnOrder
        .map(id => columnMap.get(id))
        .filter(Boolean)
        .concat(baseColumns.filter(col => !savedColumnOrder.includes(col.name)))
      : baseColumns;

    // Преобразуем колонки в нужный формат
    return orderedColumns.map((column) => ({
      id: column.id,
      columnId: column.name,
      label: column.title,
      minWidth: column.minWidth,
      width: savedColumnWidths[column.name] || column.width, // Используем сохраненную ширину или значение по умолчанию
      sortable: column.sortable,
      compare: (a, b) => {
        if (!a || !b) return 0;

        // Обработка числовых значений
        if (column.name === 'numberMarkingCodes') {
          return Number(a[column.name]?.label || 0) - Number(b[column.name]?.label || 0);
        }

        // Обработка дат
        const dateFields = ['creationDate', 'sendingDate', 'productCreationDate', 'actionDate', 'sourceDocDate', 'archivingDate'];
        if (dateFields.includes(column.name)) {
          const parseDateStr = (dateStr) => {
            if (!dateStr) return null;
            const [day, month, year] = dateStr.split('.');
            return new Date(year, month - 1, day).getTime();
          };

          const timestampA = parseDateStr(a[column.name]?.label);
          const timestampB = parseDateStr(b[column.name]?.label);

          if (!timestampA && !timestampB) return 0;
          if (!timestampA) return 1;
          if (!timestampB) return -1;

          return timestampA - timestampB;
        }

        // Обработка остальных строковых значений
        const aValue = a[column.name]?.label ?? '';
        const bValue = b[column.name]?.label ?? '';
        return String(aValue).localeCompare(String(bValue));
      },
    }));
  }, [genSettingsFromRedx?.specifySeriesWhenCreatingShiftTask, typesInfo]);

  // Используем useMemo для хранения текущих колонок
  const [memoizedColumns, setMemoizedColumns] = useState(getColumns(tableType));

  useEffect(() => {
    if (globalErrorText) {
      setreportStatus(null);
    }
  }, [globalErrorText]);

  const getGeneralSetting = async () => {
    const res = await settingsAPI.getGeneralSettings();
    dispatch(setGenSettings(res));
  };

  useEffect(() => {
    getGeneralSetting();
  }, []);

  useEffect(() => {
    setTableType(type);
    setMemoizedColumns(getColumns(type));
  }, [type]);

  useEffect(() => {
    setCrumbs([
      `/reports`,
      `/reports/${type}`,
      'Корзина'
    ]);
  }, [type]);

  useEffect(() => {
    if (typeof shouldCheckExpDateForPrintRepFromRdx === 'boolean') {
      setShouldCheckExpDateForPrintRep(shouldCheckExpDateForPrintRepFromRdx);
    }
  }, [shouldCheckExpDateForPrintRepFromRdx]);

  const getReports = async (query = {}) => {
    setLoading(true);
    const getReportsTypes = {
      printing: (queryString) => ReportsAPI.getArchivedPrintReports(queryString),
      aggregation: (queryString) => ReportsAPI.getArchivedAggregationReports(queryString),
      entering: (queryString) => ReportsAPI.getArchivedRolloutReports(queryString),
      rejection: (queryString) => ReportsAPI.getArchivedDropoutReports(queryString),
      sets: (queryString) => ReportsAPI.getArchivedSetReports(queryString),
      withrdIntrnlConsmpt: (queryString) => ReportsAPI.getArchivedLkReceiptReports(queryString),
      writeOff: (queryString) => ReportsAPI.getArchivedWriteOffReports(queryString)
    };
    let res;
    res = await getReportsTypes[tableType](query);

    if (tableType === 'printing') {
      if (
        genSettingsFromRedx &&
        'checkProductionAndExpirationDatesWhenSubmittingUtilisationReports' in genSettingsFromRedx
      )
        setShouldCheckExpDateForPrintRep(
          genSettingsFromRedx.checkProductionAndExpirationDatesWhenSubmittingUtilisationReports
        );
    }

    if (res) {
      if ('list' in res && 'quantity' in res) {
        setReports(res.list);
        setTotalItems(res.quantity);
        if (tableType === 'printing') {
          dispatch(setPrintReports(res.list));
        }
      }
    }
    setLoading(false);
  };

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();

  const { afterSending } = useSendRequest(setLoading);

  // ======= UNARCHIVE REPORT ==========================

  const UnArchiveReportHandler = async (id) => {

    setLoading(true);
    const archiveReportsTypes = {
      printing: () => ReportsAPI.DeleteArchivedPrintReports(id),
      aggregation: () => ReportsAPI.DeleteArchivedAggregationReports(id),
      entering: () => ReportsAPI.DeleteArchivedRolloutReports(id),
      rejection: () => ReportsAPI.DeleteArchivedDropoutReports(id),
      sets: () => ReportsAPI.DeleteArchivedSetReports(id),
      withrdIntrnlConsmpt: () => ReportsAPI.DeleteArchivedLkReceiptReports(id),
      writeOff: () => ReportsAPI.DeleteArchivedWriteOffReports(id)
    }
    let res = await archiveReportsTypes[tableType](query);
    setLoading(false);

    const onSuccess = () => {
      if (res?.errors?.length) {
        const errorString = res?.errors
          .reduce((acc, elem, i) => {
            return [...acc, [res?.notSendedRollouts[i], elem]];
          }, [])
          .map((item) => item.join(': '))
          .map((str) => <p style={{ margin: '5px 0' }}>{str}</p>);

        // showAlert('Ошибки архивирования отчетов', errorString);
        notify({ title: 'При восстановлении отчетов произошла ошибка', intent: 'error', timeout: 500 });
      }
      getReports(query);
      setreportStatus('success');
      notify({ title: 'Отчет успешно восстановлен', intent: 'success', timeout: 500 });
    };

    afterSending(res, onSuccess);
  }

  const { onPageChanged: baseOnPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getReports,
      query,
      setQuery,
      headerHeight: 100
    });

  // Add a wrapper function for onPageChanged
  const onPageChanged = (page) => {
    // Обновляем selectedRows на основе selectedReports для текущей страницы
    const newSelectedRows = new Set();
    reports.forEach((report, index) => {
      if (selectedReports.has(report.id)) {
        newSelectedRows.add(index);
      }
    });
    setSelectedRows(newSelectedRows);
    baseOnPageChanged(page);
  };

  // Обновляем useEffect для синхронизации выбранных строк после загрузки отчетов
  useEffect(() => {
    if (reports.length > 0) {
      const newSelectedRows = new Set();
      reports.forEach((report, index) => {
        if (selectedReports.has(report.id)) {
          newSelectedRows.add(index);
        }
      });
      setSelectedRows(newSelectedRows);
    }
  }, [reports, selectedReports]);

  const showButton = false;

  //проверяет какие заголовки и конвертирует даты
  const items = reports.map((report) => {
    const item = {};
    typesInfo[tableType].tableHeader.forEach(header => {

      const fieldName = header.name;
      let fieldValue = report[fieldName];

      switch (fieldName) {
        case 'creationDate':
        case 'sendingDate':
        case 'productCreationDate':
        case 'actionDate':
        case 'sourceDocDate':
        case 'archivingDate':
          item[fieldName] = {
            label: fieldValue ? formatTwoPartDate(fieldValue).mainPart : null,
            id: report.id,
          };
          break;
        case 'status':
          item[fieldName] = { label: fieldValue || 'UNKNOWN', id: report.id, errorMessage: report?.errorMessage };
          break;
        case 'numberMarkingCodes':
          item[fieldName] = { label: fieldValue !== undefined ? String(fieldValue) : null, id: report.id };
          break;
        case 'unarchiveButton':
          item[fieldName] = { label: 'Восстановить', id: report.id };
          break;
        default:
          item[fieldName] = { label: fieldValue, id: report.id };
      }
    });
    return item;
  });

  //создает колонки с заголовками, настраивает данные под сортировку и фильтрует массив


  const {
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
    selection: {
      toggleRow,
      isRowSelected,
    },
  } = useTableFeatures(
    {
      columns: memoizedColumns,
      items,
    },
    [
      useTableSort({
        defaultSortState: {
          sortColumn: 'productCreationDate',
          sortDirection: "descending"
        },
      }),
    ]
  );

  const headerSortProps = (columnId) => {
    const column = memoizedColumns?.find(col => col.columnId === columnId);
    if (!column?.sortable) return {};

    return {
      onClick: (e) => {
        toggleColumnSort(e, columnId);
      },
      sortDirection: getSortDirection(columnId),
    };
  }

  const rows = sort(getRows((row) => {
    const selected = isRowSelected(row?.rowId);
    return {
      ...row,
      onKeyDown: (e) => {
        if (e.key === " ") {
          e.preventDefault();
          toggleRow(e, row?.rowId);
        }
      },
      selected,
      appearance: selected ? ("brand") : ("none"),
    };
  }));

  const renderRows = (column, item) => {

    switch (item[column.columnId]?.label && column.columnId) {
      case 'status':
        switch (item[column.columnId]?.label) {
          case 'CREATED':
            return <img src={СreatedIcon} alt="Создан" />;
          case 'SENDED':
            return <img src={SendedIcon} alt="Отправлен" />;
          case 'DISAGGREGATED':
            return <img src={FragmentedIcon} alt="Разагрегирован" />;
          case 'ACCEPTED':
            return <img src={SuccessfullyProcessedIcon} alt="Успешно обработан" />;
          case 'REJECTED':
            return (
              <Tooltip content={{ children: `Текст ошибки: ${item[column.columnId]?.errorMessage}`, className: s.tooltip }}>
                <img src={ProcessedWithErrorIcon} alt="Обработан с ошибкой" />
              </Tooltip>
            );
          case 'ACCEPTED_ARCHIVED':
            return <img src={SuccessfullyProcessedAndKmArchivedIcon} alt="Успешно обработан и КМ архивированы" />;
          case 'CREATED_ARCHIVED':
            return <img src={CreatedAndKmArchivedIcon} alt="Создан и КМ архивированы" />;
          case 'REJECTED_ARCHIVED':
            return <img src={ProcessedWithErrorKmArchivedIcon} alt="Обработан с ошибкой и КМ архивированы" />;
          default:
            return item[column.columnId]?.label;
        }
      case 'productName':
        return <LinkELement
          addStyles={clsx(g.tableRow, s.firstRow)}
          link={`/reports/${tableType}/${item?.productName?.id}`}
          text={item?.productName?.label}
          params={{
            reportId: item?.productName?.id,
            type: tableType
          }}
          appearance={'subtle'}
        >
        </LinkELement>
      case 'unarchiveButton':
        return <LinkELement
          text={'Восстановить'}
          underline={true}
          style={{ marginRight: '10px' }}
          appearance={'subtle'}
          onClick={() => UnArchiveReportHandler(item?.suzId?.id)}
        />
      default:
        return <p>{item[column.columnId]?.label}</p>
    }
  };

  useEffect(() => {
    setLoading(true);
    LegalApi.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  //создает query для фильтров
  useEffect(() => {
    const filterData = [
      { label: 'Компания', key: 'companyId', options: companiesForDropdown },
      { label: 'Статус', key: 'status', options: reportStatuseForPrinting },
      {
        label: 'Дата создания от',
        key: 'minCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата создания до',
        key: 'maxCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата отправки от',
        key: 'minSendingDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата отправки до',
        key: 'maxSendingDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата производства КМ от',
        key: 'minProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата производства КМ до',
        key: 'maxProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      }
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: { key: option.key, text: option.text, category: filter.key, label: filter.label }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              category: filter.key,
              label: filter.label
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, companiesForDropdown]);



  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...memoizedColumns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setMemoizedColumns(newColumns);

  }, [memoizedColumns]);
  const {
    isResizing,
    handleResizeStart,
    getColumnWidth,
    columnWidths,
    columnOrder,
    updateState,
    orderedHeaderItems
  } = useTableColumnResizing({}, memoizedColumns, `basket_${tableType}`, 'calc(100vw - 180px)');

  const {
    draggingColumnId,
    handleDragStart,
    handleDragEnd
  } = useDragAndDrop(
    memoizedColumns,
    (sourceIndex, destinationIndex, newOrder) => {
      updateState(columnWidths, newOrder);
      handleColumnReorder(sourceIndex, destinationIndex);
    }
  );

  const handleResizeStartWrapper = (e, columnId) => {
    e.stopPropagation();
    if (draggingColumnId) return;
    handleResizeStart(e, columnId);
  };

  const renderSortIcon = (columnId) => {
    const sortDirection = getSortDirection(columnId);
    const iconProps = {
      style: {
        marginRight: '0.5rem',
        height: '12px',
        width: '12px',
        color: sortDirection ? '#0078D4' : 'inherit'
      }
    };

    return sortDirection === 'ascending'
      ? <ArrowUpRegular {...iconProps} />
      : <ArrowDownRegular {...iconProps} />;
  };


  return (
    <div className={s.tableWrapper}>
      {isAlertShowed && (
        <Alert
          title={alertTitle}
          text={alertText}
          onClose={() => {
            hideAlert();
          }}
        />
      )}
      <>
        <div className={g.titleWrapper}>
          <div className={` ${g.searchBlockWrapper}`}>
            {tableType !== 'sets' && (
              <SearchBlock
                iconProps={''}
                styles={searchBoxStyles}
                value={query.searchValue}
                placeholder="Поиск по коду отчёта, названию товара, gtin или номеру задания"
                onClear={() => onSearchTermChanged('')}
                onSearch={(newValue) => onSearchTermChanged(newValue)}
              />
            )}
          </div>
          <div isFiltersDisplayed>
            <ButtonToggle
              text="Фильтры"
              value={isOpenFilters}
              onClick={() => setIsOpenFilters(!isOpenFilters)}
              optionSize={selectedOptions.length}
            />
          </div>
        </div>
        {isOpenFilters && (
          <div className={g.filtersWrapper}>
            <ReportsFilterNew
              query={query}
              setQuery={setQuery}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              type={tableType}
              companiesForDropdown={companiesForDropdown}
              setCompaniesForDropdown={setCompaniesForDropdown}
            />
          </div>
        )}

        <div className={s.tableBlock}>
          {!rows.length && selectedOptions.length ? (
            <div className={g.searchWithFiltersVoid}>Не найдено. Измените параметры фильтрации</div>
          ) : !rows.length ? (
            <div className={g.searchWithFiltersVoid}>Архивированные отчёты отсутствуют</div>
          ) : (
            <div className={s.tableContainer} style={{ padding: '0 0.325rem' }}>
              {!loading ? (
                <Table
                  sortable
                  aria-label="Table with sort and selection"
                  className={clsx(s.table, {
                    [s.resizing]: isResizing
                  })}
                  noNativeElements
                  data-table-name={`basket_${tableType}`}
                >
                  <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table-header" direction="horizontal">
                      {(provided) => (
                        <TableHeader
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={s.tableHeader}
                        >
                          <TableRow>
                            {console.log(orderedHeaderItems, 'orderedHeaderItems')}
                            {orderedHeaderItems.map((column, index) => (
                              <Draggable
                                key={column.columnId}
                                draggableId={column.columnId}
                                index={index}
                                isDragDisabled={isResizing}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className={clsx(s.tableHeaderCell, {
                                      [s.dragging]: snapshot.isDragging,
                                      [s.draggingColumn]: column.columnId === draggingColumnId
                                    })}
                                    style={{
                                      ...provided.draggableProps.style,
                                      width: getColumnWidth(column.columnId),
                                      minWidth: column.minWidth,
                                    }}
                                    {...headerSortProps(column.columnId)}
                                  >
                                    <div
                                      {...provided.dragHandleProps}
                                      onClick={() => column.sortable && headerSortProps(column.columnId)}
                                      style={{
                                        cursor: isResizing ? 'col-resize' : (column.sortable ? 'pointer' : 'default'),
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: 1,
                                        height: '100%',
                                        padding: '0 8px'
                                      }}
                                    >
                                      <p>{column.label}</p>
                                      {column.sortable && getSortDirection(column.columnId) && (
                                        <span className={s.sortIcon}>
                                          {renderSortIcon(column.columnId)}
                                        </span>
                                      )}
                                    </div>

                                    {index < orderedHeaderItems.length - 1 && (
                                      <div
                                        className={s.resizeHandle}
                                        onMouseDown={(e) => handleResizeStartWrapper(e, column.columnId)}
                                        onTouchStart={(e) => handleResizeStartWrapper(e, column.columnId)}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </TableRow>
                        </TableHeader>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <TableBody>
                    <List
                      height={window.innerHeight - 335}
                      itemCount={rows.length}
                      itemSize={44}
                      width="100%"
                      itemData={rows}
                      className={s.ReactWindow}
                      overscanCount={5}
                    >
                      {({ index, style, data }) => {
                        const { item } = data[index];
                        return (
                          <TableRow
                            key={item.id}
                            className={clsx(s.tableRow, !isShipped(item?.status?.label) ? (rows.selected && s.tableSelectionCell) : s.disabledCheckbox)}
                            style={style}
                          >
                            {orderedHeaderItems.map((column) => (
                              <TableCell
                                id={s[column.columnId]}
                                key={column.columnId}
                                style={{
                                  width: getColumnWidth(column.columnId),
                                  minWidth: getColumnWidth(column.columnId)
                                }}
                              >
                                {column.columnId !== "status" ? (
                                  <Tooltip showDelay={300} content={item[column.columnId]?.label}>
                                    <div>{renderRows(column, item)}</div>
                                  </Tooltip>
                                ) : (
                                  <div>{renderRows(column, item)}</div>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      }}
                    </List>
                  </TableBody>
                </Table>
              ) : (
                <div className={s.spinner}>
                  <Spinner size="huge" />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={g.footer}>
          {totalPages > 1 && (
            <Pagination
              pageCount={totalPages}
              onPageChange={onPageChanged}
              selectedPage={currentPage}
            />
          )}
        </div>
      </ >
      {
        reportStatus?.includes('inProgress') && (
          <WaitingScreen
            title={'Выполняется отправка отчётов'}
            text={
              'Пожалуйста, подождите. Это может занять несколько минут. Не закрывайте эту страницу.'
            }
          />
        )
      }
      {
        ['success', 'error'].includes(syncStatus) && (
          <SyncStatus syncStatus={syncStatus} hide={() => setSyncStatus(null)} />
        )
      }
    </div >
  );
};
