import { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { productGroupRefObj } from './addSideProducts.constants';
import ComboSelect from 'components/Forms/customSelect/comboSelect';
import s from './Expiration.module.scss';
import productsAPI from 'api/productsAPI';
import { Checkbox } from '@fluentui/react';

const ProductGroupField = ({ sideProduct }) => {
  const [pgField, __, prGrHelpers] = useField('productGroup');
  const [ph, _, useShortTemplateHelpers] = useField('useShortTemplateId');
  const [newProductGroups, setNewProductGroups] = useState([]);

  const onChange = (arg) => {
    prGrHelpers.setValue(arg.value);
  };

  const getProductsGroups = async () => {
    const res = await productsAPI.getProductsGroups();
    const data = await res?.productGroups?.map((comp) => ({
      value: comp.productGroup,
      label: comp.name,
      checked: comp.canUseShortTemplateMarkingCode
    }));
    if (!sideProduct) {
      prGrHelpers.setValue(data[0]?.value);
    }
    console.log(data, 'data[0]?.label');
    setNewProductGroups(data);
  };

  useEffect(() => {
    getProductsGroups();
    if (sideProduct) {

      prGrHelpers.setValue(sideProduct.productGroup)
    }
  }, []);
  console.log(pgField.value, 'pgField.value');

  const isCheckboxDisabled = !newProductGroups?.find((pg) => pg.value === pgField.value)?.checked;

  useEffect(() => {
    if (isCheckboxDisabled) {
      useShortTemplateHelpers.setValue(sideProduct ? sideProduct.useShortTemplateId : false)
    }
  }, [isCheckboxDisabled]);

  return (
    <>
      <section className={s.expiration}>
        <Field name={'productGroup'}>
          {(props) => {
            return (
              <ComboSelect
                options={newProductGroups}
                onChange={onChange}
                label="Товарная группа продукта"
                placeholder={
                  newProductGroups?.length > 0
                    ? newProductGroups?.find((pg) => pg.value === pgField.value)?.label ||
                    newProductGroups[0]?.label ||
                    ''
                    : ''
                }
                isSearchable={false}
              />
            );
          }}
        </Field>
        <div className={s.checkboxWrapper}>
          <Field name="useShortTemplateId">
            {({ field }) => (
              <Checkbox
                {...field}
                disabled={isCheckboxDisabled}
                checked={field.value}
                onChange={(_, checked) => useShortTemplateHelpers.setValue(checked)}
              />
            )}
          </Field>
          <p>Использовать короткий код маркировки</p>
        </div>
      </section>
    </>
  );
};

export default ProductGroupField;
