import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { navLinkGroups } from '../components/Menu/navLinkGroups';
import {
  useId,
  Link,
  useToastController,
  Toast,
  ToastTitle,
  ToastBody,
  ToastFooter,
  Toaster,
  makeStyles
} from '@fluentui/react-components';
import ErrorIcon from '../assets/img/ErrorIcon.svg';
import SuccessIcon from '../assets/img/SuccessIcon.svg';

const CrumbsContext = React.createContext();
const useStyles = makeStyles({
  toasterError: {
    backgroundColor: '#EE5F2B',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0px',
    '& div': {
      color: 'white'
    },
    '& div:first-child': {
      height: '50px',
      width: '50px',
      '& svg': {
        width: '100%',
        height: '100%'
      }
    },
    '& img': {
      marginRight: '10px'
    }
  },
  toasterSuccess: {
    backgroundColor: '#107C10',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0px',
    '& div': {
      color: 'white'
    },
    '& div:first-child': {
      height: '50px',
      width: '50px',
      '& svg': {
        height: '100%'
      }
    },
    '& img': {
      marginRight: '10px'
    }
  }
});

export const CrumbsProvider = ({ children }) => {
  const [crumbs, setCrumbsState] = useState([]);
  const [renderedCrumbs, setRenderedCrumbs] = useState([]);
  const [currentRenderedCrumb, setCurrentRenderedCrumb] = useState('');
  const [isFiltersDisplayed, setIsFiltersDisplayed] = useState(false);
  const [pageTitle, setPageTitle] = useState([]);
  const [showToastTimeout, setShowToastTimeout] = useState(3000);
  const location = useLocation();
  const styles = useStyles();

  const flatArr = (arr, resArr = []) => {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i].links)) {
        flatArr(arr[i].links, resArr);
      }
      resArr.push(arr[i]);
    }
    return resArr;
  };

  const getPageTitle = (pathname) => {
    const convertedPath = pathname.split('/').filter((part) => part);
    let titleSearch = [];
    if (crumbs.includes('/')) {
      titleSearch = [{ title: navLinkGroups[0]?.links?.find((item) => item.key === `/${convertedPath[0]}`)?.name }, { path: `/${convertedPath[0]}` }];
      
    }else{
      titleSearch = [{title: convertedPath[0]}]
    }
    return titleSearch;
  };

  //Toast
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);
  const [horizontal, setHorizontal] = useState(0);
  const [vertical, setVertical] = useState(35);

  const notify = React.useCallback(
    (options = {}) => {
      const {
        title = 'Уведомление',
        body,
        intent = 'success',
        action,
        position = 'top-end',
        timeout = showToastTimeout
      } = options;

      switch (intent) {
        case 'error':
          dispatchToast(
            <Toast className={styles.toasterError}>
              <img src={ErrorIcon} alt="Error" />
              <ToastTitle media={null}>{title}</ToastTitle>
              {body && <ToastBody>{body}</ToastBody>}
              {action && (
                <ToastFooter>
                  <Link onClick={action.onClick}>{action.text}</Link>
                </ToastFooter>
              )}
            </Toast>,
            { intent, position, timeout }
          );
          break;
        case 'success':
          dispatchToast(
            <Toast className={styles.toasterSuccess}>
              <img src={SuccessIcon} alt="Success" />
              <ToastTitle media={null}>{title}</ToastTitle>
              {body && <ToastBody>{body}</ToastBody>}
              {action && (
                <ToastFooter>
                  <Link onClick={action.onClick}>{action.text}</Link>
                </ToastFooter>
              )}
            </Toast>,
            { intent, position, timeout }
          );
          break;
        case 'info':
        case 'warning':
          dispatchToast(
            <Toast>
              <ToastTitle>Toast intent: {intent}</ToastTitle>
            </Toast>,
            { intent, position, timeout }
          );
          break;
      }
    },
    [dispatchToast]
  );

  const setCrumbs = (newCrumbs) => {

    setCrumbsState((prevCrumbs) => {
      if (JSON.stringify(prevCrumbs) !== JSON.stringify(newCrumbs)) {
        return newCrumbs;

      }
      console.log(prevCrumbs, 'prevCrumbs CrumbsContext');
      return prevCrumbs;
    });
  };

  useEffect(() => {
    const resArr = [];
    const newRenderedCrumbs = [];
    const flatLinks = flatArr(navLinkGroups[0].links, resArr);
    if (crumbs.length > 1) {
      setCurrentRenderedCrumb(crumbs[crumbs.length - 1]);
    } else {
      setCurrentRenderedCrumb([]);
    }

    for (let i = 0; i < flatLinks.length; i++) {
      for (let j = 0; j < crumbs.length; j++) {
        if (flatLinks[i].key === crumbs[j]) {
          newRenderedCrumbs.push(flatLinks[i]);
          break;
        }
      }
    }
    setRenderedCrumbs(newRenderedCrumbs);
  }, [crumbs]);

  useEffect(() => {
    const title = getPageTitle(location.pathname);

    setPageTitle(title);
  }, [location.pathname]);

  return (
    <CrumbsContext.Provider
      value={{
        crumbs,
        setCrumbs,
        renderedCrumbs,
        currentRenderedCrumb,
        isFiltersDisplayed,
        setIsFiltersDisplayed,
        pageTitle,
        notify,
        toasterId
      }}
    >
      <Toaster toasterId={toasterId} offset={{ horizontal, vertical }} timeout={showToastTimeout} />
      {children}
    </CrumbsContext.Provider>
  );
};

export const useCrumbsContext = () => {
  return useContext(CrumbsContext);
};
