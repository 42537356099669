import React from 'react'
import g from '../../assets/scss/Main.module.scss';
import s from './SearchBlock.module.scss';
import { SearchBox, makeStyles, tokens } from '@fluentui/react-components';


const useClasses = makeStyles({
  searchPrimary: {
    width: "100%",
    height: "2rem"
  },
});

export const SearchBlock = ({
  placeholder,
  styles,
  value,
  onClear,
  onSearch,
}) => {
  const classes = useClasses()

  return (
    <div className={s.container}>
      <SearchBox
        className={classes.searchPrimary}
        styles={styles}
        placeholder={placeholder}
        value={value || ''}
        onClear={onClear}
        onChange={onSearch}
      />
    </div>
  )
}
