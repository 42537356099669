import { useRef, useEffect, useCallback } from "react";
import {
  createPresenceComponent,
  makeStyles,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  tokens,
  motionTokens,
  Spinner,
  MenuItemCheckbox,
} from "@fluentui/react-components";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { ChevronDownRegular } from "@fluentui/react-icons";
import { lighter } from "constants";
import clsx from "clsx";
import { aggregateStatuses } from "constants";
import { Default } from "components/ButtonToggle/ButtonToggle";


const Fade = createPresenceComponent({
  enter: {
    keyframes: [{ transform: "rotate(0deg)" }, { transform: "rotate(-180deg)" }],
    duration: motionTokens.durationGentle,
  },
  exit: {
    keyframes: [{ transform: "rotate(-180deg)" }, { transform: "rotate(0deg)" }],
    duration: motionTokens.durationGentle,
  },
});

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    minWidth: "min-content",
    color: tokens.colorNeutralForeground3,
    ':hover': {
      backgroundColor: tokens.colorPaletteBlueBorderActive,
      color: tokens.colorNeutralBackground1,
    },
    "& div": {
      display: 'flex',
      alignItems: 'center'
    },

  },
  wrapperItems: {
    minWidth: "min-content",
    color: tokens.colorNeutralForeground3,
    ':hover': {
      backgroundColor: lighter,
    },
  },

  wrapperIcon: {
    minWidth: "32px",
    height: "32px",
    padding: '5px',
  },
  scrollableMenuList: {
    maxHeight: '500px',
    overflowY: 'auto',
  },
});

export const ButtonMenu = ({
  link,
  iconName,
  text,
  isButton,
  onClick,
  params,
  disabled,
  tooltipText,
  type = 'default',
  addStyles,
  getProductBalance,
  dataArray,
  icon,
  title,
  syncStatus,
  selectedFilters,
  onChangeFilter,
  isCheckbox,
  optionSize,
}) => {
  const styles = useStyles();
  const [isRotated, setIsRotated] = useState(false);
  const [checkedValues, setCheckedValues] = useState({ edit: [] });

  const handleClickFade = () => {
    setIsRotated((prev) => !prev);
  };

  useEffect(() => {
    selectedFilters && setCheckedValues({ edit: Array.from(selectedFilters) });
  }, [selectedFilters]);

  const handleCheckedValueChange = useCallback(
    (name) => {
      return onChangeFilter(name);
    },
    [onChangeFilter]
  );


  return (
    <div style={{ height: "100%" }}>

      <Menu
        onOpenChange={handleClickFade}
        checkedValues={checkedValues}
      >
        <MenuTrigger disableButtonEnhancement>
          {isButton ?
            <MenuButton className={clsx(`${addStyles} ${styles.wrapperIcon}`)} icon={icon} menuIcon={null}>
              {title}
              {iconName}
            </MenuButton>
            :
            <MenuButton className={styles.wrapper} icon={icon} menuIcon={null}>
              {title}
              <div style={{ marginLeft: "5px" }}>
                {optionSize ? <Default className={styles.counterBadge} color="danger" count={optionSize} shape="circular" /> : null}
                {syncStatus ?
                  <Spinner size="extra-tiny" />
                  :
                  <Fade visible={isRotated}>
                    <ChevronDownRegular />
                  </Fade>
                }
              </div>
            </MenuButton>
          }
        </MenuTrigger>
        <MenuPopover flipBoundary="scrollParent">
          {!isCheckbox ?
            <MenuList className={styles.scrollableMenuList}>
              {dataArray?.map((item) => (
                <NavLink to={{ pathname: item.link }} key={item.text}>
                  <MenuItem
                    onClick={item.onClick}
                    disabled={item.disabled}
                    className={styles.wrapperItems}
                    key={item.key}
                  >
                    {item.text}
                  </MenuItem>
                </NavLink>
              ))}
            </MenuList>
            :
            <MenuList className={styles.scrollableMenuList}>
              {dataArray?.map((item) => (
                <MenuItemCheckbox
                  key={item.key}
                  value={item.key}
                  name='edit'
                  onClick={() => handleCheckedValueChange(item.key)}
                  disabled={item.disabled}
                  className={styles.wrapperItems}
                >
                  {/* {console.log([...selectedFilters])} */}
                  {/* {console.log([...aggregateStatuses])} */}
                  {item.text}
                </MenuItemCheckbox>
              ))}
            </MenuList>
          }
        </MenuPopover>
      </Menu>
    </div >
  );
};