import React, { useEffect, useState, useCallback } from 'react';
import LinesAPI from '../../api/linesAPI';
import g from '../../assets/scss/Main.module.scss';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { moreInfoIcon, searchBoxStyles } from '../../constants';
import { formatLineStatus, cellWidth, mapItemsForDropdown } from '../../functions';
import { usePaginationAndSearch, useDataFilter } from '../../hooks';
import s from './Lines.module.scss';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import { LinesFilterNew } from 'components/Filters/LinesFilterNew/LinesFilterNew';
import { lineStatuses } from '../../constants';
import productsAPI from 'api/productsAPI';
import { DirectionalHint } from '@fluentui/react';
import clsx from 'clsx';
import { Tooltip } from '@fluentui/react-components';


const tableHeader = [
  {
    title: 'Название',
    width: '0.3',
    columnId: 'name',
    sortable: true,
  },
  {
    title: 'Статус',
    width: '0.25',
    columnId: 'status',
    sortable: true,
  },
  {
    title: 'Продукт',
    width: '0.3',
    columnId: 'selectedProductName',
    sortable: true,
  },
  {
    title: 'Действия',
    width: '0.15',
    columnId: 'actions',
    sortable: false,
    minWidth: 120
  }
];

export const Lines = () => {
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('lines');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productsForDropdown, setProductsForDropdown] = useState([]);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [columns, setColumns] = useState(() => {
    try {
      const savedState = sessionStorage.getItem('table_lines_state');
      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);
        if (columnOrder && Array.isArray(columnOrder)) {
          const columnMap = new Map(tableHeader.map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          tableHeader.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return tableHeader;
  });

  const getLines = async (query) => {
    setLoading(true);
    let res = await LinesAPI.getLines(query);
    const { list, quantity } = res;
    setLines(list);
    setTotalItems(quantity);
    setLoading(false);
  };

  useEffect(() => {
    const filterData = [
      { label: 'Продукт', key: 'productId', options: productsForDropdown },
      { label: 'Статус', key: 'status', options: lineStatuses },
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: { key: option.key, text: option.text, category: filter.key, label: filter.label }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              category: filter.key,
              label: filter.label
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, lineStatuses, productsForDropdown]);

  useEffect(() => {
    productsAPI?.getAllProductsNoMarking().then((res) =>
      setProductsForDropdown(mapItemsForDropdown(res))
    );
  }, []);

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getLines, query, setQuery, headerHeight: 170 });

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);

    const reorderedLines = lines.map(item => {
      const newItem = {};
      newColumns.forEach(column => {
        newItem[column.columnId] = item[column.columnId];
      });
      return { ...item, ...newItem };
    });
    setLines(reorderedLines);
  }, [columns, lines]);

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            styles={searchBoxStyles}
            value={query.searchValue}
            placeholder="Поиск по названию"
            onClear={() => {
              onSearchTermChanged('');
            }}
            onSearch={(newValue) => {
              onSearchTermChanged(newValue);
            }}
          />
        </div>
        <ButtonToggle
          text="Фильтры"
          value={isOpenFilters}
          onClick={() => setIsOpenFilters(!isOpenFilters)}
          optionSize={selectedOptions?.length}
        />
      </div>
      <div style={{ margin: '0.75rem 0 0.75rem 1rem' }}>
        <LinkButton
          link={`lines/add`}
          isButton={true}
          text="Добавить линию"
          primary={true}
        />

      </div>
      {isOpenFilters && (
        <div className={g.filtersWrapper}>
          <LinesFilterNew
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            query={query}
            setQuery={setQuery}
            lineStatuses={lineStatuses}
            productsForDropdown={productsForDropdown}
          />
        </div>
      )}
      {!lines?.length && selectedOptions?.length ? (
        <div className={g.searchWithFiltersVoid}>Не найдено. Измените параметры фильтрации</div>
      ) : (
        <Table
          headerItems={columns}
          maxHeight="67vh"
          loading={loading}
          onColumnReorder={handleColumnReorder}
          tableName="lines"
          customHeaderClass={s.tableHeader}
        >
          {!!lines?.length &&
            lines?.map((item) => {
              return (
                <div key={item.id} className={s.tableItem}>
                  {columns.map((column) => {
                    let content;
                    let tooltipContent;
                    let customStyle = {};

                    switch (column.columnId) {
                      case 'name':
                        content = `${item?.name} (№${item?.number})`;
                        tooltipContent = content;
                        break;
                      case 'status':
                        content = formatLineStatus(item.status).status;
                        tooltipContent = content;
                        customStyle = {
                          ...formatLineStatus(item.status).style
                        };
                        break;
                      case 'selectedProductName':
                        content = item?.selectedProductName;
                        tooltipContent = content;
                        break;
                      case 'actions':
                        if (item.status === 'STOP') {
                          return (
                            <div
                              key={column.columnId}
                              className={s.tableActionCell}
                              style={{
                                width: `calc((100vw - 180px - 3.75rem) * ${column.width})`,
                                minWidth: column.minWidth
                              }}
                            >
                              <LinkButton
                                addStyles={clsx(s.linkButton)}
                                link={`/lines/${item.id}`}
                                isButton={true}
                                text="Подробнее"
                              />
                            </div>
                          );
                        }
                        return <div></div>;
                    }

                    return (
                      <div
                        key={column.columnId}
                        className={clsx(s.tableCell)}
                        style={{
                          width: `calc((100vw - 180px - 3.75rem) * ${column.width})`,
                          minWidth: column.minWidth,
                          ...customStyle
                        }}
                      >
                        <div>
                          <Tooltip
                            content={tooltipContent}
                            delay={500}
                            directionalHint={DirectionalHint.topCenter}
                          >
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {content}
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </Table>
      )}
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>

    </div>
  );
};
