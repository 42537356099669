import * as React from "react";
import {
  makeStyles,
  ToggleButton,
  tokens,
  CounterBadge,
  createPresenceComponent,
  motionTokens,
  Button
} from "@fluentui/react-components";
import { FilterRegular, ChevronDownRegular } from '@fluentui/react-icons';
import clsx from "clsx";

const useStyles = makeStyles({
  offButton: {
    minWidth: "min-content",
    color: tokens.colorNeutralForeground3,
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground1,
    },
  },
  onButton: {
    minWidth: "min-content",
    backgroundColor: tokens.colorPaletteBlueBorderActive,
    color: tokens.colorNeutralBackground1,
    ':hover': {
      backgroundColor: tokens.colorPaletteBlueBorderActive,
      color: tokens.colorNeutralBackground1,
    },
  },
  counterBadge: {
    margin: '0 0.4rem',
  },
  wrapper: {
    marginRight: '10px',
    height: '100%',
    minWidth: "min-content",
    color: tokens.colorNeutralForeground3,
    ':hover': {
      backgroundColor: '#DEECF9',
      color: '#106EBE',
    },
    "& div": {
      display: 'flex',
      alignItems: 'center'
    },
  },
});


export const Default = (args) => <CounterBadge {...args} />;
export const ClickButton = ({
  link,
  iconName,
  text,
  value,
  onClick,
  params,
  disabled,
  tooltipText,
  addStyles,
  getProductBalance,
  dataArray,
  icon,
  title,
  optionSize,
  isButton
}) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        icon={iconName ?? < FilterRegular />}
        checked={value}
        className={clsx(classes.wrapper)}
        onClick={onClick} >
      </Button>
    </div >
  );
};