import { Spinner } from '@fluentui/react';
import React, { useState, useMemo } from 'react';
import s from './Table.module.scss';
import g from '../../assets/scss/Main.module.scss';
import p from '../../pages/Products/Products.module.scss';
import clsx from 'clsx';
import { ArrowDownRegular, ArrowUpRegular } from '@fluentui/react-icons';
import { useTableColumnResizing } from './TableColumnResizingHook'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useDragAndDrop } from './DragAndDropHook';

const spinnerStyles = {
  circle: {
    height: 60,
    width: 60,
    borderWidth: 4
  }
};

export const Table = React.memo(
  ({
    headerItems,
    maxHeight,
    isFixedHeight = true,
    loading,
    children,
    onSort,
    sortState,
    isReport = false,
    customStyles = null,
    customHeaderClass = null,
    onColumnReorder,
    tableName,
    maxTableWidth,
    leftSpaceMenu
  }) => {
    const {
      isResizing,
      handleResizeStart,
      getColumnWidth,
      columnWidths,
      columnOrder,
      updateState,
      tableId,
      orderedHeaderItems: resizingOrderedHeaderItems
    } = useTableColumnResizing({}, headerItems, tableName, maxTableWidth, leftSpaceMenu);

    const {
      draggingColumnId,
      handleDragStart,
      handleDragEnd
    } = useDragAndDrop(
      headerItems,
      (sourceIndex, destinationIndex, newOrder) => {
        // Обновляем состояние ресайза с новым порядком
        updateState(columnWidths, newOrder);
        // Вызываем колбэк для обновления данных
        if (onColumnReorder) {
          onColumnReorder(sourceIndex, destinationIndex);
        }
      },
      tableName
    );

    // Используем orderedHeaderItems из хука useTableColumnResizing
    const orderedHeaderItems = resizingOrderedHeaderItems;

    const getLenghtOfTheRightChild = (arr) =>
      arr.filter((child) => child !== false)[0]?.length || 0;
    const renderSortIcon = (columnId) => {
      const sortDirection = sortState.getSortDirection(columnId);

      const iconProps = {
        style: {
          marginRight: '0.5rem',
          height: '12px',
          width: '12px',
          color: sortDirection ? '#0078D4' : 'inherit'
        }
      };

      return sortDirection === 'ascending'
        ? <ArrowUpRegular  {...iconProps} />
        : <ArrowDownRegular  {...iconProps} />;
    };

    const handleResizeStartWrapper = (e, columnId) => {
      e.stopPropagation();
      if (draggingColumnId) return;
      handleResizeStart(e, columnId);
    };

    // Функция для применения стилей ширины к дочерним элементам
    const applyColumnWidthsToChildren = (children) => {
      if (!children) return null;

      // Создаем карту для быстрого доступа к заголовкам по columnId
      const headerMap = new Map(
        orderedHeaderItems.map((item, index) => [item.columnId, { item, index }])
      );

      return React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return child;

        // Получаем все дочерние элементы строки
        const rowChildren = React.Children.toArray(child.props.children);

        // Создаем новый массив ячеек в порядке, соответствующем orderedHeaderItems
        const orderedCells = [];

        // Для каждого заголовка в orderedHeaderItems находим соответствующую ячейку
        orderedHeaderItems.forEach((headerItem) => {
          // Ищем ячейку с соответствующим ключом или data-column-id
          const cell = rowChildren.find(cell => {
            if (!React.isValidElement(cell)) return false;

            // Проверяем key или data-column-id
            const cellKey = cell.key;
            const dataColumnId = cell.props['data-column-id'] ||
              cell.props.dataColumnId ||
              (cell.props.key && cell.props.key.toString());

            return cellKey === headerItem.columnId ||
              dataColumnId === headerItem.columnId;
          });

          if (cell) {
            orderedCells.push(cell);
          }
        });

        // Если не нашли ячейки по ключам, используем порядок по умолчанию
        if (orderedCells.length === 0) {
          // Для совместимости со старым кодом
          return React.cloneElement(child, {
            style: {
              ...child.props.style,
              display: 'flex',
            },
            children: rowChildren.map((cell, index) => {
              if (!React.isValidElement(cell)) return cell;

              const headerItem = orderedHeaderItems[index];
              if (!headerItem) return cell;

              const defaultWidth = `calc((100vw - 120px - 3.75rem) * ${headerItem.width})`;
              const width = getColumnWidth(headerItem.columnId, defaultWidth);
              const isDragging = headerItem.columnId === draggingColumnId;

              return React.cloneElement(cell, {
                style: {
                  ...cell.props.style,
                  width: width,
                  minWidth: width,
                  maxWidth: width,
                },
                className: clsx(
                  cell.props.className,
                  isDragging && s.draggingColumn,
                  isDragging && s.dragging
                )
              });
            })
          });
        }

        // Клонируем строку с новым порядком ячеек
        return React.cloneElement(child, {
          style: {
            ...child.props.style,
            display: 'flex',
          },
          children: orderedCells.map((cell) => {
            if (!React.isValidElement(cell)) return cell;

            // Получаем columnId из ключа или data-column-id
            const cellKey = cell.key;
            const dataColumnId = cell.props['data-column-id'] ||
              cell.props.dataColumnId ||
              (cell.props.key && cell.props.key.toString());
            const columnId = dataColumnId || cellKey;

            // Находим соответствующий заголовок
            const headerInfo = headerMap.get(columnId);
            if (!headerInfo) return cell;

            const { item: headerItem } = headerInfo;

            const defaultWidth = `calc((100vw - 120px - 3.75rem) * ${headerItem.width})`;
            const width = getColumnWidth(headerItem.columnId, defaultWidth);
            const isDragging = headerItem.columnId === draggingColumnId;

            return React.cloneElement(cell, {
              style: {
                ...cell.props.style,
                width: width,
                minWidth: width,
                maxWidth: width,
              },
              className: clsx(
                cell.props.className,
                isDragging && s.draggingColumn,
                isDragging && s.dragging
              )
            });
          })
        });
      });
    };

    return (
      <div
        className={clsx(s.table, g.contentComponent, tableId, customStyles && customStyles, {
          [s.resizing]: isResizing
        })}
        style={{ maxHeight: maxHeight, margin: '0.5rem 1rem' }}
        data-table-name={tableName || 'default'}
      >
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <Droppable droppableId={`table-header-${tableName || 'default'}`} direction="horizontal">
            {(provided) => (
              <div
                className={clsx(s.tableHeader, customHeaderClass && customHeaderClass)}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {orderedHeaderItems.map((headerItem, index) => {
                  const defaultWidth = `calc((100vw - 120px - 3.75rem) * ${headerItem.width})`;
                  const width = getColumnWidth(headerItem.columnId, defaultWidth);
                  const isDragging = headerItem.columnId === draggingColumnId;

                  return (
                    <Draggable
                      key={headerItem.columnId}
                      draggableId={`${headerItem.columnId}-${tableName || 'default'}`}
                      index={index}
                      isDragDisabled={isResizing}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={clsx(s.tableHeaderItem, {
                            [s.sortable]: headerItem.sortable,
                            [s.dragging]: snapshot.isDragging,
                            [s.draggingColumn]: isDragging
                          })}
                          style={{
                            ...provided.draggableProps.style,
                            width: width,
                            minWidth: width,
                            maxWidth: width,
                          }}
                        >


                          <div
                            {...provided.dragHandleProps}
                            onClick={() => headerItem.sortable && onSort?.(headerItem.columnId)}
                            style={{
                              cursor: isResizing ? 'col-resize' : (headerItem.sortable ? 'pointer' : 'default'),
                              display: 'flex',
                              alignItems: 'center',
                              flex: 1,
                              height: '100%',
                              padding: '0 8px'
                            }}
                          >
                            <span>{headerItem.title}</span>
                            {headerItem.sortable && sortState && (
                              <span className={s.sortIcon}>
                                {renderSortIcon(headerItem.columnId)}
                              </span>
                            )}
                          </div>
                          {orderedHeaderItems.length !== index + 1 && (
                            <div
                              className={s.resizeHandle}
                              onMouseDown={(e) => handleResizeStartWrapper(e, headerItem.columnId)}
                              onTouchStart={(e) => handleResizeStartWrapper(e, headerItem.columnId)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div
          className={s.tableContent}
          style={
            isFixedHeight
              ? { height: `calc(${maxHeight} - 3.75rem` }
              : { maxHeight: `calc(${maxHeight} - 3.75rem` }
          }
        >
          {!loading ? (
            <div className={s.searchNothing}>
              {(!children?.length && !isReport) ||
                (isReport && !getLenghtOfTheRightChild(children)) ? (
                <span>Список результатов пуст</span>
              ) : (
                applyColumnWidthsToChildren(children)
              )}
            </div>
          ) : (
            <div className={s.loaderContainer} style={{ height: `calc(${maxHeight} - 4.75rem)` }}>
              <Spinner styles={spinnerStyles} />
            </div>
          )}
        </div>
      </div>
    );
  }
);