import Select from 'react-select';
import clsx from 'clsx';
import s from './comboSelect.module.scss';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '36px',
    height: '2.2rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    border: state.focused ? '1px solid blue' : '1px solid rgb(50, 49, 48)',
    borderRadius: '0'
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 19999 }),
  menu: (provided) => ({ ...provided, zIndex: 19999 }),
  indicatorSeparator: () => null,
  valueContainer: (provided) => ({
    ...provided,
    // paddingBottom: '0.1rem',
    color: 'rgb(50, 49, 48)',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.1rem'
  }),
  placeholder: (provided) => ({ ...provided, paddingBottom: '0.1rem', color: 'rgb(50, 49, 48)' })
};

const ComboSelect = ({
  options,
  onChange,
  label,
  placeholder,
  isSearchable = true,
  isDisabled = false,
  customFieldStyles,
  customClassNames = []
}) => {
  return (
    <section
      className={clsx([s.selectContainer, ...customClassNames])}
      style={{ ...customFieldStyles }}
    >
      <span className={s.label}>{label}</span>
      <div className={s.select}>
        <Select
          menuPortalTarget={document.section}
          menuPosition={'fixed'}
          styles={customStyles}
          // classNamePrefix="select"
          // defaultValue={colourOptions[0]}
          isDisabled={isDisabled}
          // isLoading={isLoading}
          // isClearable={isClearable}
          // isRtl={isRtl}
          isSearchable={isSearchable}
          // name="color"
          options={options}
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    </section>
  );
};

export default ComboSelect;
