import { Icon } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useCrumbsContext } from '../../context/CrumbsContext';
import s from './Crumbs.module.scss';
import { MenuItem, MenuList, makeStyles, tokens } from '@fluentui/react-components';
import { accent } from 'constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { darker } from 'constants';
import { navLinkGroups, pathWithState } from 'components/Menu/navLinkGroups';
import { flatArr } from 'functions';

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    display: 'flex',
    height: '5rem'
  },
  content: {
    flex: '1',
    padding: '1rem',
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  field: {
    display: 'flex',
    marginTop: '0.25rem',
    marginLeft: '0.5rem',
    flexDirection: 'column',
    gridRowGap: tokens.spacingVerticalS
  },
  selectedMenuItem: {
    backgroundColor: '#C7E0F4 !important',
    borderBottom: `0.20rem solid ${darker}`,
    borderRadius: '0px',
    minHeight: '5px',

    '& div:hover': {
      backgroundColor: { accent },
      color: 'black'
    },
    '& span': {
      borderRadius: '0px',
      color: 'black !important'
    }
  },
  menuBlock: {
    display: 'flex',
    flexDirection: 'row',

    '& div': {
      backgroundColor: 'transparent',
      minWidth: '80px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      gap: 0,
      color: 'white',
      ':hover': {
        backgroundColor: 'transparent',
        color: 'white',
        borderColor: 'transparent',
        borderRadius: '0px'
      },
      '& :active :hover': {
        backgroundColor: 'transparent',
        color: 'white',
        borderColor: 'transparent'
      }
    }
  }
});

export const Crumbs = () => {
  const { crumbs } = useCrumbsContext();
  const location = useLocation();
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState(location?.pathname);
  const styles = useStyles();
  const flatLinks = flatArr(navLinkGroups[0].links, []);

  const convertedPath = location.pathname.split('/').filter((part) => part);

  const convertedPathCrumbs = convertedPath.reduce((acc, item, index) => {
    if (index === 0) {
      acc.push(`${item}`);
    } else {
      acc.push(`${acc[index - 1]}/${item}`);
    }
    return acc;
  }, []);

  const menuOptions = navLinkGroups[0].links.find((item) => item.key === `/${convertedPathCrumbs[0]}`);

  const onLinkClick = useCallback(
    (e, item) => {
      setSelectedKey(item?.key);

      if (pathWithState?.includes(`${item?.key}`)) {
        history.replace({ pathname: item?.key, state: { prevPath: location.pathname } });
      } else {
        history.replace(item?.key);
      }
    },
    [history, location.pathname]
  );

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location]);

  const searchPathInNavLinkGroups = (index) => {
    // если нужно добавить уникальное название для каждой хлебной крошки, 
    // добавьте в массив navLinkGroups новый объект меню (что автоматомический добавить в меню кнопку)
    // или в комопоненте где нужно название добавить useEffect c setCrumbs[individualName, individualName, individualName]
    // обратите внмиание что порядок имеет значение (например, путь компонента /reports/printing/id а в setCrumbs порядок такой: [individualName, individualName, individualName])

    const res = flatLinks.find((item) => item?.key === `/${convertedPathCrumbs[index]}`)?.name

    if (res === undefined && crumbs[index] !== undefined) {
      return crumbs[index];
    } else if (res === undefined) {
      return convertedPath[index];
    }
    return res;
  }

  const renderCrumbs = () => {
    const res = convertedPathCrumbs.map((item, i) => {
      switch (true) {
        case !!flatLinks.find((element) => element?.key === `/${item}`):
          return (
            <React.Fragment key={i}>
              {i !== 0 ? <Icon className={s.crumbIcon} iconName="ChevronRight" /> : null}
              <NavLink
                className={s.title}
                to={{ pathname: `/${convertedPathCrumbs[i]}`, state: { prevPath: location.pathname } }}
              >
                <p className={s.crumbText}>{searchPathInNavLinkGroups(i)}</p>
              </NavLink>
            </React.Fragment>
          );
        default:
          return (
            <React.Fragment key={i + 3}>
              {i !== 0 ? <Icon className={s.crumbIcon} iconName="ChevronRight" /> : null}
              <p className={s.crumbText}>{searchPathInNavLinkGroups(i)}</p>
            </React.Fragment>
          )
      }
    });
    return res;
  }

  return (
    <>
      <div className={`${s.container} ${s.containeFlexSwitch}`}>
        <div className={s.title}>
          {renderCrumbs()}
        </div>
        <div className={s.menuBar}>
          <MenuList
            className={styles.menuBlock}
          >
            {(convertedPathCrumbs.length < 3 && ('reports' === convertedPathCrumbs[0] || 'settings' === convertedPathCrumbs[0])) ? menuOptions?.links?.map((item, i) => {
              return (
                <MenuItem
                  key={item.key}
                  onClick={(e) => onLinkClick(e, item)}
                  value={i}
                  className={selectedKey === item.key ? styles.selectedMenuItem : ''}
                >
                  {item.name}
                </MenuItem>
              );
            }) : null}
          </MenuList>
        </div>
      </div>
    </>
  );
};