import React, { useEffect, useState, useCallback } from 'react';
import AggregatesAPI from '../../api/AggregatesAPI';
import g from '../../assets/scss/Main.module.scss';
import s from './Aggregates.module.scss';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { aggregateStatuses } from '../../constants';
import { getDateFromTimestamp, getStatusById, mapItemsForDropdown, mapProductsForDropdown } from '../../functions';
import { useCheckboxFilter, usePaginationAndSearch, useDataFilter, useSelect } from '../../hooks';
import clsx from 'clsx';
import { useCrumbsContext } from 'context/CrumbsContext';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { ButtonMenu } from 'components/ButtonMenu/ButtonMenu';
import { LinkELement } from 'components/LinkELement/LinkELement';
import { AggregatesFilterNew } from 'components/Filters/AggregatesFilterNew/AggregatesFilterNew';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import productsAPI from 'api/productsAPI';
import legalAPI from 'api/legalAPI';
import { DirectionalHint, TooltipDelay, TooltipHost } from '@fluentui/react';
import { Tooltip, useTableFeatures, useTableSort } from '@fluentui/react-components';

const tableHeader = [
  {
    title: 'Код агрегата',
    width: '17',
    columnId: 'unitSerialNumber',
    sortable: false,
  },
  {
    title: 'Дата создания',
    width: '13',
    columnId: 'creationDate',
    sortable: true,
  },
  {
    title: 'Ёмкость',
    width: '10',
    columnId: 'aggregationUnitCapacity',
    sortable: true,
  },
  {
    title: 'Уровень',
    width: '10',
    columnId: 'level',
    sortable: true,
  },
  {
    title: 'Статус',
    width: '13',
    columnId: 'status',
    sortable: true,
  },
  {
    title: 'Продукт',
    width: '25',
    columnId: 'product.name',
    sortable: true,
  },
];

export const Aggregates = ({ status, statusParamName }) => {
  const [aggregates, setAggregates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('aggregates');
  const { setCrumbs } = useCrumbsContext();
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [columns, setColumns] = useState(() => {
    // Пытаемся получить сохраненное состояние
    try {
      const savedState = sessionStorage.getItem('table_aggregates_state');

      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);

        if (columnOrder && Array.isArray(columnOrder)) {
          // Восстанавливаем порядок колонок из сохраненного состояния
          const columnMap = new Map(tableHeader.map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          // Добавляем колонки, которых нет в сохраненном состоянии
          tableHeader.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return tableHeader;
  });

  useEffect(() => {
    if (status && !query.status) {
      setQuery((prev) => ({ ...prev, status: status }), 'replace');
    }
  }, [status]);

  const getAggregates = async (query) => {
    setLoading(true);
    let res = await AggregatesAPI.getAggregates(query);

    if (!res?.statusCode) {
      const { list, quantity } = res;
      setAggregates(list);
      setTotalItems(quantity);
    }
    setLoading(false);
    return res;
  };

  const [productsForDropdown, getProductsForDropdown] = useSelect(
    productsAPI.getAllProducts,
    null,
    mapProductsForDropdown
  );
  const [levelsForDropdown] = useState([
    { key: 1, text: 1 },
    { key: 2, text: 2 }
  ]);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  useEffect(() => {
    getProductsForDropdown();
    legalAPI.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
    setCrumbs(['/aggregates']);
  }, []);

  useEffect(() => {
    const filterData = [
      { name: 'Продукт', key: 'productId', options: productsForDropdown },
      { name: 'Уровень', key: 'level', options: levelsForDropdown },
      { name: 'Компания', key: 'companyId', options: companiesForDropdown },
      {
        name: 'Дата создания от',
        key: 'minCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        name: 'Дата создания до',
        key: 'maxCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      }
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: { key: option.key, text: option.text, category: filter.key, name: filter.name }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              category: filter.key,
              name: filter.name
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, companiesForDropdown, productsForDropdown, levelsForDropdown]);

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getAggregates, query, setQuery, statusParamName, status, headerHeight: 130 });
  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    aggregateStatuses,
    'status',
    setQuery,
    query,
  );

  const {
    sort: { getSortDirection, toggleColumnSort },
  } = useTableFeatures(
    {
      columns: columns,
      items: aggregates,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: 'creationDate', sortDirection: 'ascending' },
      }),
    ]
  );

  const handleSort = (columnId) => {
    const event = { preventDefault: () => { } };
    const currentDirection = getSortDirection(columnId);

    toggleColumnSort(event, columnId);

    const direction = currentDirection === 'ascending' ? -1 : 1;

    const sortedAggregates = [...aggregates].sort((a, b) => {
      if (columnId.includes('.')) {
        const [parent, child] = columnId.split('.');
        const valueA = a[parent]?.[child] ?? '';
        const valueB = b[parent]?.[child] ?? '';
        return String(valueA).localeCompare(String(valueB)) * direction;
      }

      if (columnId === 'creationDate') {
        return (new Date(a[columnId] || 0) - new Date(b[columnId] || 0)) * direction;
      }

      if (typeof a[columnId] === 'number' && typeof b[columnId] === 'number') {
        return (a[columnId] - b[columnId]) * direction;
      }

      const valueA = a[columnId] ?? '';
      const valueB = b[columnId] ?? '';
      return String(valueA).localeCompare(String(valueB)) * direction;
    });

    setAggregates(sortedAggregates);
  };

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);

    // Обновляем данные в соответствии с новым порядком колонок
    const reorderedAggregates = aggregates.map(item => {
      const newItem = {};
      newColumns.forEach(column => {
        if (column.columnId.includes('.')) {
          const [parent, child] = column.columnId.split('.');
          if (!newItem[parent]) newItem[parent] = {};
          newItem[parent][child] = item[parent]?.[child];
        } else {
          newItem[column.columnId] = item[column.columnId];
        }
      });
      return { ...item, ...newItem };
    });
    setAggregates(reorderedAggregates);
  }, [columns, aggregates]);
  console.log(columns, 'columns aggregates');
  console.log(aggregates, 'aggregates aggregates');

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            className={g.search}
            value={query.searchValue}
            placeholder="Поиск по коду агрегата"
            onClear={() => onSearchTermChanged('')}
            onSearch={(newValue) => onSearchTermChanged(newValue)}
          />
        </div>
        <div className={clsx(g.headerFilters)} >
          <ButtonMenu
            title={'Статус'}
            dataArray={aggregateStatuses}
            selectedFilters={selectedFilters}
            onChangeFilter={onChangeFilter}
            isCheckbox={true}
            optionSize={selectedFilters.size}
          />
          <ButtonToggle
            text="Фильтры"
            value={isOpenFilters}
            onClick={() => setIsOpenFilters(!isOpenFilters)}
            optionSize={selectedOptions.length}
          />
        </div>
      </div>
      {isOpenFilters && (
        <div className={g.filtersWrapper}>
          <AggregatesFilterNew
            productsForDropdown={productsForDropdown}
            levelsForDropdown={levelsForDropdown}
            companiesForDropdown={companiesForDropdown}
            query={query}
            setQuery={setQuery}
            setIsOpenFilters={setIsOpenFilters}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </div>
      )}
      <div className={s.mainContent}>
        <div className={s.tableWrapper}>
          {!aggregates.length && selectedOptions.length ? (
            <div className={g.searchWithFiltersVoid}>
              Не найдено. Измените параметры фильтрации
            </div>
          ) : (
            <Table
              headerItems={columns}
              loading={loading}
              onSort={handleSort}
              sortState={{ getSortDirection }}
              onColumnReorder={handleColumnReorder}
              tableName="aggregates"
              customHeaderClass={s.tableHeader}
            >
              {aggregates?.map((item) => {
                return (
                  <div key={item.id} className={s.tableItem}>
                    {columns.map((column, index) => {
                      let content;
                      let tooltipContent;

                      if (column.columnId === 'unitSerialNumber') {
                        content = (
                          <LinkELement
                            addStyles={clsx(s.tableRow, s.firstRow)}
                            link={`/aggregates/${item.unitSerialNumber}`}
                            text={item.unitSerialNumber}
                            appearance={'subtle'}
                          />
                        );
                        tooltipContent = item.unitSerialNumber;
                      } else if (column.columnId === 'creationDate') {
                        content = getDateFromTimestamp(item.creationDate, null, { withoutTime: true });
                        tooltipContent = content;
                      } else if (column.columnId === 'status') {
                        content = getStatusById(item.status, aggregateStatuses);
                        tooltipContent = content;
                      } else if (column.columnId === 'product.name') {
                        content = item?.product?.name;
                        tooltipContent = content;
                      } else {
                        content = item[column.columnId];
                        tooltipContent = content;
                      }

                      return (
                        <div
                          key={column.columnId}
                          className={clsx(s.tableCell, {
                            [s.tableCellProduct]: column.columnId === 'product.name'
                          })}
                          style={{ width: `calc((100vw - 180px - 3.75rem) * ${column.width})` }}
                        >
                          <div >
                            <Tooltip
                              content={tooltipContent}
                              delay={300}
                              directionalHint={DirectionalHint.topCenter}
                            >
                              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {content}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Table>
          )}
        </div>
      </div >
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </div >
  );
};