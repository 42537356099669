import { useId } from '@fluentui/react-hooks';
import { Icon, TooltipHost } from '@fluentui/react';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, makeStyles, tokens, Tooltip, Link } from '@fluentui/react-components';
import { MoreHorizontalRegular } from '@fluentui/react-icons';

const useClasses = makeStyles({
  target: {
  },
});
export const LinkELement = ({
  link,
  iconName,
  text,
  isButton = false,
  onClick,
  params,
  disabled,
  tooltipText,
  type = 'default',
  addStyles,
  style,
  getProductBalance,
  appearance,
  underline = false
}) => {
  const classes = useClasses()
  const tooltipId = useId('tooltip');

  return (
    <div className={addStyles} style={style}>
      {/* <Tooltip content={null} relationship="label" showDelay={800}  > */}
      <NavLink to={{
        pathname: link,
        state: params

      }}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block',
          maxWidth: '100%'
        }}

      >
        <Link
          appearance={appearance}
          onClick={onClick}
          icon={isButton ? null : iconName ?? <MoreHorizontalRegular />}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
            maxWidth: '100%'
          }}

        >
          {tooltipText && <Tooltip showDelay={300} content={tooltipText}>
            <p>{text}</p>
          </Tooltip>}
          {!tooltipText && <p>{text}</p>}
        </Link>
      </NavLink>
      {/* </Tooltip> */}
    </div>
  );
};
