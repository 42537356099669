import API from './APIService'

class CheckupAPI {
  getCheckup = (id) => {
    // Декодируем escape-последовательности в символы
    const decodedId = id?.replace(/\\u([0-9a-fA-F]{4})/g, (_, hex) =>
      String?.fromCharCode(parseInt(hex, 16))
    );
    return API?.post(`/api/v2/administrator/marking-code-search/`, {
      fullcode: decodedId
    });
  }
}

export default new CheckupAPI()
