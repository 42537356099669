import clsx from 'clsx';
import { Checkbox, DirectionalHint, SearchBox, TooltipDelay, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState, useCallback } from 'react';
import ConsignmentsAPI from '../../api/consignmentsAPI';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { addIcon, consignmentStatuses, moreInfoIcon, searchBoxStyles } from '../../constants';
import { getDateFromTimestamp, getStatusById, mapItemsForDropdown, mapProductsForDropdown } from '../../functions';
import { useCheckboxFilter, usePaginationAndSearch, useDataFilter } from '../../hooks';
import g from '../../assets/scss/Main.module.scss';
import s from './Consignments.module.scss';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { ButtonMenu } from 'components/ButtonMenu/ButtonMenu';
import { ConsignmentsFilterNew } from 'components/Filters/ConsignmentsFilterNew/ConsignmentsFilterNew';
import productsAPI from 'api/productsAPI';
import linesAPI from 'api/linesAPI';
import legalAPI from 'api/legalAPI';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import { Tooltip, useTableFeatures, useTableSort } from '@fluentui/react-components';

const tableHeader = [
  {
    title: 'Номер',
    width: '10',
    columnId: 'taskNumber',
    sortable: false
  },
  {
    title: 'Дата производства',
    width: '15',
    columnId: 'productCreationDate',
    sortable: true
  },
  {
    title: 'Продукт',
    width: '25',
    columnId: 'product.name',
    sortable: true
  },
  {
    title: 'Производственная линия',
    width: '20',
    columnId: 'productLine.name',
    sortable: true
  },
  {
    title: 'План/факт',
    width: '10',
    columnId: 'plannedValue',
    sortable: true
  },
  {
    title: 'Статус',
    width: '10',
    columnId: 'status',
    sortable: true
  },
  {
    title: 'Действия',
    width: '10',
    columnId: 'actions',
    sortable: false,
    minWidth: 120
  }
];

export const Consignments = ({ status, statusParamName }) => {
  const [consignments, setConsignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('consignments');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productsForDropdown, setProductsForDropdown] = useState([]);
  const [linesForDropdown, setLinesForDropdown] = useState([]);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [columns, setColumns] = useState(() => {
    try {
      const savedState = sessionStorage.getItem('table_consignments_state');
      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);
        if (columnOrder && Array.isArray(columnOrder)) {
          const columnMap = new Map(tableHeader.map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          tableHeader.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return tableHeader;
  });

  useEffect(() => {
    if (status && !query.status) {
      setQuery((prev) => ({ ...prev, status: status }), 'replace');
    }
  }, [status]);

  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    consignmentStatuses,
    'status',
    setQuery,
    query
  );

  useEffect(() => {
    productsAPI.getAllProducts().then((res) => setProductsForDropdown(mapProductsForDropdown(res)));
    linesAPI.getAllLines().then((res) => setLinesForDropdown(mapItemsForDropdown(res)));
    legalAPI.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  useEffect(() => {
    const filterData = [
      { label: 'Продукт', key: 'productId', options: productsForDropdown },
      { label: 'Производственная линия', key: 'lineId', options: linesForDropdown },
      { label: 'Компания', key: 'companyId', options: companiesForDropdown },
      {
        label: 'Дата производства от',
        key: 'minProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата производства до',
        key: 'maxProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      }
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: { key: option.key, text: option.text, category: filter.key, label: filter.label }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              category: filter.key,
              label: filter.label
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, companiesForDropdown, productsForDropdown, linesForDropdown]);

  const getConsignments = async (query) => {
    setLoading(true);
    let res = await ConsignmentsAPI.getConsignments(query);

    if (!res.statusCode) {
      const { list, quantity } = res;
      await setConsignments(list);
      await setTotalItems(quantity);
    }

    await setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getConsignments, query, setQuery, status, statusParamName, headerHeight: 220 });

  const {
    sort: { getSortDirection, toggleColumnSort },
  } = useTableFeatures(
    {
      columns: columns,
      items: consignments,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: 'taskNumber', sortDirection: 'ascending' },
      }),
    ]
  );

  const handleSort = (columnId) => {
    const event = { preventDefault: () => { } };
    const currentDirection = getSortDirection(columnId);

    toggleColumnSort(event, columnId);

    const direction = currentDirection === 'ascending' ? -1 : 1;

    const sortedConsignments = [...consignments].sort((a, b) => {
      if (columnId.includes('.')) {
        const [parent, child] = columnId.split('.');
        const valueA = a[parent]?.[child] ?? '';
        const valueB = b[parent]?.[child] ?? '';
        return String(valueA).localeCompare(String(valueB)) * direction;
      }

      if (columnId === 'productCreationDate') {
        return (new Date(a[columnId] || 0) - new Date(b[columnId] || 0)) * direction;
      }

      if (typeof a[columnId] === 'number' && typeof b[columnId] === 'number') {
        return (a[columnId] - b[columnId]) * direction;
      }

      const valueA = a[columnId] ?? '';
      const valueB = b[columnId] ?? '';
      return String(valueA).localeCompare(String(valueB)) * direction;
    });

    setConsignments(sortedConsignments);
  };

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);
    console.log(newColumns, 'consignments newColumns');

    // Обновляем данные в соответствии с новым порядком колонок
    const reorderedConsignments = consignments.map(item => {
      const newItem = {};
      newColumns.forEach(column => {
        if (column.columnId.includes('.')) {
          const [parent, child] = column.columnId.split('.');
          if (!newItem[parent]) newItem[parent] = {};
          newItem[parent][child] = item[parent]?.[child];
        } else {
          newItem[column.columnId] = item[column.columnId];
        }
      });
      return { ...item, ...newItem };
    });
    console.log(reorderedConsignments, 'consignments reorderedConsignments');
    setConsignments(reorderedConsignments);
  }, [columns, consignments]);

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            className={g.search}
            value={query.searchValue}
            placeholder="Поиск по номеру задания"
            onClear={() => onSearchTermChanged('')}
            onSearch={(newValue) => onSearchTermChanged(newValue)}
          />
        </div>
        <div className={clsx(g.headerFilters)} >
          <ButtonMenu
            title={'Статус'}
            dataArray={consignmentStatuses}
            selectedFilters={selectedFilters}
            onChangeFilter={onChangeFilter}
            isCheckbox={true}
            optionSize={selectedFilters.size}
          />
          <ButtonToggle
            text="Фильтры"
            value={isOpenFilters}
            onClick={() => setIsOpenFilters(!isOpenFilters)}
            optionSize={selectedOptions.length}
          />
        </div>
      </div>

      {isOpenFilters && (
        <div className={g.filtersWrapper}>
          <ConsignmentsFilterNew
            query={query}
            setQuery={setQuery}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            productsForDropdown={productsForDropdown}
            linesForDropdown={linesForDropdown}
            companiesForDropdown={companiesForDropdown}
            setCompaniesForDropdown={setCompaniesForDropdown}
            setIsOpenFilters={setIsOpenFilters}
          />
        </div>
      )}

      <div className={s.mainContent}>
        <div style={{ margin: '0.75rem 0 0.75rem 1rem' }}>
          <LinkButton
            link={`consignments/add`}
            isButton={true}
            text="Создать задание"
            primary={true}
          />
        </div>

        <div className={s.tableWrapper}>
          {!consignments.length && selectedOptions.length ? (
            <div className={g.searchWithFiltersVoid}>
              Не найдено. Измените параметры фильтрации
            </div>
          ) : (
            <Table
              headerItems={columns}
              loading={loading}
              onSort={handleSort}
              sortState={{ getSortDirection }}
              onColumnReorder={handleColumnReorder}
              tableName="consignments"
              customHeaderClass={s.tableHeader}
            >
              {consignments?.map((item) => {
                return (
                  <div key={item.id} className={s.tableItem}>
                    {columns.map((column) => {
                      let content;
                      let tooltipContent;

                      if (column.columnId === 'taskNumber') {
                        content = item.taskNumber;
                        tooltipContent = content;
                      } else if (column.columnId === 'productCreationDate') {
                        content = getDateFromTimestamp(item.productCreationDate, null, { withoutTime: true });
                        tooltipContent = content;
                      } else if (column.columnId === 'product.name') {
                        content = item.product?.name;
                        tooltipContent = content;
                      } else if (column.columnId === 'productLine.name') {
                        content = item.productLine?.name;
                        tooltipContent = content;
                      } else if (column.columnId === 'plannedValue') {
                        content = `${item.plannedValue}/${item.actualValue}`;
                        tooltipContent = content;
                      } else if (column.columnId === 'status') {
                        content = getStatusById(item.status, consignmentStatuses);
                        tooltipContent = content;
                      } else if (column.columnId === 'actions') {
                        return (
                          <div
                            key={column.columnId}
                            className={s.tableActionCell}
                            style={{ width: `calc((100vw - 180px - 3.75rem) * ${column.width})` }}
                          >
                            <LinkButton
                              addStyles={clsx(s.linkButton)}
                              link={`/consignments/${item.id}`}
                              isButton={true}
                              text="Подробнее"
                            />
                          </div>
                        );
                      } else {
                        content = item[column.columnId];
                        tooltipContent = content;
                      }

                      return (
                        <div
                          key={column.columnId}
                          className={clsx(s.tableCell, {
                            [s.tableCellProduct]: column.columnId === 'product.name'
                          })}
                          style={{ width: `calc((100vw - 180px - 3.75rem) * ${column.width})` }}
                        >
                          <div>
                            <Tooltip
                              content={tooltipContent}
                              delay={300}
                              directionalHint={DirectionalHint.topCenter}
                            >
                              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {content}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Table>
          )}
        </div>
      </div>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};
