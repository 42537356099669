import { DirectionalHint, SearchBox } from '@fluentui/react';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import ReportsAPI from '../../api/reportsAPI';
import { Alert } from '../../components/Alert/Alert';
import { Loader } from '../../components/Loader/Loader';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { reportStatuses, sendIcon } from '../../constants';
import { useCrumbsContext } from '../../context/CrumbsContext';
import { getDateFromTimestamp, getStatusById } from '../../functions';
import { useAlert, usePaginationAndSearch, useSendRequest } from '../../hooks';
import { cellWidth } from '../../functions';
import g from '../../assets/scss/Main.module.scss';
import s from './Report.module.scss';
import PrintReportModal from './SendEnteringReportModal';
import CorrectionsModal from './corrections/corrections-modal';
import { getShouldCheckExpDateForPrintRep } from 'redux/App/appSelectors';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { ReactComponent as CopyIcon } from '../../assets/img/CopyIcon.svg';
import { Tooltip } from '@fluentui/react-components';

const tableHeader = [
  {
    title: 'Код маркировки',
    width: '0.5',
    columnId: 'value',
    sortable: true,
  },
  {
    title: 'Дата добавления',
    width: '0.5',
    columnId: 'creationDate',
    sortable: true,
  }
];

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
}

export const Report = () => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModal, setShowModal] = useState(false);
  const { setCrumbs } = useCrumbsContext();
  const { state } = useLocation();
  const location = useLocation();
  const [disable, setDisable] = useState(false);
  const [isEntModal, setShowEntModal] = useState(false);
  const [buttonText, setButtonText] = useState('Отправить отчёт');
  const [initCorrVals, setInitCorrVals] = useState(null);
  const [reportStatus, setStatus] = useState(null);
  const shouldCheckExpDateForPrintRep = useSelector(getShouldCheckExpDateForPrintRep);
  let { reportType, reportId } = useParams();

  const [columns, setColumns] = useState(() => {
    try {
      const savedState = sessionStorage.getItem(`table_report_${reportType}_state`);
      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);
        if (columnOrder && Array.isArray(columnOrder)) {
          const columnMap = new Map(tableHeader.map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          tableHeader.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return tableHeader;
  });

  const [sortState, setSortState] = useState({
    columnId: null,
    direction: null,
    getSortDirection: (columnId) => {
      if (sortState.columnId !== columnId) return null;
      return sortState.direction;
    }
  });

  const handleSort = useCallback((columnId) => {
    setSortState(prevState => {
      const newDirection =
        prevState.columnId === columnId && prevState.direction === 'ascending'
          ? 'descending'
          : 'ascending';

      return {
        ...prevState,
        columnId,
        direction: newDirection,
        getSortDirection: (id) => {
          if (id !== columnId) return null;
          return newDirection;
        }
      };
    });

    // Here you would typically sort your data based on the column and direction
    // For now, we'll just log it
    console.log(`Sorting by ${columnId} in ${sortState.direction === 'ascending' ? 'descending' : 'ascending'} order`);
  }, []);

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);
  }, [columns]);

  useEffect(() => {
    if (
      reportType === 'entering' &&
      report &&
      'reportInfo' in report &&
      'statusCorrectingDocument' in report.reportInfo &&
      report.reportInfo.statusCorrectingDocument !== 0
    ) {
      setStatus(getStatusById(report.reportInfo.statusCorrectingDocument, reportStatuses));
    } else if (
      reportType === 'printing' &&
      report &&
      'reportInfo' in report &&
      'status' in report.reportInfo
    ) {
      setStatus(getStatusById(report.reportInfo.status, reportStatuses));
    }
  }, [
    report,
    reportType,
    report?.reportInfo,
    report?.reportInfo?.status,
    report?.reportInfo?.statusCorrectingDocument
  ]);

  useEffect(() => {
    if (
      reportType === 'entering' &&
      report &&
      'reportInfo' in report &&
      'status' in report.reportInfo &&
      (report.reportInfo.status === 3 || report.reportInfo.status === 1000)
    ) {
      setButtonText('Отправить корректировку');
    }
  }, [report, report?.reportInfo, report?.reportInfo?.status, reportType]);

  useEffect(() => {
    if (report && 'reportInfo' in report) {
      const {
        correctVsd,
        correctProductionDate,
        correctExpirationDate,
        correctVariableExpiration,
        correctWeight,
        correctTnVed,
        correctPermitDocs,
        correctLicenses,
        correctEmissionType
      } = report.reportInfo;

      const initVals = {
        correctVsd,
        correctProductionDate,
        correctExpirationDate,
        correctVariableExpiration,
        correctWeight,
        correctTnVed,
        correctPermitDocs,
        correctLicenses,
        correctEmissionType
      };
      setInitCorrVals(initVals);
    }
  }, [report, report?.reportInfo]);

  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const history = useHistory();

  const [query, setQuery] = useQueryParams({
    reportId: NumberParam,
    pageNumber: NumberParam,
    quantityOnPage: NumberParam,
    status: StringParam,
    searchValue: StringParam
  });

  useEffect(() => {
    if (
      (reportType === 'printing' || reportType === 'entering') &&
      report &&
      'reportInfo' in report &&
      report.reportInfo.testMode
    ) {
      setDisable(true);
    }
  }, [report, report?.reportInfo, reportType]);

  useEffect(() => {
    if (state?.reportId) {
      setQuery((prev) => ({ ...prev, reportId: reportId }), 'replace');
    } else {
      // setQuery({})
    }

    return async () => {
      // await setQuery({})
    };
  }, []);

  useEffect(() => {
    if (report) {
      setCrumbs([
        `/reports/${reportType}`,
        location.pathname.slice(0, location.pathname.lastIndexOf('/')),
        `Отчёт ${report?.reportInfo.suzId || report?.reportInfo?.id || 'без номера'} `
      ]);
    }
  }, [report]);

  const { alertTitle, alertText, isAlertShowed, showAlert } = useAlert();
  const { afterSending } = useSendRequest(setLoading);

  const getReport = async (query, reportType) => {
    setLoading(true);
    let res;

    if (reportType === 'printing') {
      res = await ReportsAPI.getUtilisationReportById(query);
    }

    if (reportType === 'aggregation') {
      res = await ReportsAPI.getAggregationReportById(query);
    }

    if (reportType === 'entering') {
      res = await ReportsAPI.getRolloutReportById(query);
    }

    if (reportType === 'rejection') {
      res = await ReportsAPI.getRejectReportById(query);
    }

    if (reportType === 'sets') {
      res = await ReportsAPI.getSetsReportById(query);
    }

    if (reportType === 'withrdIntrnlConsmpt') {
      res = await ReportsAPI.getInternalWithdrowalReportById(query);
      res.markingCodes.list = res.markingCodes.list.map(i => {
        i.creationDate = i.addedUtcDateTime;
        return i;
      });
    }
    if (reportType === 'writeOff') {
      res = await ReportsAPI.getInternalWriteOffReportById(query);
      res.markingCodes.list = res.markingCodes.list.map(i => {
        i.creationDate = i.addedUtcDateTime;
        return i;
      });
    }
    if (!res?.statusCode) {
      // res.reportInfo.status = 3;
      setReport(res);
      setTotalItems(res?.markingCodes?.quantity);
    }

    setLoading(false);
  };

  const getReportInEntModal = getReport.bind(null, query);

  // ========================== SEND REPORT ======================================
  const sendReport = async () => {
    const onSuccess = () => {
      showAlert('Отправка отчёта', 'Отчёт успешно отправлен');
    };

    const reportArr = [report?.reportInfo.id];
    let res;

    if (reportType === 'printing' && shouldCheckExpDateForPrintRep) {
      setShowEntModal(true);
    } else {
      if (reportType === 'printing') {
        res = await ReportsAPI.sendUtilisationReports(reportArr);
      }

      if (reportType === 'aggregation') {
        res = await ReportsAPI.sendAggregationReports(reportArr);
      }

      if (
        reportType === 'entering' &&
        'reportInfo' in report &&
        'status' in report.reportInfo &&
        (report.reportInfo.status === 3 || report.reportInfo.status === 1000)
      ) {
        // res = await ReportsAPI.sendRolloutReports(reportArr);
        openModal();
      }
      if (
        reportType === 'entering' &&
        report &&
        'reportInfo' in report &&
        'status' in report.reportInfo &&
        report.reportInfo.status !== 3 &&
        report.reportInfo.status !== 1000
      ) {
        res = await ReportsAPI.sendRolloutReports(reportArr);
      }

      if (reportType === 'rejection') {
        res = await ReportsAPI.sendRejectReports(reportArr);
      }

      if (reportType === 'sets') {
        res = await ReportsAPI.sendSetsReports(reportArr);
      }

      if (reportType === 'withrdIntrnlConsmpt') {
        res = await ReportsAPI.sendInternalWithdrwlReports(reportArr);
      }

      afterSending(res, onSuccess);

      setLoading(false);
    }
  };

  // ========================== SEND REPORT ======================================

  const onSubmitPrintReport = async (data) => {
    const resData = data?.map((rep) => ({
      id: rep.id,
      productCreationDate: rep.productCreationDate,
      expirationDate: rep.expirationDate
    }));
    const onSuccess = () => {
      showAlert('Отправка отчёта', 'Отчёт успешно отправлен');
    };
    const res = await ReportsAPI.senPrinRepsParams(resData);

    afterSending(res, onSuccess);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getReport, query, setQuery, reportType });

  return (
    <div>
      {loading && <Loader />}
      {isAlertShowed && (
        <Alert
          title={alertTitle}
          text={alertText}
          onClose={() => {
            history.push(`/ reports / ${reportType} `);
          }}
        />
      )}
      {isEntModal && report ? (
        <PrintReportModal
          isOpen={isEntModal}
          onDismiss={() => setShowEntModal(false)}
          reports={[report.reportInfo]}
          onSubmit={onSubmitPrintReport}
        />
      ) : null}
      <div className={g.contentWrapper}>
        {report && (
          <div className={g.titleWrapper}>
            <div>
              <Tooltip showDelay={300} content={`${report?.reportInfo?.suzId || ''} `}>
                <h1 className={clsx(g.title, s.reportTitle)}>
                  {`Отчёт ${report?.reportInfo?.suzId || ''} `}
                </h1>
              </Tooltip>
              <h4 className={s.reportStatus}>{reportStatus}</h4>
            </div>
            <section className={s.bottomPart}>
              {(report.reportInfo.status === 0 ||
                report.reportInfo.status === 3 ||
                report.reportInfo.status === 1000) && (
                  <TableAction
                    iconName={sendIcon}
                    text={buttonText}
                    isButton={true}
                    onClick={sendReport}
                    addStyles={{ minWidth: '2rem' }}
                    disabled={disable}
                  />
                )}
              {buttonText === 'Отправить корректировку' ? (
                <span className={s.correctionText}>
                  При повторной отправке успешно обработанного отчета, он отправляется как
                  корректировка сведений
                </span>
              ) : null}
            </section>
          </div>
        )}
        <div className={s.reportDates}>
          {reportType === 'aggregation' && (
            <span className={s.aggregationCode}>
              Код агрегата: {report?.reportInfo?.unitSerialNumber}
            </span>
          )}
          <span className={g.code}>
            Дата создания: {getDateFromTimestamp(report?.reportInfo?.creationDate)}
          </span>
          {!!report?.reportInfo?.sendingDate && (
            <span className={g.code}>
              Дата отправки: {getDateFromTimestamp(report?.reportInfo?.sendingDate)}
            </span>
          )}
        </div>
        <div className={g.header}>
          <span className={g.headerTitle}>Коды маркировки</span>
          {reportType !== 'sets' && (
            <SearchBox
              className={g.search}
              placeholder="Поиск по коду маркировки"
              onClear={() => onSearchTermChanged('')}
              onSearch={(newValue) => onSearchTermChanged(newValue)}
            />
          )}
        </div>
        {isModal && initCorrVals ? (
          <CorrectionsModal
            isOpen={isModal}
            onDismiss={closeModal}
            initVals={initCorrVals}
            reportId={report?.reportInfo.id}
            getReport={getReportInEntModal}
          />
        ) : null}
        <div className={s.tableWrapper}>
          {!!report && report.markingCodes && (
            <Table
              headerItems={columns}
              maxHeight="calc(100vh - 22rem)"
              loading={loading}
              onSort={handleSort}
              sortState={sortState}
              onColumnReorder={handleColumnReorder}
              tableName={`report_${reportType}`}
              customHeaderClass={s.tableHeader}
              maxTableWidth='calc(100vw - 180px - 55px)'
              leftSpaceMenu={150}
            >
              {
                report.markingCodes.list?.map((item) => {
                  return (
                    <div key={item.id} className={s.tableItem}>
                      {columns.map((column) => {
                        let content;
                        let tooltipContent;

                        switch (column.columnId) {
                          case 'value':
                            content = item.value;
                            tooltipContent = content;
                            break;
                          case 'creationDate':
                            content = getDateFromTimestamp(item.creationDate);
                            tooltipContent = content;
                            break;
                          default:
                            content = '';
                            tooltipContent = '';
                        }

                        return (
                          <div
                            key={column.columnId}
                            className={clsx(s.tableCell)}
                            style={{
                              width: `calc((100vw - 180px - 3.75rem) * ${column.width})`,
                              minWidth: column.minWidth
                            }}
                          >
                            <Tooltip
                              content={tooltipContent}
                              delay={300}
                              directionalHint={DirectionalHint.topCenter}
                            >
                              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }}>
                                {content}
                              </div>
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              }
            </Table>
          )}
        </div>
        {totalPages > 1 && (
          <div>
            <Pagination
              pageCount={totalPages}
              onPageChange={onPageChanged}
              selectedPage={currentPage}
            />
          </div>
        )}
      </div>
    </div >
  );
};
