import { wideScreen } from '../../constants';

export const printingTableHeader = [
  {
    name: 'productName',
    columnId: 'productName',
    title: 'Продукт',
    width: '20',
    sortable: true,
  },
  {
    name: 'partNumber',
    columnId: 'partNumber',
    title: 'Задание',
    width: '20',
    sortable: true,
  },
  {
    name: 'creationDate',
    columnId: 'creationDate',
    title: 'Дата создания',
    width: '20',
    sortable: true,
  },
  {
    name: 'sendingDate',
    columnId: 'sendingDate',
    title: 'Дата отправки',
    width: '20',
    sortable: true,
  },
  {
    name: 'productCreationDate',
    columnId: 'productCreationDate',
    title: 'Дата производства',
    width: '20',
    sortable: true,
  },
  {
    name: 'numberMarkingCodes',
    columnId: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '20',
    sortable: true,
  },
  {
    name: 'productGtin',
    columnId: 'productGtin',
    title: 'Gtin',
    width: '20',
    sortable: true,
  },
  {
    name: 'status',
    minWidth: 120,
    columnId: 'status',
    title: 'Статус',
    width: '20',
    sortable: true,
    minWidth: 120
  },
  {
    name: 'suzId',
    columnId: 'suzId',
    title: 'Код отчёта',
    width: '20',
    sortable: true,
  },
  {
    name: 'serialNumber',
    columnId: 'serialNumber',
    title: 'Серия',
    width: '20',
    sortable: true,
  },
  {
    name: 'trashButton',
    columnId: 'trashButton',
    title: 'действия',
    width: '20',
    sortable: false,
  }
];

export const aggregationTableHeader = [
  {
    name: 'suzId',
    columnId: 'suzId',
    title: 'Код отчёта',
    width: '20',
    sortable: true,
  },
  {
    name: 'partNumber',
    columnId: 'partNumber',
    title: 'Задание',
    width: '20',
    sortable: true,
  },
  {
    name: 'unitSerialNumber',
    columnId: 'unitSerialNumber',
    title: 'Код агрегата',
    width: '20',
    sortable: true,
  },
  {
    name: 'creationDate',
    columnId: 'creationDate',
    title: 'Дата создания',
    width: '20',
    sortable: true,
  },
  {
    name: 'sendingDate',
    columnId: 'sendingDate',
    title: 'Дата отправки',
    width: '20',
    sortable: true,
  },
  {
    name: 'status',
    minWidth: 120,
    columnId: 'status',
    title: 'Статус',
    width: '20',
    sortable: true,
  },
  {
    name: 'productName',
    columnId: 'productName',
    title: 'Продукт',
    width: '20',
    sortable: true,
  },
  {
    name: 'productGtin',
    columnId: 'productGtin',
    title: 'Gtin',
    width: '20',
    sortable: true,
  },
  {
    name: 'numberMarkingCodes',
    columnId: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '20',
    sortable: true,
  },
  {
    name: 'serialNumber',
    columnId: 'serialNumber',
    title: 'Серия',
    width: '20',
    sortable: true,
  },
  {
    name: 'trashButton',
    columnId: 'trashButton',
    title: 'действия',
    width: '20',
    sortable: false,
  }
];

export const enteringTableHeader = [
  {
    name: 'suzId',
    columnId: 'suzId',
    title: 'Код отчёта',
    width: '20',
    sortable: true,
  },
  {
    name: 'partNumber',
    columnId: 'partNumber',
    title: 'Задание',
    width: '20',
    sortable: true,
  },
  {
    name: 'creationDate',
    columnId: 'creationDate',
    title: 'Дата создания',
    width: '20',
    sortable: true,
  },
  {
    name: 'sendingDate',
    columnId: 'sendingDate',
    title: 'Дата отправки',
    width: '20',
    sortable: true,
  },
  {
    name: 'productCreationDate',
    columnId: 'productCreationDate',
    title: 'Дата производства',
    width: '20',
    sortable: true,
  },
  {
    name: 'status',
    minWidth: 120,
    columnId: 'status',
    title: 'Статус',
    width: '20',
    sortable: true,
  },
  {
    name: 'productName',
    columnId: 'productName',
    title: 'Продукт',
    width: '20',
    sortable: true,
  },
  {
    name: 'productGtin',
    columnId: 'productGtin',
    title: 'Gtin',
    width: '20',
    sortable: true,
  },
  {
    name: 'vsd',
    columnId: 'vsd',
    title: 'ВСД',
    width: '20',
    sortable: true,
  },
  {
    name: 'numberMarkingCodes',
    columnId: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '20',
    sortable: true,
  },
  {
    name: 'serialNumber',
    columnId: 'serialNumber',
    title: 'Серия',
    width: '20',
    sortable: true,
  },
  {
    name: 'trashButton',
    columnId: 'trashButton',
    title: 'действия',
    width: '20',
    sortable: false,
  }
];

export const rejectTableHeader = [
  {
    name: 'suzId',
    columnId: 'suzId',
    title: 'Код отчёта',
    width: '20',
    sortable: true,
  },
  {
    name: 'partNumber',
    columnId: 'partNumber',
    title: 'Задание',
    width: '20',
    sortable: true,
  },
  {
    name: 'creationDate',
    columnId: 'creationDate',
    title: 'Дата создания',
    width: '20',
    sortable: true,
  },
  {
    name: 'sendingDate',
    columnId: 'sendingDate',
    title: 'Дата отправки',
    width: '20',
    sortable: true,
  },
  {
    name: 'status',
    minWidth: 120,
    columnId: 'status',
    title: 'Статус',
    width: '20',
    sortable: true,
  },
  {
    name: 'dropoutReason',
    columnId: 'dropoutReason',
    title: 'Причины отсева',
    width: '20',
    sortable: true,
  },
  {
    name: 'productName',
    columnId: 'productName',
    title: 'Продукт',
    width: '20',
    sortable: true,
  },
  {
    name: 'productGtin',
    columnId: 'productGtin',
    title: 'Gtin',
    width: '20',
    sortable: true,
  },
  {
    name: 'numberMarkingCodes',
    columnId: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '20',
    sortable: true,
  },
  {
    name: 'serialNumber',
    columnId: 'serialNumber',
    title: 'Серия',
    width: '20',
    sortable: true,
  },
  {
    name: 'trashButton',
    columnId: 'trashButton',
    title: 'действия',
    width: '20',
    sortable: false,
  }
];

export const setsTableHeader = [
  {
    name: 'suzId',
    columnId: 'suzId',
    title: 'Код отчёта',
    width: '20',
    sortable: true,
  },
  {
    name: 'partNumber',
    columnId: 'partNumber',
    title: 'Задание',
    width: '20',
    sortable: true,
  },
  {
    name: 'creationDate',
    columnId: 'creationDate',
    title: 'Дата создания',
    width: '20',
    sortable: true,
  },
  {
    name: 'sendingDate',
    columnId: 'sendingDate',
    title: 'Дата отправки',
    width: '20',
    sortable: true,
  },
  {
    name: 'productCreationDate',
    columnId: 'productCreationDate',
    title: 'Дата производства',
    width: '20',
    sortable: true,
  },
  {
    name: 'status',
    minWidth: 120,
    columnId: 'status',
    title: 'Статус',
    width: '20',
    sortable: true,
  },
  {
    name: 'productName',
    columnId: 'productName',
    title: 'Продукт',
    width: '20',
    sortable: true,
  },
  {
    name: 'productGtin',
    columnId: 'productGtin',
    title: 'Gtin',
    width: '20',
    sortable: true,
  },
  {
    name: 'numberMarkingCodes',
    columnId: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '20',
    sortable: true,
  },
  {
    name: 'serialNumber',
    columnId: 'serialNumber',
    title: 'Серия',
    width: '20',
    sortable: true,
  },
  {
    name: 'trashButton',
    columnId: 'trashButton',
    title: 'действия',
    width: '20',
    sortable: false,
  }
];

export const withdrInternConsupmTableHeader = [
  {
    name: 'suzId',
    columnId: 'suzId',
    title: 'Код отчёта',
    width: '20',
    sortable: true,
  },
  {
    name: 'partNumber',
    columnId: 'partNumber',
    title: 'Задание',
    width: '20',
    sortable: true,
  },
  {
    name: 'creationDate',
    columnId: 'creationDate',
    title: 'Дата создания',
    width: '20',
    sortable: true,
  },
  {
    name: 'sendingDate',
    columnId: 'sendingDate',
    title: 'Дата отправки',
    width: '20',
    sortable: true,
  },
  {
    name: 'actionDate',
    columnId: 'actionDate',
    title: 'Дата вывода',
    width: '20',
    sortable: true,
  },
  {
    name: 'status',
    minWidth: 120,
    columnId: 'status',
    title: 'Статус',
    width: '20',
    sortable: true,
  },
  {
    name: 'productName',
    columnId: 'productName',
    title: 'Продукт',
    width: '20',
    sortable: true,
  },
  {
    name: 'productGtin',
    columnId: 'productGtin',
    title: 'Gtin',
    width: '20',
    sortable: true,
  },
  {
    name: 'action',
    columnId: 'action',
    title: 'Причина выбытия',
    width: '20',
    sortable: true,
  },
  {
    name: 'numberMarkingCodes',
    columnId: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '20',
    sortable: true,
  },
  {
    name: 'trashButton',
    columnId: 'trashButton',
    title: 'действия',
    width: '20',
    sortable: false,
  }
];

export const writeOffTableHeader = [
  {
    name: 'suzId',
    columnId: 'suzId',
    title: 'Код отчёта',
    width: '20',
    sortable: true,
  },
  {
    name: 'creationDate',
    columnId: 'creationDate',
    title: 'Дата создания',
    width: '20',
    sortable: true,
  },
  {
    name: 'sendingDate',
    columnId: 'sendingDate',
    title: 'Дата отправки',
    width: '20',
    sortable: true,
  },
  {
    name: 'sourceDocDate',
    columnId: 'sourceDocDate',
    title: 'Дата вывода',
    width: '20',
    sortable: true,
  },
  {
    name: 'status',
    minWidth: 120,
    columnId: 'status',
    title: 'Статус',
    width: '20',
    sortable: true,
  },
  {
    name: 'productName',
    columnId: 'productName',
    title: 'Продукт',
    width: '20',
    sortable: true,
  },
  {
    name: 'productGtin',
    columnId: 'productGtin',
    title: 'Gtin',
    width: '20',
    sortable: true,
  },
  {
    name: 'dropoutReason',
    columnId: 'dropoutReason',
    title: 'Причина выбытия',
    width: '20',
    sortable: true,
  },
  {
    name: 'numberMarkingCodes',
    columnId: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '20',
    sortable: true,
  },
  {
    name: 'trashButton',
    columnId: 'trashButton',
    title: 'действия',
    width: '20',
    sortable: false,
  }
];

export const isChkboxDisbabled = (status) => status !== 'CREATED' && status !== 'REJECTED';
export const isChkboxDisbabledEnt = (status) => status !== 'CREATED' && status !== 'REJECTED';

export const mockSetsReport = {
  quantity: 3,
  list: [
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    },
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    },
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    }
  ]
};

export const getTypesInfo = () => ({
  printing: {
    title: 'нанесения',
    tableHeader: printingTableHeader
  },
  aggregation: {
    title: 'агрегации',
    tableHeader: aggregationTableHeader
  },
  entering: {
    title: 'ввода в оборот',
    tableHeader: enteringTableHeader
  },
  rejection: {
    title: 'отбраковка',
    tableHeader: rejectTableHeader
  },
  sets: {
    title: 'наборы',
    tableHeader: setsTableHeader
  },
  withrdIntrnlConsmpt: {
    title: 'вывод из оборота',
    tableHeader: withdrInternConsupmTableHeader
  },
  writeOff: {
    title: 'списания',
    tableHeader: writeOffTableHeader
  }
});
