import Input from 'components/Forms/Input';
import Expiration from './Experation';
import ProductGroupField from './ProductGroup';
import CompanyField from './CompanyField';
import OwnerField from './OwnersField';
import s from './AddSideProduct.module.scss';
import { PrimaryButton } from '@fluentui/react';
import { memo, useEffect } from 'react';


const AddForm = memo(({ isValid, companies, owners, sideProduct }) => {
  return (
    <>
      <Input name="Name" type="text" label="Название продукта" className={s.textInput} />
      <Input name="TnVed" type="text" label="Код ТНВЭД" className={s.textInput} />
      <Input name="Gtin" type="text" label="Код GTIN" className={s.textInput} />
      <Expiration />
      <ProductGroupField sideProduct={sideProduct} />
      <CompanyField companies={companies} />
      <OwnerField owners={owners} />
      <PrimaryButton type="submit" className={s.btn} disabled={!isValid} text="Сохранить" />
    </>
  );
})

export default AddForm;
