import { useState, useCallback, useMemo, useEffect } from 'react';

export const useDragAndDrop = (headerItems = [], onReorder, tableName = '') => {
  const [draggingColumnId, setDraggingColumnId] = useState(null);

  const handleDragStart = useCallback((start) => {
    // Извлекаем реальный ID колонки из составного ID (columnId-tableName)
    const dragId = start.draggableId.split('-')[0];
    setDraggingColumnId(dragId);
  }, []);

  const handleDragEnd = useCallback((result) => {
    setDraggingColumnId(null);

    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    // Создаем новый порядок колонок
    const newOrder = headerItems.map(item => item.columnId);
    const [removed] = newOrder.splice(sourceIndex, 1);
    newOrder.splice(destinationIndex, 0, removed);

    // Вызываем колбэк с новым порядком
    if (onReorder) {
      onReorder(sourceIndex, destinationIndex, newOrder);
    }
  }, [headerItems, onReorder]);

  return {
    draggingColumnId,
    handleDragStart,
    handleDragEnd
  };
};