import { Checkbox, DirectionalHint } from '@fluentui/react';
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import ProductsAPI from '../../../api/productsAPI';
import { Pagination } from 'components/Pagination/Pagination';
import { Table } from 'components/Table/Table';
import { TableAction } from 'components/TableAction/TableAction';
import {
  bgColor,
  lightBlue,
  moreInfoIcon,
  orderStatuses,
  pairedOrderStatuses,
  orderStatuses20
} from '../../../constants';
import { useCrumbsContext } from '../../../context/CrumbsContext';
import { cropText, getDateFromTimestamp, getStatusById, tableCellWidth } from '../../../functions';
import { useCheckboxFilter, usePaginationAndSearch } from '../../../hooks';
import { TableItemWithTooltip } from '../../Reports/components/TableItemWithTooltip';
import { useAlert } from '../../../hooks';
import { Alert } from 'components/Alert/Alert';
import s from './Orders.module.scss';
import g from 'assets/scss/Main.module.scss';
import clsx from 'clsx';
import infoIcon from '../../../assets/img/Info.svg';
import { ButtonMenu } from 'components/ButtonMenu/ButtonMenu';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { Tooltip, useTableFeatures, useTableSort } from '@fluentui/react-components';
import { LinkButton } from 'components/LinkButton/LinkButton';

const tableHeader = [
  {
    title: 'Продукт',
    width: 20,
    columnId: 'product.name',
    sortable: true
  },
  {
    title: 'Код заказа',
    width: 20,
    columnId: 'suzId',
    sortable: true
  },
  {
    title: 'Дата заказа',
    width: 20,
    columnId: 'creationDate',
    sortable: true
  },
  {
    title: 'Статус заказа',
    width: 20,
    columnId: 'status',
    sortable: true
  },
  {
    title: 'Кол-во КМ',
    width: 20,
    columnId: 'markingCodesQuantity',
    sortable: true
  },
  {
    title: 'Действия',
    width: 20,
    sortable: false,
    minWidth: 150

  }
];
const ordersTableHeader = [
  {
    title: 'Продукт',
    width: 20,
    columnId: 'product.name',
    sortable: true
  },
  {
    title: 'Код заказа',
    width: 20,
    columnId: 'suzId',
    sortable: true
  },
  {
    title: 'Дата заказа',
    width: 20,
    columnId: 'creationDate',
    sortable: true
  },
  {
    title: 'Статус заказа',
    width: 20,
    columnId: 'status',
    sortable: true
  },
  {
    title: 'Продукт',
    width: 20,
    columnId: 'product.name',
    sortable: true
  },
  {
    title: 'Количество КМ',
    width: 20,
    columnId: 'markingCodesQuantity',
    sortable: true
  },
  {
    title: 'Действия',
    width: 20,
    sortable: false,
  }
];

const searchBoxStyles = {
  root: { width: '28.75rem', background: bgColor, borderColor: lightBlue }
};

export const Orders = ({ status, statusParamName }) => {
  const { setCrumbs } = useCrumbsContext();
  const { params } = useLocation();
  const [orders, setOrders] = useState([]);
  const [orderInfo, setOrderInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const orderWidth = status ? tableCellWidth(ordersTableHeader) : tableCellWidth(tableHeader);

  useEffect(() => {
    if (params) {
      setQuery((prev) => {
        return { ...prev, productId: params.id };
      }, 'replace');
    } else {
      if (!status) {
        // setQuery({})
      }
    }
    return async () => {
      // await setQuery({})
    };
  }, []);

  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    quantityOnPage: NumberParam,
    productId: NumberParam,
    orderStatus: StringParam,
    searchValue: StringParam
  });

  const getOrders = async (query) => {
    setLoading(true);
    let res = await ProductsAPI.getOrders(query);
    const { list, quantity, info } = res;
    await setOrders(list);
    await setOrderInfo(info);
    await setTotalItems(quantity);
    await setLoading(false);
  };

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const errorInfoHandler = (msg) => {
    showAlert('Текст ошибки', msg);
  };

  // useEffect(() => {
  //   if (!params) {
  //     if (!status) {
  //       // getBack()
  //     }
  //   } else {
  //     if (params.status && !query.orderStatus) {
  //       setQuery((prev) => ({ ...prev, orderStatus: params.status }), 'replace');
  //     }
  //   }
  // }, [params]);

  useEffect(() => {
    setCrumbs(['/products', 'Заказы КМ']);
  }, [query]);

  useEffect(() => {
    if (status && !query.orderStatus) {
      setQuery((prev) => ({ ...prev, orderStatus: status }), 'replace');
    }
  }, [status]);

  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    pairedOrderStatuses,
    'orderStatus',
    setQuery,
    query
  );
  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getOrders,
      query,
      setQuery,
      status: status || params?.status,
      statusParamName: statusParamName || params?.statusParamName
    });

  const {
    sort: { getSortDirection, toggleColumnSort },
  } = useTableFeatures(
    {
      columns: status ? ordersTableHeader : tableHeader,
      items: orders,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: 'creationDate', sortDirection: 'ascending' },
      }),
    ]
  );

  const handleSort = (columnId) => {
    const event = { preventDefault: () => { } };
    const currentDirection = getSortDirection(columnId);

    toggleColumnSort(event, columnId);
    const direction = currentDirection === 'ascending' ? 1 : -1;

    const sortedOrders = [...orders].sort((a, b) => {
      // Для вложенных свойств
      if (columnId?.includes('.')) {
        const [parent, child] = columnId?.split('.');
        const valueA = a[parent]?.[child] ?? '';
        const valueB = b[parent]?.[child] ?? '';
        return String(valueA).localeCompare(String(valueB)) * direction;
      }

      // Для дат
      if (columnId === 'creationDate') {
        return (new Date(a[columnId] || 0) - new Date(b[columnId] || 0)) * direction;
      }

      // Для чисел
      if (typeof a[columnId] === 'number' && typeof b[columnId] === 'number') {
        return (a[columnId] - b[columnId]) * direction;
      }

      // Для строк
      const valueA = a[columnId] ?? '';
      const valueB = b[columnId] ?? '';
      return String(valueA).localeCompare(String(valueB)) * direction;
    });

    setOrders(sortedOrders);
  };

  const [columns, setColumns] = useState(() => {
    // Try to get saved state
    try {
      const savedState = sessionStorage.getItem('table_orders_state');

      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);

        if (columnOrder && Array.isArray(columnOrder)) {
          // Restore column order from saved state
          const columnMap = new Map((status ? ordersTableHeader : tableHeader).map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          // Add columns that aren't in the saved state
          (status ? ordersTableHeader : tableHeader).forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return status ? ordersTableHeader : tableHeader;
  });

  // Add columnId to table headers
  tableHeader.forEach((header, index) => {
    if (!header.columnId) {
      switch (index) {
        case 0: header.columnId = 'product.name'; break;
        case 1: header.columnId = 'suzId'; break;
        case 2: header.columnId = 'creationDate'; break;
        case 3: header.columnId = 'status'; break;
        case 4: header.columnId = 'markingCodesQuantity'; break;
        case 5: header.columnId = 'actions'; break;
      }
    }
  });

  ordersTableHeader.forEach((header, index) => {
    if (!header.columnId) {
      switch (index) {
        case 0: header.columnId = 'product.name'; break;
        case 1: header.columnId = 'suzId'; break;
        case 2: header.columnId = 'creationDate'; break;
        case 3: header.columnId = 'status'; break;
        case 4: header.columnId = 'product.name'; break;
        case 5: header.columnId = 'markingCodesQuantity'; break;
        case 6: header.columnId = 'actions'; break;
      }
    }
  });

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);

    // Update data according to new column order
    const reorderedOrders = orders.map(item => {
      const newItem = {};
      newColumns.forEach(column => {
        if (column.columnId.includes('.')) {
          const [parent, child] = column.columnId.split('.');
          if (!newItem[parent]) newItem[parent] = {};
          newItem[parent][child] = item[parent]?.[child];
        } else {
          newItem[column.columnId] = item[column.columnId];
        }
      });
      return { ...item, ...newItem };
    });
    setOrders(reorderedOrders);
  }, [columns, orders]);

  return (
    <div className={s.contentWrapper} style={{ height: '100%' }}>
      <div className={g.titleWrapper} >
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            className={g.search}
            value={query.searchValue}
            placeholder={
              status ? 'Поиск по коду заказа, продукту или gtin' : 'Поиск по коду заказа'
            }
            onClear={() => onSearchTermChanged('')}
            onSearch={(newValue) => onSearchTermChanged(newValue)}
          />
        </div>
        <div className={clsx(g.headerFilters)} >
          <ButtonMenu
            title={'Статус'}
            dataArray={pairedOrderStatuses}
            selectedFilters={selectedFilters}
            onChangeFilter={onChangeFilter}
            isCheckbox={true}
            optionSize={selectedFilters.size}
          />
        </div>
      </div>
      <div className={g.tableWrapper}>
        <Table
          headerItems={columns}
          loading={loading}
          onSort={handleSort}
          sortState={{ getSortDirection }}
          onColumnReorder={handleColumnReorder}
          tableName="orders"
          customHeaderClass={s.tableHeader}
        >
          {isAlertShowed && (
            <Alert
              title={alertTitle}
              text={alertText}
              onClose={() => {
                hideAlert();
              }}
            />
          )}
          {status
            ? orders?.map((item) => {
              return (
                <div key={item.id} className={s.tableItem}>
                  {columns.map((column, index) => {
                    let content;
                    let tooltipContent;

                    if (column.columnId === 'product.name' && index === 0) {
                      content = item.product?.name;
                      tooltipContent = content;
                    } else if (column.columnId === 'suzId') {
                      content = item.suzId;
                      tooltipContent = content;
                    } else if (column.columnId === 'creationDate') {
                      content = getDateFromTimestamp(item.creationDate);
                      tooltipContent = content;
                    } else if (column.columnId === 'status') {
                      content = (
                        <>
                          {item.errorMessage ? (
                            <span style={{ color: 'red' }}>Обработан с ошибкой</span>
                          ) : (
                            getStatusById(item.status, [
                              ...orderStatuses,
                              ...orderStatuses20,
                              ...pairedOrderStatuses
                            ])
                          )}
                          {item.errorMessage && (
                            <div style={{ display: 'flex', marginLeft: '5px', alignSelf: 'start' }}>
                              <TableAction
                                isButton
                                onClick={() => errorInfoHandler(item.errorMessage)}
                                tooltipText={'Текст ошибки'}
                                icon={infoIcon}
                              />
                            </div>
                          )}
                        </>
                      );
                      tooltipContent = item.errorMessage ? 'Обработан с ошибкой' : getStatusById(item.status, [
                        ...orderStatuses,
                        ...orderStatuses20,
                        ...pairedOrderStatuses
                      ]);
                    } else if (column.columnId === 'product.name' && index === 4) {
                      content = item.product?.name;
                      tooltipContent = content;
                    } else if (column.columnId === 'markingCodesQuantity') {
                      content = item.markingCodesQuantity;
                      tooltipContent = content;
                    } else if (column.columnId === 'actions') {
                      content = (
                        <TableAction
                          link={`/products/codesList`}
                          params={{
                            orderId: item.id,
                            status: 'NEW,UTILISATED,AGGREGATED,INROLLOUT,SCANED,INVALID',
                            statusParamName: 'status',
                            prevRoute: '/orders'
                          }}
                          text="Подробнее"
                          icon={infoIcon}
                        />
                      );
                      tooltipContent = "Подробнее";
                    }

                    return (
                      <div
                        key={column.columnId + index}
                        className={clsx(s.tableCell)}
                      >
                        <div>
                          <Tooltip
                            content={tooltipContent}
                            delay={300}
                            directionalHint={DirectionalHint.topCenter}
                          >
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {content}
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
            : orders?.map((item) => {
              return (
                <div key={item.id} className={s.tableItem}>
                  {columns.map((column, index) => {
                    let content;
                    let tooltipContent;

                    if (column.columnId === 'product.name') {
                      content = item.product?.name;
                      tooltipContent = content;
                    } else if (column.columnId === 'suzId') {
                      content = item.suzId;
                      tooltipContent = content;
                    } else if (column.columnId === 'creationDate') {
                      content = getDateFromTimestamp(item.creationDate);
                      tooltipContent = content;
                    } else if (column.columnId === 'status') {
                      content = (
                        <>
                          {item.errorMessage ? (
                            'Обработан с ошибкой'
                          ) : (
                            getStatusById(item.status, [
                              ...orderStatuses,
                              ...orderStatuses20,
                              ...pairedOrderStatuses
                            ])
                          )}
                          {item.errorMessage && (
                            <TableAction
                              isButton
                              onClick={() => errorInfoHandler(item.errorMessage)}
                              tooltipText={'Текст ошибки'}
                              icon={infoIcon}
                            />
                          )}
                        </>
                      );
                      tooltipContent = item.errorMessage ? 'Обработан с ошибкой' : getStatusById(item.status, [
                        ...orderStatuses,
                        ...orderStatuses20,
                        ...pairedOrderStatuses
                      ]);
                    } else if (column.columnId === 'markingCodesQuantity') {
                      content = item.markingCodesQuantity;
                      tooltipContent = content;
                    } else if (column.columnId === 'actions') {
                      content = (
                        <LinkButton
                          addStyles={clsx(s.linkButton)}
                          link={`/products/codesList`}
                          isButton={true}
                          text="Подробнее"
                          params={{
                            orderId: item.id,
                            status: 'NEW,UTILISATED,AGGREGATED,INROLLOUT,SCANED,INVALID',
                            id: params?.id,
                            productName: params?.productName,
                            templateId: params?.templateId,
                            productGtin: params?.productGtin,
                            orderCode: item.suzId,
                            orderStatus: item.status
                          }}
                        />
                      );

                      tooltipContent = "Подробнее";
                    }

                    return (
                      <div
                        key={column.columnId + index}
                        className={clsx(s.tableCell)}
                      >
                        <div>
                          {column.columnId !== "actions" ? (
                            <Tooltip
                              content={tooltipContent}
                              delay={300}
                              directionalHint={DirectionalHint.topCenter}
                            >
                              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {content}
                              </div>
                            </Tooltip>
                          ) : (
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {content}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </Table>
      </div>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};
