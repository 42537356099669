import { Input, makeStyles, useId } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import s from './Checkup.module.scss';
import { LinkButton } from 'components/LinkButton/LinkButton';
import CheckupAPI from 'api/CheckupAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import g from '../../assets/scss/Main.module.scss';
import { useDataFilter, usePaginationAndSearch } from 'hooks';
import { useDispatch } from 'react-redux';
import { setCheckupResult } from 'redux/Checkup/CheckupAction';
const useStyles = makeStyles({
  linkButton: {
    marginLeft: '0 !important',
    '& button': {
      width: '142px'
    },
  },
  input: {
    width: '70%'
  }
});

export default function Checkup() {
  const inputId = useId('input');
  const styles = useStyles();
  const [checkupValue, setCheckupValue] = useState('');
  const [error, setError] = useState(null);
  const history = useHistory();
  const [query, setQuery] = useDataFilter('checkup');
  const dispatch = useDispatch();

  const getCheckup = async (id) => {
    await setQuery((prev) => ({ ...prev, searchedCode: id }), "replace");
    const res = await CheckupAPI.getCheckup(id);
    console.log(res);
    dispatch(setCheckupResult(res));

    if (res?.searchedCode) {
      history.push({
        pathname: '/checkup/result',
        search: `?searchedCode=${id}`,
      });
    } else {
      setError('Код не найден');
    }
  };

  return (
    <div className={s.root}>
      <div className={s.inputBlock}>
        <Input
          onChange={(e) => setCheckupValue(e.target.value)}
          value={checkupValue}
          className={styles.input}
          id={inputId}
          placeholder="Введите код или часть кода для поиска и проверки"
        />
        {error && <div className={s.errorMessageText}>{error}</div>}
        <div className={s.buttonBlock}>
          <LinkButton
            primary={true}
            addStyles={styles.linkButton}
            isButton={true}
            text={'Найти'}
            onClick={() => getCheckup(checkupValue)}
          />
        </div>
      </div>
    </div>
  );
}
