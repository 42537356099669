import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'components/Table/Table';
import MinBalanceInput from './customInput';
import { Pagination } from 'components/Pagination/Pagination';
import { usePaginationAndSearch, useDataFilter } from 'hooks';
import clsx from 'clsx';
import g from 'assets/scss/Main.module.scss';
import s from './balance.module.scss';
import { useDispatch } from 'react-redux';
import { DirectionalHint, TooltipDelay, TooltipHost } from '@fluentui/react';

const tableHeader = [
  {
    title: 'Товарная группа',
    width: '0.3',
    columnId: 'productGroup',
    sortable: false
  },
  {
    title: 'Баланс, руб',
    width: '0.3',
    columnId: 'balance',
    sortable: false
  },
  {
    title: 'Уведомлять при остатке',
    width: '0.3',
    columnId: 'minBalance',
    sortable: false
  }
];

const BalanceDetails = ({ data, getBalance }) => {
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState(() => {
    try {
      const savedState = sessionStorage.getItem('table_balance_state');
      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);
        if (columnOrder && Array.isArray(columnOrder)) {
          const columnMap = new Map(tableHeader.map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          tableHeader.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return tableHeader;
  });
  const [query, setQuery] = useDataFilter('balance');
  const dispatch = useDispatch();

  const isBalanceTooLow = (currentBalance, borderLineBalance) => {
    return currentBalance <= borderLineBalance;
  };

  const getData = () => {
    setLoading(true);
    getBalance();
    setLoading(false);
    return data;
  };

  const { onPageChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getData, query, setQuery });

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);
  }, [columns]);

  return (
    <main className={s.mainContainer}>
      <header className={s.titleWrapper}>
        <h1 className={s.balanceTitle}>{data.companyName}</h1>
      </header>
      <div className={s.contentWrapper}>
        <div className={s.tableWrapper}>
          <Table
            headerItems={columns}
            maxHeight="auto"
            loading={loading}
            customStyles={s.additionalTableProps}
            customHeaderClass={s.tableHeader}
            onColumnReorder={handleColumnReorder}
            tableName="balance"
          >
            {data?.items?.map((row) => (
              <div
                key={row.productGroup}
                className={clsx(
                  s.tableItem,
                  isBalanceTooLow(row.currentBalance, row.minimumBalance) && s.lowBalance
                )}
              >
                {columns.map((column) => {
                  switch (column.columnId) {
                    case 'productGroup':
                      return (
                        <div
                          key={column.columnId}
                          className={s.tableCell}
                          style={{ width: `calc((100vw - 180px - 3.75rem) * ${column.width})` }}
                        >
                          <TooltipHost
                            content={row.productGroupName}
                            delay={TooltipDelay.medium}
                            directionalHint={DirectionalHint.topCenter}
                          >
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {row.productGroupName}
                            </div>
                          </TooltipHost>
                        </div>
                      );
                    case 'balance':
                      return (
                        <div
                          key={column.columnId}
                          className={s.tableCell}
                          style={{ width: `calc((100vw - 180px - 3.75rem) * ${column.width})` }}
                        >
                          <TooltipHost
                            content={row.currentBalance}
                            delay={TooltipDelay.medium}
                            directionalHint={DirectionalHint.topCenter}
                          >
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {row.currentBalance}
                            </div>
                          </TooltipHost>
                        </div>
                      );
                    case 'minBalance':
                      return (
                        <div
                          key={column.columnId}
                          className={clsx(s.tableCell, s.minBalanceColumn)}
                          style={{ width: `calc((100vw - 180px - 3.75rem) * ${column.width})` }}
                        >
                          <MinBalanceInput
                            companyId={data.companyId}
                            productGroup={row.productGroup}
                            initialVal={row.minimumBalance}
                            additonalStyles={
                              isBalanceTooLow(row.currentBalance, row.minimumBalance) && s.lowBalance
                            }
                          />
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            ))}
          </Table>
        </div>
      </div>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </main>
  );
};

export default BalanceDetails;
