import AggregatesAPI from 'api/AggregatesAPI';
import { updateMinBalanceState } from 'redux/utils';
export const SET_GLOBAL_ERROR_TEXT = 'SET_GLOBAL_ERROR_TEXT';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_INITIALIZED = 'SET_INITIALIZED';
export const SET_PREV_ROUTE = 'SET_PREV_ROUTE';
const AGGREGATION_INCLUDED = 'AGGREGATION_INCLUDED';
const SET_FILTERED_DATA = 'SET_FILTERED_DATA';
export const REQUEST_AGGREGATION_INCLUDED = 'REQUEST_AGGREGATION_INCLUDED';
const SET_ERROR = 'SET_ERROR';
const UPDATE_MINBALANCE = 'UPDATE_MINBALANCE';
const RESET_MINBALANCE = 'RESET_MINBALANCE';
const SET_LOWBALANCE = 'SET_LOWBALANCE';
export const SET_ALL_BALANCES = 'SET_ALL_BALANCES';
export const GET_ALL_BALANCES = 'GET_ALL_BALANCES';
export const SEND_MIN_BALANCES = 'SEND_MIN_BALANCES';
export const SET_LOADING = 'SET_LOADING';
export const GET_PRODUCT_BALANCE = 'GET_PRODUCT_BALANCE';
export const SET_PRODUCT_BALANCE = 'SET_PRODUCT_BALANCE';
export const CHECK_BALANCE = 'CHECK_BALANCE';
export const SET_IS_NEW_RELEASE = 'SET_IS_NEW_RELEASE';
export const SET_SHOULD_RETRY = 'SET_SHOULD_RETRY';
export const SET_REALEASE_VERSION = 'SET_REALEASE_VERSION';
export const RELEASE_INSTALLATION_STATUS = 'SET_REALEASE_VERSION';
export const RELEASE_UPDATE_ERROR = 'RELEASE_UPDATE_ERROR';
export const SET_INSTALLED_RELEASE_VERSION = 'SET_INSTALLED_RELEASE_VERSION';
export const SET_INSTALLED_RELEASE_VERSION_INFO = 'SET_INSTALLED_RELEASE_VERSION_INFO';
export const RUN_RELEASE_WS = 'RUN_RELEASE_WS';
export const SET_PRINT_REPORTS = 'SET_PRINT_REPORTS';
export const SET_IS_STILL_SENDING_BALANCE = 'SET_IS_STILL_SENDING_BALANCE';
export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';
export const SEND_GENERAL_SETTINGS = 'SEND_GENERAL_SETTINGS';
export const SET_GLOBAL_SUCC_ALERT = 'SET_GLOBAL_SUCC_ALERT';
export const SET_IS_DB = 'SET_IS_DB';
export const GET_IS_DB = 'GET_IS_DB';
export const SET_TOGGLE_MENU_DATA = 'SET_TOGGLE_MENU_DATA';

const initialState = {
  isLoading: false,
  isStillSendingBalance: false,
  globalErrorText: '',
  isFilters: false,
  initialized: false,
  prevRoute: '',
  filteredData: null,
  minBalanceUpdates: [],
  isLowBalance: false,
  chZnakBalances: [],
  productChZnakBalance: '',
  printReports: [],
  genSettings: {},
  globalSuccessAlertBody: { title: '', text: '' },
  isDb: undefined,
  toggleMenu: null
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_ERROR_TEXT:
      return {
        ...state,
        globalErrorText: action.errorText
      };
    case SET_FILTERS:
      return {
        ...state,
        isFilters: action.isFilters
      };
    case SET_INITIALIZED:
      return {
        ...state,
        initialized: action.isInitialized
      };
    case SET_PREV_ROUTE:
      return {
        ...state,
        prevRoute: action.prevRoute
      };
    case AGGREGATION_INCLUDED:
      return {
        ...state,
        aggregationIncluded: action.payload
      };
    case SET_FILTERED_DATA:
      return {
        ...state,
        filteredData: action.payload
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case UPDATE_MINBALANCE:
      return {
        ...state,
        minBalanceUpdates: [...state.minBalanceUpdates, action.payload]
      };
    case RESET_MINBALANCE:
      return {
        ...state,
        minBalanceUpdates: []
      };
    case SET_LOWBALANCE:
      return {
        ...state,
        isLowBalance: action.payload
      };
    case SET_ALL_BALANCES:
      return {
        ...state,
        chZnakBalances: [...action.payload]
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case SET_PRODUCT_BALANCE:
      return {
        ...state,
        productChZnakBalance: action.payload
      };
    case SET_TOGGLE_MENU_DATA:
      return { ...state, toggleMenu: action.payload }
    case SET_IS_NEW_RELEASE:
      return { ...state, isNewRelease: action.payload };
    case SET_SHOULD_RETRY:
      return { ...state, shouldRetry: action.payload };
    case SET_REALEASE_VERSION:
      return { ...state, releaseVersion: action.payload };
    case RELEASE_INSTALLATION_STATUS:
      return { ...state, releaseInstallStatus: action.payload };
    case RELEASE_UPDATE_ERROR:
      return { ...state, releaseUpdateError: action.payload };
    case SET_INSTALLED_RELEASE_VERSION:
      return { ...state, installedReleaseVersion: action.payload };
    case SET_INSTALLED_RELEASE_VERSION_INFO:
      return { ...state, installedRelVerInfo: action.payload };
    case SET_PRINT_REPORTS:
      return { ...state, printReports: action.payload };
    case SET_IS_STILL_SENDING_BALANCE:
      return { ...state, isStillSendingBalance: action.payload };
    case SET_GENERAL_SETTINGS:
      return { ...state, genSettings: { ...action.payload } };
    case SET_GLOBAL_SUCC_ALERT:
      return {
        ...state,
        globalSuccessAlertBody: { title: action.payload.title, text: action.payload.text }
      };
    case SET_IS_DB:
      return {
        ...state,
        isDb: action.payload
      };
    default:
      return state;
  }
};

export const setGlobalErrorTextAC = (errorText) => ({ type: SET_GLOBAL_ERROR_TEXT, errorText });
export const setFiltersAC = (isFilters) => {
  return { type: SET_FILTERS, isFilters };
};
export const setInitialized = (isInitialized) => ({ type: SET_INITIALIZED, isInitialized });
export const setPrevRoute = (prevRoute) => ({ type: SET_PREV_ROUTE, prevRoute });
export const setAggregationIncluded = (payload) => ({ type: AGGREGATION_INCLUDED, payload });
export const requestAggregationIncluded = () => ({ type: REQUEST_AGGREGATION_INCLUDED });
export const setErrorApp = (payload) => ({ type: SET_ERROR, payload });
export const setFilteredData = (payload) => ({ type: SET_FILTERED_DATA, payload });
export const setMinBalance = (payload) => ({ type: UPDATE_MINBALANCE, payload });
export const resetMinBalance = () => ({ type: RESET_MINBALANCE });
export const setLowBalance = (payload) => ({ type: SET_LOWBALANCE, payload });
export const setAllBalances = (payload) => ({ type: SET_ALL_BALANCES, payload });
export const getAllBalances = () => ({ type: GET_ALL_BALANCES });
export const sendMinBalances = (payload) => ({ type: SEND_MIN_BALANCES, payload });
export const setLoading = (payload) => ({ type: SET_LOADING, payload });
export const getProductChZBalance = (payload) => ({ type: GET_PRODUCT_BALANCE, payload });
export const setProductChZBalance = (payload) => ({ type: SET_PRODUCT_BALANCE, payload });
export const checkIfLowBalance = () => ({ type: CHECK_BALANCE });
export const runReleaseWS = () => ({ type: RUN_RELEASE_WS });
export const setPrintReports = (payload) => ({ type: SET_PRINT_REPORTS, payload });
export const setToggleMenuData = (payload) =>
  ({ type: SET_TOGGLE_MENU_DATA, payload })


export const setIsStillSendingBalance = (payload) => ({
  type: SET_IS_STILL_SENDING_BALANCE,
  payload
});
export const setGenSettings = (payload) => ({ type: SET_GENERAL_SETTINGS, payload });
export const sendGenSettings = (payload) => ({ type: SEND_GENERAL_SETTINGS, payload });
export const setGlobSuccAlert = (payload) => ({ type: SET_GLOBAL_SUCC_ALERT, payload });
export const setIsDb = (payload) => ({ type: SET_IS_DB, payload });
export const getIsDb = () => ({ type: GET_IS_DB });
