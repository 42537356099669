import { convertUTCToLocal, formatTwoPartDate } from "functions";

export const constants = {
  first: [
    'Продукт',
    'Компания',
    'Документация',
  ],
  second: [
    'Получен',
    'Задание',
    'Напечатан/Не напечатан',
    'Валидация',
    'Агрегация',
    'Списан',
    'Отчёт о нанесении',
    'Отчёт о вводе в оборот',
    'Выведен из оборота',
    'Отчет об отбраковке (выбытии)',
    'Корректировка сведений',
    'Архивирование',
  ],
}

export const descriptionData = {
  'Продукт': [
    { title: 'Название:', path: 'productInfo.name' },
    { title: 'GTIN:', path: 'productInfo.gtin' },
    { title: 'Категория:', path: 'productInfo.categoryName' },
    { title: 'Подкатегория:', path: 'productInfo.subCategoryName' },
    { title: 'Бренд:', path: 'productInfo.brandName' },
    { title: 'Срок годности:', path: 'productInfo.expirationDateValue', dateType: 'productInfo.expirationDateType' },
    { title: 'Товарная группа:', path: 'productInfo.productGroup' },
    { title: 'Набор:', path: 'productInfo.isSet' },
    { title: 'Кег:', path: 'productInfo.isKeg' },
    { title: 'Объем продукции в единице упаковки:', path: 'productInfo.volume' },
    { title: 'Имя владельца продукта:', path: 'productInfo.productOwner.name' },
    { title: 'ИНН:', path: 'productInfo.productOwner.inn' }
  ],
  'Компания': [
    { title: 'Название:', path: 'productInfo.company.name' },
    { title: 'ИНН:', path: 'productInfo.company.participantInn' },
    { title: 'ИНН владельца:', path: 'productInfo.company.ownerInn' },
    { title: 'ИНН производителя:', path: 'productInfo.company.producerInn' },
  ],
  'Документация': [
    {
      title: 'Разрешительная документация: ',
      path: 'productInfo.certificateDocuments',
      certificateType: 'productInfo.certificateDocuments.0.certificateType',
      certificateNumber: 'productInfo.certificateDocuments.0.certificateNumber',
      certificateDate: 'productInfo.certificateDocuments.0.certificateDate'
    },
    { title: 'Номер скважины:', path: 'productInfo.certificateDocuments.0.wellNumber' },
    {
      title: 'Документ соответствия: ',
      path: 'productInfo.complianceDocument',
      documentNumber: 'productInfo.complianceDocument.documentNumber',
      documentDate: 'productInfo.complianceDocument.documentDate',
      documentSource: 'productInfo.complianceDocument.documentSource'
    },
    {
      title: 'Лицензия на пользование недрами: ',
      path: 'productionTaskInfo.subsoilUseLicenses',
      licenseNumber: 'licenseNumber',
      dateOfIssue: 'dateOfIssue',
    },
    {
      title: 'Ветеринарный сопроводительный документ: ',
      path: 'productionTaskInfo.vsdNumber',
    },
    {
      title: 'Фактическое содержание этилового спирта: ',
      path: 'productionTaskInfo.beerAdditionalField',
      alcoholVolume: 'alcoholVolume',
      numberPrimaryDocument: 'numberPrimaryDocument',
      numberPrimaryDocumentDate: 'numberPrimaryDocumentDate',
    },
  ],
  'Получен': [{
    titlePar: 'Номер заказа: ',
    first: 'additionalInfo',
    type: ['700', '701']
  },
  {
    title: 'Дата и время создания заказа: ',
    path: 'externalInfo.emissionDateTimeUtc',
  },
  {
    titlePar: 'Дата и время сохранения кода маркировки: ',
    first: 'dateTimeUtcAction',
    type: ['200', '701'],
  },
  {
    titlePar: 'Производственная линия: ',
    first: 'relatedName',
    type: ['200', '701'],
  },
  ],

  'Задание': [{
    title: 'Дата и время создания: ',
    path: 'productionTaskInfo.creationDateTimeUtc',
    date: true
  },
  {
    title: 'Дата и время запуска в работу: ',
    path: 'productionTaskInfo.startDateTimeUtc',
    date: true
  },
  {
    title: 'Дата и время завершения работы: ',
    path: 'productionTaskInfo.stopDateTimeUtc',
    date: true
  },
  {
    title: 'Производственная линия: ',
    path: 'productionTaskInfo.productionLineName',
    date: false
  },
  {
    title: 'Номер задания: ',
    path: 'productionTaskInfo.number',
    date: false
  },
  {
    title: 'Дата производства: ',
    path: 'productionTaskInfo.productionDate',
    date: true
  },
  {
    title: 'Дата окончания срока годности продукции: ',
    path: 'productionTaskInfo.productExpirationDate',
    date: true
  },
  {
    title: 'Дата упаковки: ',
    path: 'productionTaskInfo.packingDate',
    date: true
  },
  {
    title: 'Статус: ',
    path: 'productionTaskInfo.status',
    date: false
  },
  {
    title: 'Серия: ',
    path: 'productionTaskInfo.serial',
    date: false
  },
  {
    titlePar: 'actionTypeDescription',
    first: 'dateTimeUtcAction',
    second: 'relatedName',
    third: 'additionalInfo',
    staples: true,
    type: ['900', '901'],
  },
  ],

  'Напечатан/Не напечатан': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      second: 'relatedName',
      third: 'additionalInfo',
      staples: true,
      type: ['200', '201', '202', '203'],
    },
  ],

  'Валидация': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      second: 'relatedName',
      third: 'additionalInfo',
      staples: true,
      type: ['100', '101', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111', '150', '151', '152'],
    },
  ],

  'Агрегация': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      type: ['300 ', '301', '302', '303', '304', '305', '306', '307', '308', '400', '406', '800', '801', '812', '813', '1000', '1006'],
    },
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      third: 'additionalInfo',
      staples: true,
      type: ['500', '506'],
    },
  ],

  'Списан': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      type: ['404', '808', '809', '1004'],
    },
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      third: 'additionalInfo',
      staples: true,
      type: ['504'],
    },
  ],

  'Отчёт о нанесении': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      date: true,
      type: ['401', '802', '803', '1001'],
    },
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      third: 'additionalInfo',
      staples: true,
      type: ['501'],
    },
  ],

  'Отчёт о вводе в оборот': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      type: ['403', '806', '807', '1003'],
    },
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      third: 'additionalInfo',
      staples: true,
      type: ['503'],
    },
  ],

  'Выведен из оборота': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      type: ['405', '810', '811', '1005'],
    },
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      third: 'additionalInfo',
      staples: true,
      type: ['505'],
    },
  ],
  'Отчет об отбраковке (выбытии)': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      type: ['402', '804', '805', '1002'],
    },
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      third: 'additionalInfo',
      staples: true,
      type: ['502'],
    },
  ],
  'Корректировка сведений': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      date: true,
      type: ['814', '815'],
    },
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      third: 'additionalInfo',
      staples: true,
      type: ['507'],
    }
  ],
  'Архивирование': [
    {
      titlePar: 'actionTypeDescription',
      first: 'dateTimeUtcAction',
      date: true,
      type: ['600', '602'],
    },
  ]
}

export const getNestedValue = (checkupResult, path) => {
  return path?.split('.').reduce((current, key) => current?.[key], checkupResult);
};

export const getNestedWithValueType = (obj, checkResultItems) => {
  console.log(obj, 'obj');
  console.log(checkResultItems, 'checkResultItems');

  //Проверяем есть ли UTC
  const hasDateWithUTC = /utc/i.test(obj?.first) ? true : false;
  const hasDate = /date/i.test(obj?.first) && !/utc/i.test(obj?.first) ? true : false;

  const actions = checkResultItems?.actions;
  console.log(actions, 'actions');

  return obj?.type?.map((actionType) => {
    const matchingActions = actions?.filter(action =>
      action.actionType?.toString() === actionType?.toString()
    );

    console.log(matchingActions, 'matchingActions');
    return matchingActions?.map((action, i) => {
      // Получаем значения из action

      if (!action[obj?.titlePar] || !action[obj?.first]) {
        return null
      }
      let firstValue = ''
      const title = action[obj?.titlePar] || '';

      if (hasDate) {
        firstValue = obj?.first ? `${convertUTCToLocal(action[obj?.first])?.mainPart} ${convertUTCToLocal(action[obj?.first])?.toolTipPart?.slice(0, 5)}` : '';
      } else if (hasDateWithUTC) {
        firstValue = obj?.first ? `${formatTwoPartDate(action[obj?.first])?.mainPart} ${formatTwoPartDate(action[obj?.first])?.toolTipPart?.slice(0, 5)}` : '';
      }

      const secondValue = obj?.second ? action[obj?.second] : '';
      const thirdValue = obj?.third ? action[obj?.third] : '';

      // Формируем дополнительную информацию в скобках
      let bracketsInfo = '';
      if (obj?.staples) {
        const values = [];
        if (secondValue) values.push(secondValue);
        if (thirdValue) values.push(thirdValue);
        if (values.length > 0) {
          bracketsInfo = ` (${values.join(', ')})`;
        }
      }

      // Собираем итоговую строку
      const resultString = `${title}: ${firstValue}${bracketsInfo}`;
      console.log(resultString, 'resultString');

      return (
        <p key={`${actionType}-${i}`}>
          {resultString}
        </p>
      );
    });
  })?.flat();
};