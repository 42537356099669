import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import s from './CheckupResult.module.scss';
import { convertUTCToLocal, formatTwoPartDate } from 'functions';
import clsx from 'clsx';
import g from '../../../assets/scss/Main.module.scss';
import { ReactComponent as ClockIcon } from '../../../assets/img/ClockIcon.svg';
import { ReactComponent as CalendarCompleteIcon } from '../../../assets/img/CalendarCompleteIcon.svg';
import CheckupAPI from 'api/CheckupAPI';
import { useDataFilter } from 'hooks';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { searchBoxStyles } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckupResult } from 'redux/Checkup/CheckupSelectors';
import { setCheckupResult } from 'redux/Checkup/CheckupAction';
import { makeStyles, MenuItem, MenuList } from '@fluentui/react-components';
import { lightBlue } from 'constants';
import { accent } from 'constants';
import { Link, Spinner } from '@fluentui/react';
import { constants, descriptionData, getNestedWithValueType, getNestedValue } from './constants';
import { debounce } from 'lodash';
import { useCrumbsContext } from 'context/CrumbsContext';

const useStyles = makeStyles({
  linkButton: {
    marginLeft: '0 !important',
    '& button': {
      width: '142px'
    },
  },
  input: {
    width: '70%'
  },
  selectedMenuItem: {
    backgroundColor: lightBlue,
    borderLeftColor: accent,
    borderBottom: `0.125rem solid ${accent}`,
    borderRadius: '0px',
    '[role="menuitem"]': {
      '--CheckCodeStyle': '#8FC8F4',
    }
  },

  menuCheckupBlock: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    '& div': {
      display: 'flex',
      width: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      gap: 0,
      whiteSpace: 'nowrap',
      padding: '0 2.3%',
    },
    '& span': {
      padding: '0',
    },
    '& div:hover': {
      borderRadius: '0px',
    }
  },

});

const CheckupResult = () => {
  const location = useLocation();
  const [query, setQuery] = useDataFilter('');
  const [searchValue, setSearchValue] = useState('')
  const [openToggle, setOpenToggle] = useState(false);
  const checkupResult = useSelector(getCheckupResult);
  const [checkedValues, setCheckedValues] = useState({ font: ['calibri'] });
  const [selectedItem, setSelectedItem] = useState({ first: 'Продукт', second: 'Получен' });
  const { setCrumbs } = useCrumbsContext();
  const [showSearchLoader, setShowSearchLoader] = useState(false);

  const styles = useStyles();
  const dispatch = useDispatch();

  const onSearchTermChanged = async (e) => {
    const newValue = e?.target?.value;
    setSearchValue(newValue);
  }

  useEffect(() => {
    setCrumbs(['/checkup', 'Результаты проверки']);
  }, [])

  const checkupResultFetch = useCallback(
    debounce(async (newValue) => {
      setShowSearchLoader(true);
      const res = await CheckupAPI?.getCheckup(newValue);
      dispatch(setCheckupResult(res || ''));
      setShowSearchLoader(false);
    }, 600),
    [dispatch]
  );

  const onSearchClearHandle = async () => {
    setSearchValue(undefined)
  }

  useEffect(() => {
    setQuery((prev) => ({ ...prev, searchedCode: searchValue || undefined }), 'replace');

  }, [searchValue])

  const onChangeChooseMenuElement = (
    e,
    { name, checkedItems }
  ) => {
    setCheckedValues((s) => ({ ...s, [name]: checkedItems }));
  };

  const handleClickFirst = (e, item) => {
    const obj = {
      first: item,
    }
    setSelectedItem((prev) => ({ ...prev, ...obj }));
  }
  const handleClickSecond = (e, item) => {
    const obj = {
      second: (item),
    }
    setSelectedItem((prev) => ({ ...prev, ...obj }));
  }

  useEffect(() => {
    setSearchValue(query?.searchedCode);
  }, []);

  useEffect(() => {
    checkupResultFetch(searchValue);
  }, [searchValue]);

  const getAvailableFirstTabs = useCallback(() => {
    return constants?.first?.filter(tab => {
      const tabData = descriptionData[tab];
      return tabData.some(item => {
        switch (item.path) {
          case 'productInfo.certificateDocuments':
            return checkupResult?.productInfo?.certificateDocuments?.length > 0;
          case 'productInfo.complianceDocument':
            return getNestedValue(checkupResult, item.documentSource) &&
              getNestedValue(checkupResult, item.documentNumber) &&
              getNestedValue(checkupResult, item.documentDate);
          default:
            return getNestedValue(checkupResult, item.path);
        }
      });
    });
  }, [checkupResult]);

  const getAvailableSecondTabs = useCallback((currentItem) => {
    return constants?.second?.filter(tab => {
      const tabData = descriptionData[tab];
      return tabData.some(item => {
        if (Array.isArray(item.type)) {
          return currentItem?.actions?.some(action =>
            item.type.includes(action.actionType.toString())
          );
        }
        return getNestedValue(currentItem, item.path) ||
          getNestedValue(checkupResult, item.path);
      });
    });
  }, [checkupResult]);

  useEffect(() => {
    const availableFirstTabs = getAvailableFirstTabs();
    if (checkupResult?.items?.length) {
      const firstItem = checkupResult.items[0];
      const availableSecondTabs = getAvailableSecondTabs(firstItem);
      setSelectedItem(prev => ({
        first: availableFirstTabs.includes(prev.first) ? prev.first : availableFirstTabs[0],
        second: availableSecondTabs.includes(prev.second) ? prev.second : availableSecondTabs[0]
      }));
    } else {
      const availableSecondTabs = getAvailableSecondTabs(checkupResult);
      setSelectedItem(prev => ({
        first: availableFirstTabs.includes(prev.first) ? prev.first : availableFirstTabs[0],
        second: availableSecondTabs.includes(prev.second) ? prev.second : availableSecondTabs[0]
      }));
    }
  }, [getAvailableFirstTabs, getAvailableSecondTabs, checkupResult]);

  return (
    <div className={s.root}>
      <div className={clsx(s.titleWrapper)} style={!checkupResult || !checkupResult?.items?.length ? { paddingBottom: '1.4rem' } : undefined}>
        <div className={g.searchBlockWrapper} style={{ position: 'relative' }}>
          <SearchBlock
            className={g.search}
            iconProps={''}
            styles={searchBoxStyles}
            value={searchValue}
            placeholder='Поиск по названию или Gtin'
            onClear={() => onSearchClearHandle()}
            onSearch={(e) => onSearchTermChanged(e)}
          />
          {!checkupResult ? (
            <div className={s.errorMessageText}>код не найден</div>
          ) : !checkupResult?.items?.length ? (
            <div className={s.errorMessageText}>код не полный</div>
          ) : null}
        </div>
        {showSearchLoader && <Spinner style={{ height: '100%', width: '30px' }} className={s.searchLoader} />}
      </div>

      {
        checkupResult?.items?.length || !checkupResult ? checkupResult?.items?.map((checkResultItems, i) => {
          console.log(checkResultItems, 'checkResultItems');
          return (
            <div key={i}>
              {/* Результаты проверки */}
              <div className={clsx(s.resultBlock, openToggle && (s.resultBlockOpen))}>
                <div className={s.resultBlockItem}>
                  <div className={s.resultBlockItem__status}>
                    <p>{!checkResultItems?.lastStatus ? 'Неизвестно' : checkResultItems?.lastStatus}</p>
                  </div>
                  <div className={s.resultBlockItem__date}>
                    <div className={s.resultBlockItem__date_item}>
                      <CalendarCompleteIcon />
                      <p>{formatTwoPartDate(checkResultItems?.lastStatusDateTimeUtc)?.mainPart}</p>
                    </div>
                    <div className={s.resultBlockItem__date_item}>
                      <ClockIcon />
                      <p>{formatTwoPartDate(checkResultItems?.lastStatusDateTimeUtc)?.toolTipPart?.slice(0, -3)}</p>
                    </div>
                  </div>
                </div>
                <div className={s.resultBlockItem}>
                  <div className={s.resultBlockItem__gtin}>
                    <p>Код маркировки: {checkResultItems?.markingCodeValueInfo?.replacedSpecialCharsFullCode}</p>
                  </div>
                  <div className={clsx(s.resultLinkButton)}>
                    <Link
                      appearance={'subtle'}
                      onClick={() => {
                        setOpenToggle((prev) => !prev);
                      }}
                      icon={null}
                    >Подробнее</Link>
                  </div>
                </div>
                {/* Подробнее */}
                {openToggle && <div className={s.openToggleBlock}>
                  <div className={s.tablist}>
                    <MenuList className={styles.menuCheckupBlock} checkedValues={checkedValues} onCheckedValueChange={onChangeChooseMenuElement}>
                      {getAvailableFirstTabs().map((firstMenuElement, i) => {
                        return (
                          <MenuItem
                            key={i}
                            icon={null}
                            hasIcons={false}
                            onClick={(e) => handleClickFirst(e, firstMenuElement)}
                            value={i}
                            className={selectedItem?.first === firstMenuElement ? styles.selectedMenuItem : ''}
                          >
                            <span style={selectedItem?.first === firstMenuElement ? { fontWeight: 'bold' } : {}}>
                              {firstMenuElement}
                            </span>
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                    <div className={s.descriptionBlock}>
                      {descriptionData?.[selectedItem?.first]?.map((constantsFirstDataElement, i) => {

                        switch (constantsFirstDataElement?.path) {
                          case 'productInfo.certificateDocuments':
                            return (
                              checkupResult?.productInfo?.certificateDocuments?.map((item, i) => {
                                return checkupResult?.productInfo?.certificateDocuments && (
                                  <p key={i}>
                                    {constantsFirstDataElement?.title}
                                    {item?.certificateType}
                                    {' №'}
                                    {item?.certificateNumber}
                                    {' от '}
                                    {item?.certificateDate}
                                  </p>
                                )
                              })
                            )
                          case 'productInfo.complianceDocument':
                            return getNestedValue(checkupResult, constantsFirstDataElement?.documentSource) && getNestedValue(checkupResult, constantsFirstDataElement?.documentNumber) && getNestedValue(checkupResult, constantsFirstDataElement?.documentDate) && (
                              <p key={i}>
                                {constantsFirstDataElement?.title}
                                {getNestedValue(checkupResult, constantsFirstDataElement?.documentSource)}
                                {' №'}
                                {getNestedValue(checkupResult, constantsFirstDataElement?.documentNumber)}
                                {' от '}
                                {getNestedValue(checkupResult, constantsFirstDataElement?.documentDate)}
                              </p>
                            )
                          case 'productionTaskInfo.subsoilUseLicenses':
                            return (
                              checkResultItems?.productionTaskInfo?.subsoilUseLicenses?.map((item, i) => {
                                return checkResultItems?.productionTaskInfo?.subsoilUseLicenses && (
                                  <p key={i}>
                                    {constantsFirstDataElement?.title}
                                    {' №'}
                                    {item?.licenseNumber}
                                    {' от '}
                                    {item?.dateOfIssue}
                                  </p>
                                )
                              })
                            )
                          case 'productionTaskInfo.subsoilUseLicenses':
                            checkResultItems?.productionTaskInfo?.subsoilUseLicenses?.map((item, i) => {
                              return checkResultItems?.productionTaskInfo?.subsoilUseLicenses && (
                                <p key={i}>
                                  {constantsFirstDataElement?.title}
                                  {' №'}
                                  {item?.licenseNumber}
                                </p>
                              )
                            })
                          case 'productionTaskInfo.vsdNumber':
                            return checkResultItems?.productionTaskInfo?.vsdNumber && (
                              <p key={i}>
                                {constantsFirstDataElement?.title}
                                {' №'}
                                {checkResultItems?.productionTaskInfo?.vsdNumber}
                              </p>
                            )
                          case 'productionTaskInfo.beerAdditionalField':
                            return checkResultItems?.productionTaskInfo?.beerAdditionalField && (
                              <p key={i}>
                                {constantsFirstDataElement?.title}
                                {' №'}
                                {checkResultItems?.productionTaskInfo?.beerAdditionalField?.alcoholVolume}
                                {', '}
                                {checkResultItems?.productionTaskInfo?.beerAdditionalField?.numberPrimaryDocument}
                                {' от '}
                                {checkResultItems?.productionTaskInfo?.beerAdditionalField?.numberPrimaryDocumentDate}
                              </p>
                            )
                          default:
                            return (
                              getNestedValue(checkupResult, constantsFirstDataElement?.path) &&
                              <p key={i}>{constantsFirstDataElement?.title} {getNestedValue(checkupResult, constantsFirstDataElement?.path)} {getNestedValue(checkupResult, constantsFirstDataElement?.dateType)}</p>
                            )
                        }
                      })}
                    </div >
                  </div>
                  <div className={s.historyStatusTitle}>
                    <p>
                      История статусов
                    </p>
                  </div>
                  <div className={s.tablist}>
                    <MenuList className={styles.menuCheckupBlock} checkedValues={checkedValues} onCheckedValueChange={onChangeChooseMenuElement}>
                      {getAvailableSecondTabs(checkResultItems).map((item, i) => {
                        return (
                          <MenuItem
                            key={i}
                            icon={null}
                            hasIcons={false}
                            onClick={(e) => handleClickSecond(e, item)}
                            value={i}
                            className={selectedItem?.second === item ? styles.selectedMenuItem : ''}
                          >
                            <span style={selectedItem?.second === item ? { fontWeight: 'bold' } : {}}>
                              {item}
                            </span>
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                    <div className={s.descriptionStoryBlock}>
                      {descriptionData?.[selectedItem?.second]?.map((constantsSecondDataElement, i) => {

                        switch (true) {
                          //сортирует по constantsSecondDataElement.type 
                          case Array.isArray(constantsSecondDataElement?.type):
                            return getNestedWithValueType(constantsSecondDataElement, checkResultItems)
                          default:
                            //сортирует по constantsSecondDataElement.path 
                            const hasDateWithUTC = constantsSecondDataElement?.path ? /utc/i.test(constantsSecondDataElement.path) : false;
                            switch (true) {
                              case hasDateWithUTC:
                                if (getNestedValue(checkResultItems, constantsSecondDataElement?.path)) {
                                  return getNestedValue(checkResultItems, constantsSecondDataElement?.path) && (
                                    <p key={i}>
                                      {constantsSecondDataElement?.title}
                                      {`${formatTwoPartDate(getNestedValue(checkResultItems, constantsSecondDataElement?.path))?.mainPart} ${formatTwoPartDate(getNestedValue(checkResultItems, constantsSecondDataElement?.path))?.toolTipPart?.slice(0, 5)}`}
                                    </p>
                                  )
                                } else {
                                  return getNestedValue(checkupResult, constantsSecondDataElement?.path) && (
                                    <p key={i}>
                                      {constantsSecondDataElement?.title}
                                      {`${formatTwoPartDate(getNestedValue(checkupResult, constantsSecondDataElement?.path))?.mainPart} ${formatTwoPartDate(getNestedValue(checkupResult, constantsSecondDataElement?.path))?.toolTipPart?.slice(0, 5)}`}
                                    </p>
                                  )
                                }
                              default:
                                if (getNestedValue(checkResultItems, constantsSecondDataElement?.path)) {
                                  return getNestedValue(checkResultItems, constantsSecondDataElement?.path) && (
                                    <p key={i}>
                                      {constantsSecondDataElement?.title}
                                      {getNestedValue(checkResultItems, constantsSecondDataElement?.path)}
                                    </p>
                                  )
                                } else {
                                  return getNestedValue(checkupResult, constantsSecondDataElement?.path) && (
                                    <p key={i}>
                                      {constantsSecondDataElement?.title}
                                      {getNestedValue(checkupResult, constantsSecondDataElement?.path)}
                                    </p>
                                  )
                                }
                            }
                        }
                      })}
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          )
        })
          :
          <div>
            {/* Результаты проверки если код не полный */}
            <div className={clsx(s.resultBlock, openToggle && (s.resultBlockOpen))}>
              <div className={s.resultBlockItem}>
                <div className={s.resultBlockItem__status}>
                  <p>{!checkupResult?.lastStatus ? 'Неизвестно' : checkupResult?.lastStatus}</p>
                </div>
                <div className={s.resultBlockItem__date}>
                  <div className={s.resultBlockItem__date_item}>
                    <CalendarCompleteIcon />
                    <p>{formatTwoPartDate(checkupResult?.lastStatusDateTimeUtc)?.mainPart}</p>
                  </div>
                  <div className={s.resultBlockItem__date_item}>
                    <ClockIcon />
                    <p>{formatTwoPartDate(checkupResult?.lastStatusDateTimeUtc)?.toolTipPart?.slice(0, -3)}</p>
                  </div>
                </div>
              </div>
              <div className={s.resultBlockItem}>
                <div className={s.resultBlockItem__gtin}>
                  <p>Код маркировки: {checkupResult?.markingCodeValueInfo?.replacedSpecialCharsFullCode}</p>
                </div>
                <div className={clsx(s.resultLinkButton)}>
                  <Link
                    appearance={'subtle'}
                    onClick={() => {
                      setOpenToggle((prev) => !prev);
                    }}
                    icon={null}
                  >Подробнее</Link>
                </div>
              </div>
              {/* Подробнее */}
              {openToggle && <div className={s.openToggleBlock}>
                <div className={s.tablist}>
                  <MenuList className={styles.menuCheckupBlock} checkedValues={checkedValues} onCheckedValueChange={onChangeChooseMenuElement}>
                    {getAvailableFirstTabs().map((firstMenuElement, i) => {
                      return (
                        <MenuItem
                          key={i}
                          icon={null}
                          hasIcons={false}
                          onClick={(e) => handleClickFirst(e, firstMenuElement)}
                          value={i}
                          className={selectedItem?.first === firstMenuElement ? styles.selectedMenuItem : ''}
                        >
                          <span style={selectedItem?.first === firstMenuElement ? { fontWeight: 'bold' } : {}}>
                            {firstMenuElement}
                          </span>
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                  <div className={s.descriptionBlock}>
                    {descriptionData?.[selectedItem?.first]?.map((constantsFirstDataElement, i) => {

                      switch (constantsFirstDataElement?.path) {
                        case 'productInfo.certificateDocuments':
                          return (
                            checkupResult?.productInfo?.certificateDocuments?.map((item, i) => {
                              return (
                                <p key={i}>
                                  {constantsFirstDataElement?.title}
                                  {item?.certificateType}
                                  {' №'}
                                  {item?.certificateNumber}
                                  {' от '}
                                  {item?.certificateDate}
                                </p>
                              )
                            })
                          )
                        case 'productInfo.complianceDocument':
                          return getNestedValue(checkupResult, constantsFirstDataElement?.documentSource) && getNestedValue(checkupResult, constantsFirstDataElement?.documentNumber) && getNestedValue(checkupResult, constantsFirstDataElement?.documentDate) && (
                            <p key={i}>
                              {constantsFirstDataElement?.title}
                              {getNestedValue(checkupResult, constantsFirstDataElement?.documentSource)}
                              {' №'}
                              {getNestedValue(checkupResult, constantsFirstDataElement?.documentNumber)}
                              {' от '}
                              {getNestedValue(checkupResult, constantsFirstDataElement?.documentDate)}
                            </p>
                          )
                        default:
                          return (
                            getNestedValue(checkupResult, constantsFirstDataElement?.path) &&
                            <p key={i}>{constantsFirstDataElement?.title} {getNestedValue(checkupResult, constantsFirstDataElement?.path)} {getNestedValue(checkupResult, constantsFirstDataElement?.dateType)}</p>
                          )
                      }
                    })}
                  </div >
                </div>
                <div className={s.historyStatusTitle}>
                  <p>
                    История статусов
                  </p>
                </div>
                <div className={s.tablist}>
                  <MenuList className={styles.menuCheckupBlock} checkedValues={checkedValues} onCheckedValueChange={onChangeChooseMenuElement}>
                    {getAvailableSecondTabs(checkupResult).map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          icon={null}
                          hasIcons={false}
                          onClick={(e) => handleClickSecond(e, item)}
                          value={i}
                          className={selectedItem?.second === item ? styles.selectedMenuItem : ''}
                        >
                          <span style={selectedItem?.second === item ? { fontWeight: 'bold' } : {}}>
                            {item}
                          </span>
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                  <div className={s.descriptionStoryBlock}>
                    {descriptionData?.[selectedItem?.second]?.map((constantsSecondDataElement, i) => {

                      switch (true) {
                        //сортирует по constantsSecondDataElement.type 
                        case Array.isArray(constantsSecondDataElement?.type):
                          return getNestedWithValueType(constantsSecondDataElement, checkupResult)
                        default:
                          //сортирует по constantsSecondDataElement.path 
                          const hasDateWithUTC = constantsSecondDataElement?.path ? /utc/i.test(constantsSecondDataElement.path) : false;
                          switch (true) {
                            case hasDateWithUTC:
                              if (getNestedValue(checkupResult, constantsSecondDataElement?.path)) {
                                return getNestedValue(checkupResult, constantsSecondDataElement?.path) && (
                                  <p key={i}>
                                    {constantsSecondDataElement?.title}
                                    {`${formatTwoPartDate(getNestedValue(checkupResult, constantsSecondDataElement?.path))?.mainPart} ${formatTwoPartDate(getNestedValue(checkupResult, constantsSecondDataElement?.path))?.toolTipPart?.slice(0, 5)}`}
                                  </p>
                                )
                              } else {
                                return getNestedValue(checkupResult, constantsSecondDataElement?.path) && (
                                  <p key={i}>
                                    {constantsSecondDataElement?.title}
                                    {`${formatTwoPartDate(getNestedValue(checkupResult, constantsSecondDataElement?.path))?.mainPart} ${formatTwoPartDate(getNestedValue(checkupResult, constantsSecondDataElement?.path))?.toolTipPart?.slice(0, 5)}`}
                                  </p>
                                )
                              }
                            default:
                              if (getNestedValue(checkupResult, constantsSecondDataElement?.path)) {
                                return getNestedValue(checkupResult, constantsSecondDataElement?.path) && (
                                  <p key={i}>
                                    {constantsSecondDataElement?.title}
                                    {getNestedValue(checkupResult, constantsSecondDataElement?.path)}
                                  </p>
                                )
                              } else {
                                return getNestedValue(checkupResult, constantsSecondDataElement?.path) && (
                                  <p key={i}>
                                    {constantsSecondDataElement?.title}
                                    {getNestedValue(checkupResult, constantsSecondDataElement?.path)}
                                  </p>
                                )
                              }
                          }
                      }
                    })}
                  </div>
                </div>
              </div>}
            </div>
          </div>
      }
    </div >
  )
}

export default CheckupResult;
