import * as Yup from 'yup';

// export const productGroupRefObj = [
//   { key: 'lp', text: 'Предметы одежды, бельё постельное, столовое, туалетное и кухонное' },
//   { key: 'shoes', text: 'Обувные товары' },
//   { key: 'tobacco', text: 'Табачная продукция' },
//   { key: 'perfumery', text: 'Духи и туалетная вода' },
//   { key: 'tires', text: 'Шины и покрышки пневматические резиновые новые' },
//   { key: 'electronics', text: 'Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки' },
//   { key: 'pharma', text: 'Лекарственные препараты для медицинского применения' },
//   { key: 'milk', text: 'Молочная продукция' },
//   { key: 'bicycle', text: 'Велосипеды и велосипедные рамы' },
//   { key: 'wheelchairs', text: 'Медицинские изделия' },
//   { key: 'otp', text: 'Альтернативная табачная продукция' },
//   { key: 'water', text: 'Упакованная вода' },
//   { key: 'furs', text: 'Товары из натурального меха' },
//   { key: 'beer', text: 'Пиво, напитки, изготавливаемые на основе пива, слабоалкогольные напитки' },
//   { key: 'ncp', text: 'Никотиносодержащая продукция' },
//   { key: 'bio', text: 'Биологически активные добавки к пище' },
//   { key: 'antiseptic', text: 'Антисептики и дезинфицирующие средства' },
//   { key: 'seafood', text: 'Морепродукты' },
//   { key: 'nabeer', text: 'Безалкогольное пиво' },
//   { key: 'softdrinks', text: 'Соковая продукция и безалкогольные напитки' },
//   { key: 'petfood', text: 'Корма для животных' }
// ];

export const productGroupRefObj = {
  productGroups: [
    {
      "name": 'Соковая продукция и безалкогольные напитки',
      "productGroup": 23,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Антисептики и дезинфицирующие средства",
      "productGroup": 19,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Консервированная продукция",
      "productGroup": 32,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Игры и игрушки для детей",
      "productGroup": 27,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Ветеринарные препараты",
      "productGroup": 26,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Товары из натурального меха",
      "productGroup": 14,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Упакованная вода",
      "productGroup": 13,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Безалкогольное пиво",
      "productGroup": 22,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Никотиносодержащая продукция",
      "productGroup": 16,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Велосипеды и велосипедные рамы",
      "productGroup": 9,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Титановая металлопродукция",
      "productGroup": 31,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Молочная продукция",
      "productGroup": 8,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Табачная продукция",
      "productGroup": 3,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Радиоэлектронная продукция",
      "productGroup": 28,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Корма для животных",
      "productGroup": 20,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Парфюмерные и косметические средства и бытовая химия",
      "productGroup": 35,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Обувные товары",
      "productGroup": 2,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Пиво, напитки, изготавливаемые на основе пива, слабоалкогольные напитки",
      "productGroup": 15,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Морепродукты",
      "productGroup": 21,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Биологически активные добавки к пище",
      "productGroup": 17,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Медицинские изделия",
      "productGroup": 10,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Растительные масла",
      "productGroup": 33,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Альтернативная табачная продукция",
      "productGroup": 12,
      "canUseShortTemplateMarkingCode": true
    },
    {
      "name": "Шины и покрышки пневматические резиновые новые",
      "productGroup": 5,
      "canUseShortTemplateMarkingCode": false
    },
    {
      "name": "Оптоволокно и оптоволоконная продукция",
      "productGroup": 34,
      "canUseShortTemplateMarkingCode": true
    }
  ]
}

export const expirationDateTypeRefObj = [
  { key: 'Hour', text: 'Час' },
  { key: 'Day', text: 'День' },
  { key: 'Week', text: 'Неделя' },
  { key: 'Month', text: 'Месяц' },
  { key: 'Year', text: 'Год' }
];

export const NEW_SIDEPRODUCT_VALIDATION_SCHEMA = Yup.object().shape({
  Name: Yup.string()
    .required('Поле обязательно')
    .test(
      'length>=4',
      'Название продукта не должно быть короче четырех символов',
      (value) => value?.toString().length > 3
    )
    .typeError('Поле обязательно'),
  TnVed: Yup.string()
    .required('Поле обязательно')
    .test(
      'numbersOnly',
      'Формат ТН ВЭД должен быть 4-10 цифр',
      (value) =>
        !/[^0-9]/.test(value) && value?.toString().length > 3 && value?.toString().length < 11
    )
    .typeError('Поле обязательно'),
  Gtin: Yup.string()
    .required('Поле обязательно')
    .test(
      'numbersOnly',
      'Формат GTIN должен быть 14 цифр',
      (value) => !/[^0-9]/.test(value) && value?.toString().length === 14
    )
    .typeError('Поле обязательно'),
  expirationDate: Yup.number()
    .test(
      'min0',
      'Данное поле принимает только числовые значения больше 0',
      (value) => Number(value) > 0
    )
    .test(
      'numbersOnly',
      'Данное поле принимает только числовые значения больше 0',
      (value) => typeof value === 'number' && !/[^0-9]/.test(value)
    )
    .required('Поле обязательно')
    .typeError('Данное поле принимает только числовые значения больше 0')
});

export const initialValues = {
  Name: '',
  TnVed: '',
  Gtin: '',
  productGroup: productGroupRefObj.productGroups[0].key,
  expirationDateType: expirationDateTypeRefObj[0].key,
  expirationDate: null,
  companyId: '',
  productOwnerId: '',
  useShortTemplateId: false
};
