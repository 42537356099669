import { CODES_CONTROL_ACCESS, FULL_ACCESS } from '../../constants';
import {
  BoxRegular,
  BoxMultipleRegular,
  TextboxRegular,
  TaskListSquareLtrRegular,
  TimelineRegular,
  PersonRegular,
  SettingsRegular,
  BarcodeScannerRegular
} from "@fluentui/react-icons";
import { ReactComponent as CheckCode } from '../../assets/img/CheckCode.svg';

export const navLinkGroups = [
  {
    links: [
      {
        name: 'Продукты и заказы',
        key: '/products',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS],
        icon: <BoxRegular style={{ height: '100%', width: '100%' }} />
      },
      {
        name: 'Агрегаты',
        key: '/aggregates',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS],
        icon: <BoxMultipleRegular style={{ height: '100%', width: '100%' }} />
      },
      {
        name: 'Отчеты',
        key: '/reports',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS],
        icon: <TextboxRegular style={{ height: '100%', width: '100%' }} />,
        links: [
          {
            name: 'Нанесение',
            key: '/reports/printing'
          },
          {
            name: 'Агрегация',
            key: '/reports/aggregation'
          },
          {
            name: 'Ввод в оборот',
            key: '/reports/entering'
          },
          {
            name: 'Отбраковка',
            key: '/reports/rejection'
          },
          {
            name: 'Наборы',
            key: '/reports/sets'
          },
          {
            name: 'Вывод из оборота',
            key: '/reports/withrdIntrnlConsmpt'
          },
          {
            name: 'Списание',
            key: '/reports/writeOff'
          }
        ],
        isExpanded: true
      },
      {
        name: 'Задания на линию',
        key: '/consignments',
        access: [FULL_ACCESS],
        icon: <TaskListSquareLtrRegular style={{ height: '100%', width: '100%' }} />
      },
      {
        name: 'Проверка КМ',
        key: '/checkup',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS],
        icon: <CheckCode style={{ height: '100%', width: '100%' }} />
      },
      {
        name: 'Линии',
        key: '/lines',
        access: [FULL_ACCESS],
        icon: <TimelineRegular style={{ height: '100%', width: '100%' }} />
      },
      // {
      //     name: 'Склады',
      //     key: '/storage',
      //     access: [FULL_ACCESS]
      // },
      {
        name: 'Пользователи',
        key: '/users',
        access: [FULL_ACCESS],
        icon: <PersonRegular style={{ height: '100%', width: '100%' }} />
      },
      {
        name: 'Настройки',
        key: '/settings',
        access: [FULL_ACCESS],
        icon: <SettingsRegular style={{ height: '100%', width: '100%' }} />,
        links: [
          {
            name: 'Юридические лица',
            key: '/settings/legal'
          },
          {
            name: 'Владельцы продуктов',
            key: '/settings/productowners'
          },
          {
            name: 'Настройки списания КМ',
            key: '/settings/writeoffSettings'
          },
          {
            name: 'Общие настройки',
            key: '/settings/mainSettings'
          },
          // {
          //     name: 'FTP',
          //     key: '/settings/ftp'
          // },
          {
            name: 'База данных',
            key: '/settings/database'
          },
        ],
        isExpanded: false
      }
    ]
  }
];

export const navLinkGroupsNoAggregation = [
  {
    links: [
      {
        name: 'Продукты и заказы',
        key: '/products',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS],
        icon: <BoxRegular style={{ height: '100%', width: '100%' }} />
      },
      // {
      //   name: 'Заказы КМ',
      //   key: '/orders',
      //   access: [FULL_ACCESS, CODES_CONTROL_ACCESS]
      // },
      // {
      //   name: 'Агрегаты (КИТУ)',
      //   key: '/aggregates',
      //   access: [FULL_ACCESS, CODES_CONTROL_ACCESS]
      // },
      {
        name: 'Отчеты',
        key: '/reports',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS],
        icon: <TextboxRegular style={{ height: '100%', width: '100%' }} />,
        links: [
          {
            name: 'Нанесение',
            key: '/reports/printing'
          },
          // {
          //   name: 'Агрегация',
          //   key: '/reports/aggregation'
          // },
          {
            name: 'Ввод в оборот',
            key: '/reports/entering'
          },
          {
            name: 'Отбраковка',
            key: '/reports/rejection'
          },
          {
            name: 'Наборы',
            key: '/reports/sets'
          },
        ],
        isExpanded: true
      },
      {
        name: 'Задания на линию',
        key: '/consignments',
        access: [FULL_ACCESS],
        icon: <TaskListSquareLtrRegular style={{ height: '100%', width: '100%' }} />
      },
      {
        name: 'Проверка КМ',
        key: '/checkup',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS],
        icon: <CheckCode style={{ height: '100%', width: '100%' }} />
      },
      {
        name: 'Линии',
        key: '/lines',
        access: [FULL_ACCESS],
        icon: <TimelineRegular style={{ height: '100%', width: '100%' }} />
      },
      // {
      //     name: 'Склады',
      //     key: '/storage',
      //     access: [FULL_ACCESS]
      // },
      {
        name: 'Пользователи',
        key: '/users',
        access: [FULL_ACCESS],
        icon: <PersonRegular style={{ height: '100%', width: '100%' }} />
      },
      {
        name: 'Настройки',
        key: '/settings',
        access: [FULL_ACCESS],
        icon: <SettingsRegular style={{ height: '100%', width: '100%' }} />,
        links: [
          {
            name: 'Юридические лица',
            key: '/settings/legal'
          },
          {
            name: 'Владельцы продуктов',
            key: '/settings/productowners'
          },
          {
            name: 'Настройки списания КМ',
            key: '/settings/writeoffSettings'
          },
          {
            name: 'Общие настройки',
            key: '/settings/mainSettings'
          },
          // {
          //     name: 'FTP',
          //     key: '/settings/ftp'
          // },
          {
            name: 'База данных',
            key: '/settings/database'
          },
          {
            name: 'Журнал запросов "Честный знак"',
            key: '/settings/logs'
          },
        ],
        isExpanded: false
      }
    ]
  }
];

export const pathWithState = [
  '/products',
  '/consignments',
  '/aggregates',
  '/reports/printing',
  '/reports/entering',
  '/reports/rejection',
  '/reports/aggregation',
  '/reports/sets',
  '/lines'
];

export const pathWithCrumbs = [
  '/reports/printing',
  '/reports/entering',
  '/reports/rejection',
  '/reports/aggregation',
  '/reports/sets',
];
