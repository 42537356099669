import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { pageSize } from './constants';
import { setPrevRoute } from './redux/App/appReducer';
import { getFilteredData, getGlobalErrorText } from 'redux/App/appSelectors';
import { isResourseLink } from 'functions';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useCrumbsContext } from 'context/CrumbsContext';
import { store } from './redux';
import { debounce } from 'lodash';

export const useCheckboxFilter = (triggers, paramName, setQuery, query) => {
  const [selectedFilters, setSelectedFilters] = useState(new Set(query[paramName]?.split(',')));

  useEffect(() => {
    setSelectedFilters(new Set(query[paramName]?.split(',')));
  }, [query]);

  const onChangeFilter = (key) => {
    const filtersQuery = selectedFilters;
    if (filtersQuery.has(key)) {
      filtersQuery.delete(key);
    } else {
      filtersQuery.add(key);
    }

    setSelectedFilters(filtersQuery);
    setQuery(
      (prev) => ({
        ...prev,
        [paramName]: filtersQuery.size ? Array.from(filtersQuery).join(',') : undefined
      }),
      'replace'
    );
  };

  return [selectedFilters, onChangeFilter];
};

const calculateTotalPages = (totalItemsCount, itemsPerPage = pageSize) => {
  if (!totalItemsCount || !itemsPerPage) return 1;
  return Math.ceil(totalItemsCount / itemsPerPage);
};

export const useBackBtn = (prevRoute) => {
  const history = useHistory();

  const popHandler = () => {
    if (prevRoute) {
      history.replace(prevRoute);
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', popHandler);

    return () => {
      window.removeEventListener('popstate', popHandler);
    };
  }, []);
};

export const useNavigation = (statusParamName, status) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const popHandler = () => {
    const prevRoute = store.getState().app.prevRoute;
    history.push(prevRoute);
  };

  useEffect(() => {
    window.addEventListener('popstate', popHandler);
    return () => {
      let prevLocation;
      if (status) {
        prevLocation = `${location.pathname}?${statusParamName}=${status}`;
      } else {
        prevLocation = `${location.pathname}${location.search}`;
      }
      dispatch(setPrevRoute(prevLocation));
      window.removeEventListener('popstate', popHandler);
    };
  }, []);
};

// ===================== usePaginationAndSearch ========================= //

export const usePaginationAndSearch = ({
  callback,
  query,
  setQuery,
  reportType,
  statusParamName,
  status,
  tableHeaderHeight = 44, // Высота заголовка таблицы
  headerHeight = 125 // Высота шапки
}) => {
  const location = useLocation();
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [oldSearch, setOldSearch] = useState('');

  const calculateRowsPerPage = () => {
    const screenHeight = window.innerHeight;
    const footerHeight = 20; // Высота футера
    const rowHeight = 44; // Высота одной строки
    const filterHeight = 120; // Высота фильтров если они есть
    const padding = 32; // Отступы

    // Учитываем высоту заголовка таблицы
    const availableHeight = screenHeight - headerHeight - footerHeight - tableHeaderHeight - filterHeight - padding;

    // Вычисляем оптимальное количество строк
    const optimalRows = Math.floor(availableHeight / rowHeight);

    // Ограничиваем минимальным и максимальным значением
    return Math.max(5, Math.min(optimalRows, 50));
  };

  useEffect(() => {
    const _pageNumber = query.pageNumber ? query.pageNumber : 1;
    onPageChanged(_pageNumber);

    const initialRowsPerPage = calculateRowsPerPage();
    setQuery((prev) => ({
      ...prev,
      quantityOnPage: initialRowsPerPage
    }), 'replace');

    return async () => {
      await setQuery({});
    };
  }, []);

  useEffect(() => {
    const pathname = location.pathname + location.search;
    if (!!location.search && pathname !== oldSearch) {
      if (reportType) callback(query, reportType);
      else callback(query);
      setOldSearch(location.pathname + location.search);
    }

    setTotalPages(calculateTotalPages(totalItems, query.quantityOnPage));

    if (!query.pageNumber) {
      setQuery((prev) => ({ ...prev, pageNumber: 1 }), 'replace');
    }

    if (!query.quantityOnPage) {
      const rowsPerPage = calculateRowsPerPage();
      setQuery((prev) => ({
        ...prev,
        quantityOnPage: rowsPerPage
      }), 'replace');
    }
  }, [query, totalItems, reportType]);

  useEffect(() => {
    const abortController = new AbortController();

    const handleResize = debounce(() => {
      const rowsPerPage = calculateRowsPerPage();

      setQuery((prev) => {
        if (rowsPerPage !== prev.quantityOnPage) {
          const newQuery = {
            ...prev,
            quantityOnPage: rowsPerPage,
            pageNumber: 1
          };

          if (reportType) {
            callback(newQuery, reportType, { signal: abortController.signal });
          } else {
            callback(newQuery, { signal: abortController.signal });
          }

          return newQuery;
        }
        return prev;
      }, 'replace');
    }, 300);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
      abortController.abort();
    };
  }, [reportType, callback]);

  const onPageChanged = (page) => setQuery((prev) => ({ ...prev, pageNumber: Number(page) }), 'replace');
  const onSearchTermChanged = (term) => {
    return setQuery((prev) => ({ ...prev, pageNumber: 1, searchValue: term.target.value }), 'replace')
  }

  return {
    totalPages,
    currentPage: query.pageNumber,
    setTotalItems,
    onPageChanged,
    onSearchTermChanged,
    rowsPerPage: query.quantityOnPage
  };
};

export const useAlert = (_alertText = '', _alertTitle = '', _isAlertShowed = false) => {
  const [alertText, setAlertText] = useState(_alertText);
  const [alertTitle, setAlertTitle] = useState(_alertTitle);
  const [isAlertShowed, setIsAlertShowed] = useState(_isAlertShowed);

  const showAlert = (title, text, onAgree) => {
    setAlertTitle(title);
    setAlertText(text);
    setIsAlertShowed(true);
    // if (onAgree) {
    //     onAgree()
    // } else {
    //     setIsAlertShowed(false)
    // }
  };

  const hideAlert = () => {
    setIsAlertShowed(false);
  };

  return { alertTitle, alertText, isAlertShowed, showAlert, hideAlert };
};

export const useSendRequest = (setLoading) => {
  const afterSending = (res, onSuccess, onError) => {
    if (res?.statusCode === 200 || res?.status === 200) {
      onSuccess && onSuccess(res);
    } else {
      onError && onError();
    }
    setLoading(false);
  };

  return { afterSending };
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useSelect = (getSelectData, propName, mappedFunc) => {
  const [selectData, setSelectData] = useState([]);
  const getData = async (...params) => {
    const res = await getSelectData(...params);

    if (!res?.statusCode) {
      if (propName) {
        setSelectData(mappedFunc(res[propName]));
      } else {
        setSelectData(mappedFunc(res));
      }
    }
  };

  return [selectData, getData];
};
const defaultIntitVals = {
  brandId: null,
  categoryId: null,
  subCategoryId: null,
  maxRemainderOfMarkingCodes: null,
  minRemainderOfMarkingCodes: null,
  companyId: null
};

// ============================================= useDataFilter =========================================================

export const useDataFilter = (page, type = null) => {
  const history = useHistory();
  const prevPath = history.location.state?.prevPath;
  const preSavedFilterVals = useSelector(getFilteredData);
  const comingToParentLink = isResourseLink(prevPath, page);
  const initialValues = comingToParentLink ? preSavedFilterVals : defaultIntitVals;
  const { setCrumbs } = useCrumbsContext();

  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    searchValue: StringParam,
    quantityOnPage: NumberParam,
    brandId: NumberParam,
    categoryId: NumberParam,
    subCategoryId: NumberParam,
    maxRemainderOfMarkingCodes: NumberParam,
    minRemainderOfMarkingCodes: NumberParam,
    companyId: NumberParam,
    productId: NumberParam,
    lineId: NumberParam,
    status: StringParam,
    minProductCreationDate: NumberParam,
    maxProductCreationDate: NumberParam,
    level: NumberParam,
    minCreationDate: NumberParam,
    maxCreationDate: NumberParam,
    minSendingDate: NumberParam,
    maxSendingDate: NumberParam,
    roleName: StringParam,
    searchedCode: StringParam
  });

  useEffect(() => {
    if (comingToParentLink) {
      if (page === 'reports' && type !== initialValues?.type) setQuery(query, 'replace');
      else setQuery({ ...query, ...initialValues }, 'replace');
    } else {
      setQuery(query, 'replace');
    }
    setCrumbs([]);
  }, [type]);

  return [query, setQuery];
};
