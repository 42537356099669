import { SET_CHECKUP_RESULT } from './CheckupAction';

const initialState = {
    checkupResult: null
};

const CheckupReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHECKUP_RESULT:
            return {
                ...state,
                checkupResult: action.payload
            };
        default:
            return state;
    }
};

export default CheckupReducer;