import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import settingsAPI from 'api/settingsAPI';
import ReportsAPI from '../../api/reportsAPI';
import g from '../../assets/scss/Main.module.scss';
import { Alert } from '../../components/Alert/Alert';
import { Pagination } from '../../components/Pagination/Pagination';
import { TableAction } from '../../components/TableAction/TableAction';
import { WaitingScreen } from '../../components/WaitingScreen/WaitingScreen';
import { searchBoxStyles, reportStatuseForPrinting } from '../../constants';
import { formatTwoPartDate, isShipped, getDateFromTimestamp, mapItemsForDropdown } from '../../functions';
import { useAlert, usePaginationAndSearch, useSendRequest, useDataFilter } from '../../hooks';
import { getGeneralSettings, getGlobalErrorText } from '../../redux/App/appSelectors';
import s from './Reports.module.scss';
import { getTypesInfo } from './constants';
import { SyncStatus } from '../../components/SyncStatus/SyncStatus';
import ProductsAPI from '../../api/productsAPI';
import PrintReportModal from 'pages/Report/SendEnteringReportModal';
import { setGenSettings, setPrintReports } from 'redux/App/appReducer';
import { getShouldCheckExpDateForPrintRep } from '../../redux/App/appSelectors';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { ReportsFilterNew } from 'components/Filters/ReportsFilterNew/ReportsFilterNew';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import LegalApi from '../../api/legalAPI';

import СreatedIcon from '../../assets/img/СreatedIcon.svg';
import CreatedAndKmArchivedIcon from '../../assets/img/CreatedAndKmArchivedIcon.svg';
import ProcessedWithErrorKmArchivedIcon from '../../assets/img/ProcessedWithErrorKmArchivedIcon.svg';
import SuccessfullyProcessedAndKmArchivedIcon from '../../assets/img/SuccessfullyProcessedAndKmArchivedIcon.svg';
import ProcessedWithErrorIcon from '../../assets/img/ProcessedWithErrorIcon.svg';
import SuccessfullyProcessedIcon from '../../assets/img/SuccessfullyProcessedIcon.svg';
import FragmentedIcon from '../../assets/img/FragmentedIcon.svg';
import SendedIcon from '../../assets/img/SendedIcon.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/CopyIcon.svg';
import { ReactComponent as TrashIcon } from '../../assets/img/TrashIcon.svg';

import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  useTableFeatures,
  useTableSort,
  useTableSelection,
  TableSelectionCell,
  Spinner,
  Tooltip,
  Checkbox,
} from "@fluentui/react-components";
import { ProductsFilterNew } from 'components/Filters/ProductsFilterNew/ProductsFilterNew';
import { LinkELement } from 'components/LinkELement/LinkELement';
import clsx from 'clsx';
import { FixedSizeList as List } from "react-window";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useTableColumnResizing } from '../../components/Table/TableColumnResizingHook';
import { useDragAndDrop } from '../../components/Table/DragAndDropHook';
import { ArrowDownRegular, ArrowUpRegular } from '@fluentui/react-icons';

import { ButtonMenu } from 'components/ButtonMenu/ButtonMenu';
import { ClickButton } from 'components/ClickButton/ClickButton';
import { useCrumbsContext } from 'context/CrumbsContext';


export const Reports = ({ type, children }) => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [reportStatus, setreportStatus] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);
  const [selectedReports, setSelectedReports] = useState(new Map());
  const globalErrorText = useSelector(getGlobalErrorText);
  const [query, setQuery] = useDataFilter('reports', type);
  const [tableType, setTableType] = useState(type);


  const typesInfo = getTypesInfo();

  useEffect(() => {
    setTableType(type);
    setMemoizedColumns(loadSavedState(type));
  }, [type]);

  const [isPrintRepModal, setIsPrintModal] = useState(false);
  const dispatch = useDispatch();
  const shouldCheckExpDateForPrintRepFromRdx = useSelector(getShouldCheckExpDateForPrintRep);
  const [shouldCheckExpDateForPrintRep, setShouldCheckExpDateForPrintRep] = useState(false);
  const genSettingsFromRedx = useSelector(getGeneralSettings);
  const [selectedRows, setSelectedRows] = useState(() => new Set([]));
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const { setCrumbs, notify } = useCrumbsContext();

  const loadSavedState = (itemType) => {
    try {
      const savedState = sessionStorage.getItem(`table_${itemType}_state`);
      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);
        if (columnOrder && Array.isArray(columnOrder)) {
          const baseColumns = typesInfo[itemType]?.tableHeader?.filter(column => column.name);
          const columnMap = new Map(baseColumns.map(col => ({
            id: col.id,
            columnId: col.name
          })).map(col => [col.columnId, col]));

          const orderedColumns = columnOrder
            .map(id => {
              const colInfo = columnMap.get(id);
              if (!colInfo) return null;

              const column = baseColumns.find(c => c.name === id);
              if (!column) return null;

              return mapColumnToTableFormat(column);
            })
            .filter(Boolean);

          baseColumns.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.name)) {
              orderedColumns.push(mapColumnToTableFormat(col));
            }
          });

          return orderedColumns;
        }
      }

      const baseColumns = typesInfo[itemType]?.tableHeader?.filter(column => column.name);
      console.log(baseColumns, 'baseColumns');

      return baseColumns?.map(column => mapColumnToTableFormat(column));

    } catch (error) {
      console.warn('Error loading saved state:', error);
      return [];
    }
  };

  const mapColumnToTableFormat = (column) => ({
    id: column.id,
    columnId: column.name,
    label: column.title,
    minWidth: column.minWidth,
    sortable: column.sortable,
    compare: (a, b) => {
      if (!a || !b) return 0;

      const aValue = a[column.name]?.label ?? '';
      const bValue = b[column.name]?.label ?? '';

      // Если оба значения пустые, считаем их равными
      if (!aValue && !bValue) return 0;

      if (column.name === 'numberMarkingCodes') {
        return (Number(aValue) || 0) - (Number(bValue) || 0);
      }

      const dateFields = ['creationDate', 'sendingDate', 'productCreationDate', 'actionDate', 'sourceDocDate'];
      if (dateFields.includes(column.name)) {
        const parseDateStr = (dateStr) => {
          if (!dateStr) return null;
          const [day, month, year] = dateStr.split('.');
          return new Date(year, month - 1, day).getTime();
        };

        const timestampA = parseDateStr(aValue);
        const timestampB = parseDateStr(bValue);

        if (!timestampA && !timestampB) return 0;
        if (!timestampA) return 1;
        if (!timestampB) return -1;

        return timestampA - timestampB;
      }

      return String(aValue).localeCompare(String(bValue));
    }
  });

  const [memoizedColumns, setMemoizedColumns] = useState(() => {

    return loadSavedState(tableType);
  });

  const memoizedItems = useMemo(() => {
    return reports.map((report) => {
      const item = {};
      typesInfo[tableType].tableHeader.forEach(header => {
        const fieldName = header.name;
        let fieldValue = report[fieldName];

        switch (fieldName) {
          case 'creationDate':
          case 'sendingDate':
          case 'productCreationDate':
          case 'actionDate':
          case 'sourceDocDate':
            item[fieldName] = {
              label: fieldValue ? formatTwoPartDate(fieldValue).mainPart : null,
              id: report.id,
            };
            break;
          case 'status':
            item[fieldName] = { label: fieldValue || 'UNKNOWN', id: report.id, errorMessage: report?.errorMessage };
            break;
          case 'numberMarkingCodes':
            item[fieldName] = { label: fieldValue !== undefined ? String(fieldValue) : null, id: report.id };
            break;
          case 'trashButton':
            item[fieldName] = { label: report.id };
            break;
          default:
            item[fieldName] = { label: fieldValue, id: report.id };
        }
      });
      return item;
    });
  }, [reports, tableType]);

  useEffect(() => {
    if (globalErrorText) {
      setreportStatus(null);
    }
  }, [globalErrorText]);

  const getGeneralSetting = async () => {
    const res = await settingsAPI.getGeneralSettings();
    dispatch(setGenSettings(res));
  };

  useEffect(() => {
    getGeneralSetting();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  }

  useEffect(() => {
    if (typeof shouldCheckExpDateForPrintRepFromRdx === 'boolean') {
      setShouldCheckExpDateForPrintRep(shouldCheckExpDateForPrintRepFromRdx);
    }
  }, [shouldCheckExpDateForPrintRepFromRdx]);

  const getReports = async (query = {}) => {
    setLoading(true);
    const getReportsTypes = {
      printing: (queryString) => ReportsAPI.getUtilisationReports(queryString),
      aggregation: (queryString) => ReportsAPI.getAggregationReports(queryString),
      entering: (queryString) => ReportsAPI.getRolloutReports(queryString),
      rejection: (queryString) => ReportsAPI.getRejectReports(queryString),
      sets: (queryString) => ReportsAPI.getSetsReport(queryString),
      withrdIntrnlConsmpt: (queryString) => ReportsAPI.getInternalWithdrowalReport(queryString),
      writeOff: (queryString) => ReportsAPI.getInternalWriteOffReport(queryString)
    };
    let res;
    res = await getReportsTypes[tableType](query);
    if (tableType === 'printing') {
      if (
        genSettingsFromRedx &&
        'checkProductionAndExpirationDatesWhenSubmittingUtilisationReports' in genSettingsFromRedx
      )
        setShouldCheckExpDateForPrintRep(
          genSettingsFromRedx.checkProductionAndExpirationDatesWhenSubmittingUtilisationReports
        );
    }

    if (res) {
      if ('list' in res && 'quantity' in res) {
        setReports(res.list);
        setTotalItems(res.quantity);
        if (tableType === 'printing') {
          dispatch(setPrintReports(res.list));
        }
      }
    }
    setLoading(false);
  };

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();

  const { afterSending } = useSendRequest(setLoading);

  //============================= send reports =============================

  const sendReports = async () => {
    const sendReportsTypes = {
      // printing: (arrayArg) => ReportsAPI.sendUtilisationReports(arrayArg),
      printing: shouldCheckExpDateForPrintRep
        ? () => setIsPrintModal(true)
        : (arrayArg) => ReportsAPI.sendUtilisationReports(arrayArg),
      aggregation: (arrayArg) => ReportsAPI.sendAggregationReports(arrayArg),
      entering: (arrayArg) => ReportsAPI.sendRolloutReports(arrayArg),
      rejection: (arrayArg) => ReportsAPI.sendRejectReports(arrayArg),
      sets: (arrayArg) => ReportsAPI.sendSetsReports(arrayArg),
      withrdIntrnlConsmpt: (arrayArg) => ReportsAPI.sendInternalWithdrwlReports(arrayArg),
      writeOff: (arrayArg) => ReportsAPI.sendInternalWriteOffReports(arrayArg)
    };

    if (tableType === 'printing' && shouldCheckExpDateForPrintRep) {
      sendReportsTypes[tableType]();
    } else {
      setreportStatus(`$inProgress`);
      setSelectedReports(new Map());
      const res = await sendReportsTypes[tableType](Array.from(selectedReports.keys()));

      const onSuccess = () => {
        if (res?.errors?.length) {

          const errorString = res?.errors
            .reduce((acc, elem, i) => {
              return [...acc, [res?.notSendedRollouts[i], elem]];
            }, [])
            .map((item) => item.join(': '))
            .map((str) => <p style={{ margin: '5px 0' }}>{str}</p>);

          showAlert('Ошибки отправки отчетов', errorString);
        } else {
          showAlert('Отправка отчётов', 'Отчёты успешно отправлены');
        }
        getReports(query);
        setreportStatus('success');
      };

      const onError = async () => {
        setreportStatus('error');
        setSelectedReports(new Map());
        setSelectedRows(new Set())
      };

      afterSending(res, onSuccess, onError);
    }
  };

  //============================= send reports =============================

  const syncProducts = async (tableType) => {
    setSyncStatus(`${tableType}InProgress`);
    let syncRes;

    if (tableType === 'suz') {
      syncRes = await ProductsAPI.setGetReportStatus();
    }

    if (syncRes.statusCode === 200) {
      await getReports(query);
      setSyncStatus('success');
    } else {
      setSyncStatus('error');
    }
  };

  //========== ARCHIVE REPORT =========================
  const ArchiveReportHandler = async (id) => {

    setLoading(true);
    const archiveReportsTypes = {
      printing: () => ReportsAPI.CreateArchivedPrintReports(id),
      aggregation: () => ReportsAPI.CreateArchivedAggregationReports(id),
      entering: () => ReportsAPI.CreateArchivedRolloutReports(id),
      rejection: () => ReportsAPI.CreateArchivedDropoutReports(id),
      sets: () => ReportsAPI.CreateArchivedSetReports(id),
      withrdIntrnlConsmpt: () => ReportsAPI.CreateArchivedLkReceiptReports(id),
      writeOff: () => ReportsAPI.CreateArchivedWriteOffReports(id)
    }
    let res = await archiveReportsTypes[tableType](query);
    setLoading(false);

    const onSuccess = () => {
      if (res?.errors?.length) {
        const errorString = res?.errors
          .reduce((acc, elem, i) => {
            return [...acc, [res?.notSendedRollouts[i], elem]];
          }, [])
          .map((item) => item.join(': '))
          .map((str) => <p style={{ margin: '5px 0' }}>{str}</p>);

        showAlert('Ошибки архивирования отчетов', errorString);
        notify({ title: 'При архивировании отчетов произошла ошибка', intent: 'error', timeout: 500 });
      }
      getReports(query);
      setreportStatus('success');
      notify({ title: 'Отчеты успешно архивированы', intent: 'success', timeout: 500 });
    };

    const onError = async () => {
      setreportStatus('error');
      setSelectedReports(new Map());
      setSelectedRows(new Set())
    };

    afterSending(res, onSuccess, onError);
  }

  // ======= ON SELECT REPORT ==========================
  const onSelectReport = (report) => {
    if (!isShipped(report?.status)) {
      const newSelectedReports = new Map(selectedReports);
      if (selectedReports.has(report.id)) {
        newSelectedReports.delete(report.id);
      } else {
        newSelectedReports.set(report.id, report);
      }
      setSelectedReports(newSelectedReports);
    }
  };

  // ======= ON SELECT REPORT ==========================

  const onSubmitPrintReport = async (data) => {
    const resData = data.map((rep) => ({
      id: rep.id,
      productCreationDate: rep.productCreationDate,
      expirationDate: rep.expirationDate
    }));
    await ReportsAPI.senPrinRepsParams(resData);
    await getReports(query);
  };

  const { onPageChanged: baseOnPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getReports,
      query,
      setQuery,
      headerHeight: 200
    });

  const onPageChanged = (page) => {
    // Обновляем selectedRows на основе selectedReports для текущей страницы
    const newSelectedRows = new Set();
    reports.forEach((report, index) => {
      if (selectedReports.has(report.id)) {
        newSelectedRows.add(index);
      }
    });
    setSelectedRows(newSelectedRows);
    baseOnPageChanged(page);
  };

  useEffect(() => {
    if (reports.length > 0) {
      const newSelectedRows = new Set();
      reports.forEach((report, index) => {
        if (selectedReports.has(report.id)) {
          newSelectedRows.add(index);
        }
      });
      setSelectedRows(newSelectedRows);
    }

  }, [reports, selectedReports]);

  useEffect(() => {
    setCrumbs([
      `/reports`,
      `/reports/${tableType}`,
    ]);
  }, [reports, tableType]);

  const showButton = false;

  const {
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
    selection: {
      allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
  } = useTableFeatures(
    {
      columns: memoizedColumns,
      items: memoizedItems,
    },
    [
      useTableSort({
        defaultSortState: {
          sortColumn: 'productCreationDate',
          sortDirection: "descending"
        },
      }),
      useTableSelection({
        selectionMode: "multiselect",
        selectedItems: selectedRows,
        onSelectionChange: (e, data) => {
          setSelectedRows(data.selectedItems);
          const newSelectedReports = new Map(selectedReports);
          reports.forEach((report, index) => {
            if (data.selectedItems.has(index)) {
              newSelectedReports.set(report.id, report);
            } else {
              newSelectedReports.delete(report.id);
            }
          });
          setSelectedReports(newSelectedReports);
        }
      }),
    ]
  );

  const headerSortProps = (columnId) => {
    const column = memoizedColumns?.find(col => col.columnId === columnId);

    if (!column?.sortable) return {};

    return {
      onClick: (e) => {
        toggleColumnSort(e, columnId);
      },
      sortDirection: getSortDirection(columnId),
    };
  };

  const rows = sort(getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
      ...row,
      onClick: (e) => {
        if (!isShipped(reports[row.rowId]?.status)) {
          onSelectReport(reports[row.rowId])
          toggleRow(e, row.rowId);
        }
      },
      onKeyDown: (e) => {
        if (e.key === " ") {
          e.preventDefault();
          toggleRow(e, row.rowId);
        }
      },
      selected,
      appearance: selected ? ("brand") : ("none"),
    };
  }));

  const RenderRowContent = React.memo(({ column, item }) => {
    const columnId = column.columnId;
    const itemLabel = item[columnId]?.label;
    const errorMessage = item[columnId]?.errorMessage;

    const cache = useRef(new Map());

    const cacheKey = `${columnId}-${item.id}`;

    if (!cache.current.has(cacheKey)) {
      const content = (() => {
        switch (itemLabel && columnId) {
          case 'status':
            switch (itemLabel) {
              case 'CREATED': return <img src={СreatedIcon} alt="Создан" />;
              case 'SENDED': return <img src={SendedIcon} alt="Отправлен" />;
              case 'DISAGGREGATED': return <img src={FragmentedIcon} alt="Разагрегирован" />;
              case 'ACCEPTED': return <img src={SuccessfullyProcessedIcon} alt="Успешно обработан" />;
              case 'REJECTED': return (
                <Tooltip content={{ children: `Текст ошибки: ${item[column.columnId]?.errorMessage}`, className: s.tooltip }}>
                  <img src={ProcessedWithErrorIcon} alt="Обработан с ошибкой" />
                </Tooltip>
              );
              case 'ACCEPTED_ARCHIVED': return <img src={SuccessfullyProcessedAndKmArchivedIcon} alt="Успешно обработан и КМ архивированы" />;
              case 'CREATED_ARCHIVED': return <img src={CreatedAndKmArchivedIcon} alt="Создан и КМ архивированы" />;
              case 'REJECTED_ARCHIVED': return <img src={ProcessedWithErrorKmArchivedIcon} alt="Обработан с ошибкой и КМ архивированы" />;
              default: return itemLabel;
            }
          case 'productName':
            return <LinkELement
              addStyles={clsx(g.tableRow, s.firstRow)}
              link={`/reports/${tableType}/${item?.productName?.id}`}
              text={item?.productName?.label}
              params={{
                reportId: item?.productName?.id,
                tableType: 'printing'
              }}
              appearance={'subtle'}
            />;
          case 'suzId':
          case 'partNumber':
            return (
              <>
                <p>{itemLabel}</p>
                <div className={s.copyIconWrapper} onClick={() => copyToClipboard(itemLabel)}>
                  <CopyIcon className={s.copyIcon} />
                </div>
              </>
            );
          case 'trashButton':
            return (
              <ClickButton
                iconName={<TrashIcon style={{ '--icon-color': '#8A8886', cursor: 'pointer' }} />}
                isButton={true}
                addStyles={s.trashButton}
                onClick={() => ArchiveReportHandler(item[columnId].label)}
              />
            );
          default: return <p>{itemLabel}</p>;
        }
      })();

      cache.current.set(cacheKey, content);
    }
    return cache.current.get(cacheKey);
  });

  const RenderRow = React.memo(({ index, style, data }) => {
    const { item, selected, appearance, onClick, onKeyDown } = data[index];

    return (
      <TableRow
        key={item.id}
        onKeyDown={onKeyDown}
        aria-selected={selected}
        className={clsx(s.tableRow, !isShipped(item?.status?.label) ? (selected && s.tableSelectionCell) : s.disabledCheckbox)}
        appearance={appearance}
        style={style}
      >
        <TableSelectionCell
          onClick={onClick}
          checked={selected}
          style={{ width: '44px' }}
          checkboxIndicator={{ "aria-label": "Select row" }}
          className={isShipped(item?.status?.label) ? s.disabledCheckbox : ''}
        />
        {orderedHeaderItems.map((column) => (
          <TableCell
            id={s[column.columnId]}
            key={column.columnId}
            style={{
              display: 'flex',
              alignItems: 'center',
              width: getColumnWidth(column.columnId),
              minWidth: getColumnWidth(column.columnId)
            }}
          >
            {column.columnId !== "status" && column.columnId !== "trashButton" ? (
              <Tooltip showDelay={300} content={item[column.columnId]?.label}>
                <div><RenderRowContent column={column} item={item} /></div>
              </Tooltip>
            ) : (
              <div><RenderRowContent column={column} item={item} /></div>
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  });

  useEffect(() => {
    LegalApi.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  useEffect(() => {
    const filterData = [
      { label: 'Компания', key: 'companyId', options: companiesForDropdown },
      { label: 'Статус', key: 'status', options: reportStatuseForPrinting },
      {
        label: 'Дата создания от',
        key: 'minCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата создания до',
        key: 'maxCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата отправки от',
        key: 'minSendingDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата отправки до',
        key: 'maxSendingDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата производства КМ от',
        key: 'minProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата производства КМ до',
        key: 'maxProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      }
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options?.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: { key: option.key, text: option.text, category: filter.key, label: filter.label }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              category: filter.key,
              label: filter.label
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, companiesForDropdown]);

  const renderSortIcon = (columnId) => {
    const column = memoizedColumns?.find(col => col.columnId === columnId);
    if (!column?.sortable) return null;

    const sortDirection = getSortDirection(columnId);

    const iconProps = {
      style: {
        marginRight: '0.5rem',
        height: '12px',
        width: '12px',
        color: sortDirection ? '#0078D4' : 'inherit'
      }
    };

    return sortDirection === 'ascending'
      ? < ArrowDownRegular  {...iconProps} />
      : <ArrowUpRegular  {...iconProps} />;
  };


  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...memoizedColumns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setMemoizedColumns(newColumns);
  }, [memoizedColumns, setMemoizedColumns]);

  const {
    isResizing,
    handleResizeStart,
    getColumnWidth,
    columnWidths,
    columnOrder,
    updateState,
    orderedHeaderItems
  } = useTableColumnResizing({}, memoizedColumns, `${tableType}`, 'calc(100vw - 180px - 0px)', 90);

  const {
    draggingColumnId,
    handleDragStart,
    handleDragEnd
  } = useDragAndDrop(
    memoizedColumns,
    (sourceIndex, destinationIndex, newOrder) => {
      updateState(columnWidths, newOrder);
      handleColumnReorder(sourceIndex, destinationIndex);
    }
  );

  const handleResizeStartWrapper = (e, columnId) => {
    e.stopPropagation();
    if (draggingColumnId) return;
    handleResizeStart(e, columnId);
  };

  useEffect(() => {
    // Очищаем выбранные отчеты при смене типа таблицы
    setSelectedReports(new Map());
    setSelectedRows(new Set());
    setMemoizedColumns(loadSavedState(tableType));
  }, [tableType]);

  return (
    <div className={s.tableWrapper}>
      {isAlertShowed && (
        <Alert
          title={alertTitle}
          text={alertText}
          onClose={() => {
            hideAlert();
          }}
        />
      )}
      {isPrintRepModal ? (
        <PrintReportModal
          isOpen={isPrintRepModal}
          setIsOpen={setIsPrintModal}
          reports={Array.from(selectedReports.values())}
          onDismiss={() => {
            setIsPrintModal(false);
            setSelectedReports(new Map());
            setSelectedRows(new Set());
          }}
          onSubmit={onSubmitPrintReport}
        />
      ) : null}
      <>
        <div className={g.titleWrapper}>
          <div className={` ${g.searchBlockWrapper}`}>
            {tableType !== 'sets' && (
              <SearchBlock
                iconProps={''}
                styles={searchBoxStyles}
                value={query.searchValue}
                placeholder="Поиск по коду отчёта, названию товара, gtin или номеру задания"
                onClear={() => onSearchTermChanged('')}
                onSearch={(newValue) => onSearchTermChanged(newValue)}
              />
            )}
          </div>
          <div isFiltersDisplayed>
            <ButtonToggle
              text="Фильтры"
              value={isOpenFilters}
              onClick={() => setIsOpenFilters(!isOpenFilters)}
              optionSize={selectedOptions.length}
            />
          </div>
        </div>
        {isOpenFilters && (
          <div className={g.filtersWrapper}>
            <ReportsFilterNew
              query={query}
              setQuery={setQuery}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              tableType={tableType}
              companiesForDropdown={companiesForDropdown}
              setCompaniesForDropdown={setCompaniesForDropdown}
            />
          </div>
        )}

        <div className={g.footer} style={{ margin: ' 0.75rem 0  0.75rem 1rem' }}>
          <LinkButton
            isButton={true}
            text="Отправить выбранные"
            primary={true}
            onClick={() => sendReports()}
            disabled={!selectedReports.size}
          />
        </div>
        <div className={s.tableBlock}>
          {!rows.length && selectedOptions.length ? (
            <div className={g.searchWithFiltersVoid}>Не найдено. Измените параметры фильтрации</div>
          ) : !rows.length ? (
            <div className={g.searchWithFiltersVoid}>Не найдено</div>
          ) : (
            <div className={s.tableContainer} style={{ padding: '0 0.325rem' }}>
              {!loading ? (
                <Table
                  sortable
                  aria-label="Table with sort and selection"
                  className={s.table}
                  noNativeElements
                >
                  <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table-header" direction="horizontal">
                      {(provided) => (
                        <TableHeader
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={s.tableHeader}
                        >
                          <TableRow>
                            <div style={{ width: '44px' }} />
                            {orderedHeaderItems.map((column, index) => (
                              <Draggable
                                key={column.columnId}
                                draggableId={column.columnId}
                                index={index}
                                isDragDisabled={isResizing}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className={clsx(s.tableHeaderCell, {
                                      [s.dragging]: snapshot.isDragging,
                                      [s.draggingColumn]: column.columnId === draggingColumnId
                                    })}
                                    style={{
                                      ...provided.draggableProps.style,
                                      width: getColumnWidth(column.columnId),
                                      minWidth: column.minWidth,
                                    }}
                                    {...headerSortProps(column.columnId)}
                                  >
                                    <div
                                      {...provided.dragHandleProps}
                                      onClick={() => column.sortable && headerSortProps(column.columnId)}
                                      style={{
                                        cursor: isResizing ? 'col-resize' : (column.sortable ? 'pointer' : 'default'),
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: 1,
                                        height: '100%',
                                        padding: '0 8px'
                                      }}
                                    >
                                      <p>{column.label}</p>
                                      {column.sortable && getSortDirection(column.columnId) && (
                                        <span className={s.sortIcon}>
                                          {renderSortIcon(column.columnId)}
                                        </span>
                                      )}
                                    </div>

                                    {index < orderedHeaderItems.length - 1 && (
                                      <div
                                        className={s.resizeHandle}
                                        onMouseDown={(e) => handleResizeStartWrapper(e, column.columnId)}
                                        onTouchStart={(e) => handleResizeStartWrapper(e, column.columnId)}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </TableRow>
                        </TableHeader>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <TableBody>
                    <List
                      height={window.innerHeight - 420}
                      itemCount={rows.length}
                      itemSize={44}
                      width="100%"
                      itemData={rows}
                      className={s.ReactWindow}
                    >
                      {RenderRow}
                    </List>
                  </TableBody>
                </Table>
              ) : (
                <div className={s.spinner}>
                  <Spinner size="huge" />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={g.footer}>
          {totalPages > 1 && (
            <Pagination
              pageCount={totalPages}
              onPageChange={onPageChanged}
              selectedPage={currentPage}
            />
          )}
          <div style={{ marginLeft: 'auto' }}>
            <LinkButton
              addStyles={clsx(s.linkButton)}
              link={`/reports/${tableType}/basket`}
              text="Корзина"
              iconName={<TrashIcon className={s.iconColor} />}
              primary={true}

            />
          </div>
        </div>
      </ >
      {
        reportStatus?.includes('inProgress') && (
          <WaitingScreen
            title={'Выполняется отправка отчётов'}
            text={
              'Пожалуйста, подождите. Это может занять несколько минут. Не закрывайте эту страницу.'
            }
          />
        )
      }
      {
        ['success', 'error'].includes(syncStatus) && (
          <SyncStatus syncStatus={syncStatus} hide={() => setSyncStatus(null)} />
        )
      }
    </div >
  );
};
