import React, { useState, useEffect, useCallback } from 'react';
import g from '../../assets/scss/Main.module.scss';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { moreInfoIcon, searchBoxStyles } from '../../constants';
import UsersAPI from '../../api/usersAPI';
import { usePaginationAndSearch, useDataFilter } from '../../hooks';
import s from './Users.module.scss';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { UsersFilterNew } from 'components/Filters/UsersFilterNew/UsersFilterNew';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import { DirectionalHint, TooltipDelay, TooltipHost } from '@fluentui/react';
import { Tooltip } from '@fluentui/react-components';
import clsx from 'clsx';

const tableHeader = [
  {
    title: 'Логин',
    width: '0.17',
    columnId: 'userName',
    sortable: true
  },
  {
    title: 'ФИО',
    width: '0.3',
    columnId: 'fullName',
    sortable: true
  },
  {
    title: 'Роль',
    width: '0.4',
    columnId: 'roles',
    sortable: true
  },
  {
    title: 'Действия',
    width: '0.13',
    columnId: 'actions',
    sortable: false,
    minWidth: 120
  }
];

export const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('users');
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [columns, setColumns] = useState(() => {
    try {
      const savedState = sessionStorage.getItem('table_users_state');
      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);
        if (columnOrder && Array.isArray(columnOrder)) {
          const columnMap = new Map(tableHeader.map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          tableHeader.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return tableHeader;
  });

  const getUsers = async (query) => {
    setLoading(true);
    let res = await UsersAPI.getUsers(query);
    const { list, quantity } = res;
    setUsers(list);
    setTotalItems(quantity);
    setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getUsers, query, setQuery, headerHeight: 10 });

  const getRolesString = (user) => {
    const roles = [];
    user.roles.forEach((role) => roles.push(role.name));
    return roles.join(', ');
  };

  useEffect(() => {
    if (query.roleName) {
      setSelectedOptions([
        { optionValue: { key: query.roleName, text: query.roleName, category: 'roleName', label: 'Роль' } }
      ]);
    } else {
      setSelectedOptions([]);
    }
  }, [query.roleName]);

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);

    const reorderedUsers = users.map(item => {
      const newItem = {};
      newColumns.forEach(column => {
        newItem[column.columnId] = item[column.columnId];
      });
      return { ...item, ...newItem };
    });
    setUsers(reorderedUsers);
  }, [columns, users]);

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            styles={searchBoxStyles}
            placeholder="Поиск по ФИО или логину"
            value={query.searchValue}
            onClear={() => {
              onSearchTermChanged('');
            }}
            onSearch={(newValue) => onSearchTermChanged(newValue)}
          />
        </div>
        <div className={g.headerFilters}>
          <ButtonToggle
            text="Фильтры"
            value={isOpenFilters}
            onClick={() => setIsOpenFilters(!isOpenFilters)}
            optionSize={selectedOptions.length}
          />
        </div>
      </div>

      <div style={{ margin: '0.75rem 0 0.75rem 1rem' }}>
        <LinkButton
          link={`users/add`}
          isButton={true}
          text="Добавить пользователя"
          primary={true}
        />
      </div>

      {isOpenFilters && (
        <div className={g.filtersWrapper}>
          <UsersFilterNew
            query={query}
            setQuery={setQuery}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </div>
      )}
      <div className={s.mainContent}>
        {!users.length && selectedOptions.length ? (
          <div className={g.searchWithFiltersVoid}>Не найдено. Измените параметры фильтрации</div>
        ) : (
          <Table
            headerItems={columns}
            maxHeight="67vh"
            loading={loading}
            onColumnReorder={handleColumnReorder}
            tableName="users"
            customHeaderClass={s.tableHeader}
          >
            {!!users?.length &&
              users?.map((item) => (
                <div key={item.id} className={s.tableItem}>
                  {columns.map((column) => {
                    let content;
                    let tooltipContent;

                    switch (column.columnId) {
                      case 'userName':
                        content = item.userName;
                        tooltipContent = content;
                        break;
                      case 'fullName':
                        content = `${item.firstName} ${item.surname} ${item.patronymic}`;
                        tooltipContent = content;
                        break;
                      case 'roles':
                        content = getRolesString(item);
                        tooltipContent = content;
                        break;
                      case 'actions':
                        return (
                          <div
                            key={column.columnId}
                            className={s.tableActionCell}
                            style={{
                              width: `calc((100vw - 180px - 3.75rem) * ${column.width})`,
                              minWidth: column.minWidth
                            }}
                          >
                            <LinkButton
                              addStyles={clsx(s.linkButton)}
                              link={`/users/${item.id}`}
                              isButton={true}
                              text="Подробнее"
                            />
                          </div>
                        );
                    }

                    return (
                      <div
                        key={column.columnId}
                        className={s.tableCell}
                        style={{
                          width: `calc((100vw - 180px - 3.75rem) * ${column.width})`,
                          minWidth: column.minWidth
                        }}
                      >
                        <div>
                          <Tooltip
                            content={tooltipContent}
                            delay={500}
                            directionalHint={DirectionalHint.topCenter}
                          >
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {content}
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
          </Table>
        )}
        <div className={g.footer}>
          {totalPages > 1 && (
            <Pagination
              pageCount={totalPages}
              onPageChange={onPageChanged}
              selectedPage={currentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
