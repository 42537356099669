import * as React from "react";
import {
  makeStyles,
  ToggleButton,
  tokens,
  CounterBadge,
  createPresenceComponent,
  motionTokens
} from "@fluentui/react-components";
import { FilterRegular, ChevronDownRegular } from '@fluentui/react-icons';

const useStyles = makeStyles({
  offButton: {
    minWidth: "min-content",
    color: tokens.colorNeutralForeground3,
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground1,
    },
  },
  onButton: {
    minWidth: "min-content",
    backgroundColor: tokens.colorPaletteBlueBorderActive,
    color: tokens.colorNeutralBackground1,
    ':hover': {
      backgroundColor: tokens.colorPaletteBlueBorderActive,
      color: tokens.colorNeutralBackground1,
    },
  },
  counterBadge: {
    margin: '0 0.4rem',
  },
  wrapper: {
    height: '100%',
    minWidth: "min-content",
    color: tokens.colorNeutralForeground3,
    ':hover': {
      backgroundColor: tokens.colorPaletteBlueBorderActive,
      color: tokens.colorNeutralBackground1,
    },
    "& div": {
      display: 'flex',
      alignItems: 'center'
    },
  },
});

const Fade = createPresenceComponent({
  enter: {
    keyframes: [{ transform: "rotate(0deg)" }, { transform: "rotate(-180deg)" }],
    duration: motionTokens.durationGentle,
  },
  exit: {
    keyframes: [{ transform: "rotate(-180deg)" }, { transform: "rotate(0deg)" }],
    duration: motionTokens.durationGentle,
  },
});

export const Default = (args) => <CounterBadge {...args} />;
export const ButtonToggle = ({
  link,
  iconName,
  text,
  value,
  onClick,
  params,
  disabled,
  tooltipText,
  addStyles,
  getProductBalance,
  dataArray,
  icon,
  title,
  optionSize,
  isButton
}) => {
  const classes = useStyles();

  return (
    <div>
      <div >
        <ToggleButton
          icon={iconName ?? < FilterRegular />}
          checked={value}
          className={[value ? classes.onButton : classes.offButton, classes.wrapper]}
          onClick={onClick} >
          {!isButton &&
            <>
              {text}
              < Default className={classes.counterBadge} color="danger" count={optionSize} shape="circular" />
              <Fade visible={value}>
                <ChevronDownRegular
                  className={classes.chevronIcon}
                />
              </Fade>
            </>
          }

        </ToggleButton>
      </div>
    </div >
  );
};