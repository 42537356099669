import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import s from './index.module.scss';
import g from '../../assets/scss/Main.module.scss';
import { DirectionalHint, SearchBox } from '@fluentui/react';
import { moreInfoIcon, searchBoxStyles } from '../../constants';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { Pagination } from '../../components/Pagination/Pagination';
import { useCrumbsContext } from '../../context/CrumbsContext';
import LegalAPI from '../../api/legalAPI';
import { usePaginationAndSearch } from '../../hooks';
import { Checkbox } from '@fluentui/react';
import { addIcon } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getCompanyInfo } from 'redux/LegalFaces/legalFacesSelectors';
import clsx from 'clsx';
import legalAPI from '../../api/legalAPI';
import { setCompanyAC, setErrorAC } from 'redux/LegalFaces/legalFacesReducer';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { Tooltip } from '@fluentui/react-components';

const tableHeader = [
  {
    title: 'Название',
    width: '0.4',
    columnId: 'name',
    sortable: true,
  },
  {
    title: 'ИНН',
    width: '0.3',
    columnId: 'inn',
    sortable: true,
  },
  {
    title: 'Количество продуктов',
    width: '0.15',
    columnId: 'productNumber',
    sortable: true,
  },
  {
    title: 'Действия',
    width: '0.15',
    columnId: 'actions',
    sortable: false,
    minWidth: 120
  }
];

const LegalFacesPage = () => {
  const { setCrumbs } = useCrumbsContext();
  const [legalFaces, setLegalFaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    searchValue: StringParam,
    quantityOnPage: NumberParam,
    roleName: StringParam
  });
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(getCompanyInfo);
  const history = useHistory();
  const [isActive, setIsActive] = useState(true);
  const [isArchived, setIsArchived] = useState(false);

  const [columns, setColumns] = useState(() => {
    try {
      const savedState = sessionStorage.getItem('table_legalFaces_state');
      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);
        if (columnOrder && Array.isArray(columnOrder)) {
          const columnMap = new Map(tableHeader.map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          tableHeader.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return tableHeader;
  });

  const [sortState, setSortState] = useState({
    columnId: null,
    direction: null,
    getSortDirection: (columnId) => {
      if (sortState.columnId !== columnId) return null;
      return sortState.direction;
    }
  });

  const onToggleBox = useCallback(
    (isActive, isArchived, type) => {
      if (type === 'isActive') {
        setIsActive((p) => !p);
        getLegalFaces(!isActive, isArchived);
      } else {
        setIsArchived((p) => !p);
        getLegalFaces(isActive, !isArchived);
      }
    },
    [isActive, isArchived]
  );

  const checkboxes = useMemo(
    () => [
      {
        title: 'Активные',
        variableName: 'isActive',
        value: isActive,
        onChange: () => onToggleBox(isActive, isArchived, 'isActive')
      },
      {
        title: 'Архивные',
        variableName: 'isArchived',
        value: isArchived,
        onChange: () => onToggleBox(isActive, isArchived, 'isArchived')
      }
    ],
    [isActive, isArchived]
  );

  useEffect(() => {
    setCrumbs([
      '/settings',
      '/settings/legal'
    ]);

    setQuery(query, 'replace');
  }, []);

  const getLegalFaces = async (isActive, isArchived) => {
    setLoading(true);
    //let res = await LegalAPI.getCompanies(query);
    let res = await LegalAPI.getCompanies2(isActive, isArchived);
    setLegalFaces(res);
    setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, currentPage } = usePaginationAndSearch({
    callback: () => getLegalFaces(isActive, isArchived),
    query,
    setQuery
  });

  const handleDetailsClick = async (id) => {
    setLoading(true);
    try {
      const legalFace = await legalAPI.getCompanyById2(id);
      dispatch(setCompanyAC(legalFace));
      history.push(`/settings/legal/${legalFace.id}`);
    } catch (e) {
      dispatch(setErrorAC(e?.message));
    }
    setLoading(false);
  };

  const handleSort = useCallback((columnId) => {
    setSortState(prevState => {
      const newDirection =
        prevState.columnId === columnId && prevState.direction === 'ascending'
          ? 'descending'
          : 'ascending';

      return {
        ...prevState,
        columnId,
        direction: newDirection,
        getSortDirection: (id) => {
          if (id !== columnId) return null;
          return newDirection;
        }
      };
    });

    const sortedLegalFaces = [...legalFaces].sort((a, b) => {
      const aValue = a[columnId];
      const bValue = b[columnId];

      if (aValue === bValue) return 0;

      const direction = sortState.columnId === columnId && sortState.direction === 'ascending' ? -1 : 1;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * direction;
      }

      return (aValue > bValue ? 1 : -1) * direction;
    });

    setLegalFaces(sortedLegalFaces);
  }, [legalFaces, sortState]);

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);

    const reorderedLegalFaces = legalFaces.map(item => {
      const newItem = {};
      newColumns.forEach(column => {
        newItem[column.columnId] = item[column.columnId];
      });
      return { ...item, ...newItem };
    });
    setLegalFaces(reorderedLegalFaces);
  }, [columns, legalFaces]);

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <h1 className={g.title}>Юридические лица</h1>
        <SearchBox
          styles={searchBoxStyles}
          placeholder="Поиск по Названию компании"
          value={query.searchValue}
          onClear={() => {
            onSearchTermChanged('');
          }}
          onSearch={(newValue) => onSearchTermChanged(newValue)}
        />
      </div>
      <div className={s.checkBoxWrapper}>
        {checkboxes?.map((option) => (
          <div className={s.singleCheckbox} key={option.variableName}>
            <Checkbox onChange={option.onChange} disabled={false} checked={option.value} />
            <span className={s.checkboxText}>{option.title}</span>
          </div>
        ))}
      </div>

      <div style={{ margin: '0.75rem 0 0.75rem 1rem' }}>
        <LinkButton
          isButton={true}
          text="Добавить юрлицо"
          primary={true}
          onClick={() => history.push(`legal/add`)}
        />
      </div>

      <Table
        headerItems={columns}
        maxHeight="50vh"
        loading={loading}
        onSort={handleSort}
        sortState={sortState}
        onColumnReorder={handleColumnReorder}
        tableName="legalFaces"
        customHeaderClass={s.tableHeader}
      >
        {!!legalFaces?.length &&
          legalFaces?.map((item) => {
            return (
              <div key={item.id} className={s.tableItem}>
                {columns.map((column) => {
                  let content;
                  let tooltipContent;

                  switch (column.columnId) {
                    case 'name':
                      content = item.name;
                      tooltipContent = content;
                      break;
                    case 'inn':
                      content = item.inn;
                      tooltipContent = content;
                      break;
                    case 'productNumber':
                      content = item.productNumber;
                      tooltipContent = content;
                      break;
                    case 'actions':
                      return (
                        <div
                          key={column.columnId}
                          className={clsx(s.tableActionCell)}
                          style={{
                            width: `calc((100vw - 180px - 3.75rem) * ${column.width})`,
                            minWidth: column.minWidth
                          }}
                        >
                          <LinkButton
                            addStyles={clsx(s.linkButton)}
                            link={`/settings/legal/${item.id}`}
                            isButton={true}
                            text="Подробнее"
                          />
                        </div>
                      );
                  }

                  return (
                    <div
                      key={column.columnId}
                      className={clsx(s.tableCell)}
                      style={{
                        width: `calc((100vw - 180px - 3.75rem) * ${column.width})`,
                        minWidth: column.minWidth
                      }}
                    >
                      <div>
                        <Tooltip
                          content={tooltipContent}
                          delay={500}
                          directionalHint={DirectionalHint.topCenter}
                        >
                          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {content}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </Table>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};

export default LegalFacesPage;
