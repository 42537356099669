import { Pagination } from 'components/Pagination/Pagination';
import { Table } from 'components/Table/Table';
import { TableAction } from 'components/TableAction/TableAction';
import { Checkbox, SearchBox } from '@fluentui/react';
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import ProductsAPI from '../../../api/productsAPI';
import { codeFilters, orderCodesIcon, orderStatuses, searchBoxStyles } from '../../../constants';
import { useCrumbsContext } from '../../../context/CrumbsContext';
import { getDateFromTimestamp, getStatusById } from '../../../functions';
import { useCheckboxFilter, usePaginationAndSearch } from '../../../hooks';
import { cellWidth } from '../../../functions';
import g from '../../../assets/scss/Main.module.scss';
import s from './CodesList.module.scss';
import { Tooltip, useTableFeatures, useTableSort } from '@fluentui/react-components';
import { DirectionalHint, TooltipDelay, TooltipHost } from '@fluentui/react';
import clsx from 'clsx';
import { LinkELement } from 'components/LinkELement/LinkELement';

const tableHeader = [
  {
    title: 'Код маркировки',
    width: '30',
    columnId: 'value',
    sortable: true,
  },
  {
    title: 'Заказан',
    width: '14',
    columnId: 'creationDate',
    sortable: true,
  },
  {
    title: 'Нанесён',
    width: '14',
    columnId: 'utilisationDate',
    sortable: true,
  },
  {
    title: 'Добавлен в агрегат',
    width: '14',
    columnId: 'aggregationDate',
    sortable: true,
  },
  {
    title: 'Введён в оборот',
    width: '14',
    columnId: 'rolloutDate',
    sortable: true,
  },
  {
    title: 'Код агрегата',
    width: '14',
    columnId: 'unitSerialNumber',
    sortable: true,
  }
];

export const CodesList = ({ isOpenFilters, setIsOpenFilters, status, statusParamName }) => {
  const { setCrumbs } = useCrumbsContext();
  const { state: params, pathname, href } = useLocation();
  const [_status, _setStatus] = useState(status);
  const [_statusParamName, _setStatusParamName] = useState(statusParamName);
  const [codes, setCodes] = useState([]);
  const [_codesFilters] = useState(codeFilters);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [savedFilterValues, setSavedFilterValues] = useState({
    minAggregationDate: null,
    maxAggregationDate: null,
    minUtilisationDate: null,
    maxUtilisationDate: null,
    minCreationDate: null,
    maxCreationDate: null,
    minRolloutDate: null,
    maxRolloutDate: null
  });

  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    quantityOnPage: NumberParam,
    productId: NumberParam,
    orderId: NumberParam,
    status: StringParam,
    searchValue: StringParam,
    minAggregationDate: NumberParam,
    maxAggregationDate: NumberParam,
    minUtilisationDate: NumberParam,
    maxUtilisationDate: NumberParam,
    minCreationDate: NumberParam,
    maxCreationDate: NumberParam,
    minRolloutDate: NumberParam,
    maxRolloutDate: NumberParam
  });

  const [columns, setColumns] = useState(() => {
    // Пытаемся получить сохраненное состояние
    try {
      const savedState = sessionStorage.getItem('table_codes_state');

      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);

        if (columnOrder && Array.isArray(columnOrder)) {
          // Восстанавливаем порядок колонок из сохраненного состояния
          const columnMap = new Map(tableHeader.map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          // Добавляем колонки, которых нет в сохраненном состоянии
          tableHeader.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return tableHeader;
  });

  const {
    sort: { getSortDirection, toggleColumnSort },
  } = useTableFeatures(
    {
      columns: columns,
      items: codes,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: 'creationDate', sortDirection: 'ascending' },
      }),
    ]
  );

  const handleSort = (columnId) => {
    const event = { preventDefault: () => { } };
    const currentDirection = getSortDirection(columnId);

    toggleColumnSort(event, columnId);

    const direction = currentDirection === 'ascending' ? -1 : 1;

    const sortedCodes = [...codes].sort((a, b) => {
      if (columnId === 'value' || columnId === 'unitSerialNumber') {
        const valueA = a[columnId] ?? '';
        const valueB = b[columnId] ?? '';
        return String(valueA).localeCompare(String(valueB)) * direction;
      }

      // Для дат
      if (['creationDate', 'utilisationDate', 'aggregationDate', 'rolloutDate'].includes(columnId)) {
        return (new Date(a[columnId] || 0) - new Date(b[columnId] || 0)) * direction;
      }

      const valueA = a[columnId] ?? '';
      const valueB = b[columnId] ?? '';
      return String(valueA).localeCompare(String(valueB)) * direction;
    });

    setCodes(sortedCodes);
  };

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);

    // Обновляем данные в соответствии с новым порядком колонок
    const reorderedCodes = codes.map(item => {
      const newItem = {};
      newColumns.forEach(column => {
        newItem[column.columnId] = item[column.columnId];
      });
      return { ...item, ...newItem };
    });
    setCodes(reorderedCodes);
  }, [columns, codes]);

  useEffect(() => {
    setCrumbs(['/products', 'Список КМ продукта']);

    if (query.orderId) {
      setCrumbs(['/orders', 'Список КМ в заказе']);
    }
  }, [query]);

  useEffect(() => {
    if (params) {
      if (params.status && !query.status) {
        setQuery((prev) => ({ ...prev, status: params.status }), 'replace');
      }

      if (params.orderId) {
        setQuery((prev) => ({ ...prev, productId: undefined, orderId: params.orderId }), 'replace');
      }

      if (params.productId) {
        setQuery(
          (prev) => ({ ...prev, orderId: undefined, productId: params.productId }),
          'replace'
        );
      }
    } else {
      // setQuery({})
    }
  }, [params]);

  const getCodes = async (query) => {
    setLoading(true);
    const res = await ProductsAPI.getCodesForProduct(query);
    const { list, quantity, info } = res;
    setCodes(list);
    setTotalItems(quantity);
    setInfo(info);
    await setLoading(false);
  };

  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    _codesFilters,
    'status',
    setQuery,
    query
  );
  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getCodes,
      query,
      setQuery,
      status: status || params?.status,
      statusParamName: statusParamName || params?.statusParamName,
      headerHeight: 220
    });

  return (
    <>
      {/*{params ?*/}
      <div className={g.contentWrapper}>
        <div className={g.titleWrapper}>
          {query.orderId && info && (
            <h1 className={g.title}>
              {`Заказ ${info?.suzId || 'без номера'} (${getStatusById(info?.status, orderStatuses) || ''
                })` || ''}
            </h1>
          )}
          {query.productId && info && <h1 className={g.title}>{info?.name || ''}</h1>}
          {query.productId && info && (
            <TableAction
              iconName={orderCodesIcon}
              link="/products/orderCode"
              params={{
                productName: info?.name,
                templateId: info?.templateId,
                productGtin: info?.gtin,
                prevRoute: `${pathname}${href}`
              }}
              text="Заказать КМ"
            />
          )}
        </div>
        {info?.gtin && (
          <span className={g.code}>
            {params?.orderId ? `${info?.name} (GTIN: ${info?.gtin})` : `GTIN: ${info?.gtin}`}
          </span>
        )}
        <div className={s.header} style={{ marginTop: 20 }}>
          <div className={s.headerSearch}>
            <span className={g.headerTitleWrapper}>Коды маркировки</span>
            <SearchBox
              styles={searchBoxStyles}
              className={s.search}
              placeholder="Поиск по коду маркировки"
              onClear={() => {
                onSearchTermChanged('');
              }}
              onSearch={(newValue) => onSearchTermChanged(newValue)}
            />
          </div>
          <div className={g.headerFilters} style={{ marginTop: 20 }}>
            {codeFilters?.map((item) => {
              return (
                <Checkbox
                  key={item.id}
                  label={item.title}
                  checked={selectedFilters.has(item.key)}
                  onChange={() => onChangeFilter(item.key)}
                />
              );
            })}
          </div>
        </div>
        <div className={s.contentWrapper}>
          <div className={s.tableWrapper || g.tableWrapper}>
            <Table
              headerItems={columns}
              loading={loading}
              onSort={handleSort}
              sortState={{ getSortDirection }}
              onColumnReorder={handleColumnReorder}
              tableName="codes"
              customHeaderClass={s.tableHeader}
              maxTableWidth='calc(100vw - 180px - 70px)'
            >
              {codes?.map((item) => {
                return (
                  <div key={item.id} className={s.tableItem || g.tableItem}>
                    {columns.map((column) => {
                      let content;
                      let tooltipContent;

                      if (column.columnId === 'value') {
                        content = item.value;
                        tooltipContent = item.value;
                      } else if (column.columnId === 'creationDate') {
                        content = getDateFromTimestamp(item.creationDate);
                        tooltipContent = content;
                      } else if (column.columnId === 'utilisationDate') {
                        content = getDateFromTimestamp(item.utilisationDate);
                        tooltipContent = content;
                      } else if (column.columnId === 'aggregationDate') {
                        content = getDateFromTimestamp(item.aggregationDate);
                        tooltipContent = content;
                      } else if (column.columnId === 'rolloutDate') {
                        content = getDateFromTimestamp(item.rolloutDate);
                        tooltipContent = content;
                      } else if (column.columnId === 'unitSerialNumber') {
                        content = item.unitSerialNumber ? (
                          <LinkELement
                            addStyles={clsx(s.tableRow)}
                            link={`/aggregates/${item.unitSerialNumber}`}
                            text={item.unitSerialNumber}
                            appearance={'subtle'}
                          />
                        ) : null;
                        tooltipContent = item.unitSerialNumber;
                      }

                      return (
                        <div
                          key={column.columnId}
                          className={clsx(s.tableCell || g.tableCell)}
                        >
                          <div>
                            <Tooltip
                              content={tooltipContent}
                              delay={300}
                              directionalHint={DirectionalHint.topCenter}
                            >
                              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {content}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Table>
          </div>
        </div>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
      {/*: <Redirect to='/products'/>}*/}
    </>
  );
};
