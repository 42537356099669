import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import s from './index.module.scss';
import g from '../../assets/scss/Main.module.scss';
import { DirectionalHint, SearchBox } from '@fluentui/react';
import { moreInfoIcon, searchBoxStyles } from '../../constants';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { Pagination } from '../../components/Pagination/Pagination';
import { useCrumbsContext } from '../../context/CrumbsContext';
import LegalAPI from '../../api/legalAPI';
import { usePaginationAndSearch } from '../../hooks';
import { Checkbox } from '@fluentui/react';
import { addIcon } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getCompanyInfo } from 'redux/LegalFaces/legalFacesSelectors';
import clsx from 'clsx';
import { setCompanyAC, setErrorAC } from 'redux/LegalFaces/legalFacesReducer';
import productsAPI from 'api/productsAPI';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { Tooltip } from '@fluentui/react-components';

const tableHeader = [
  {
    title: 'Название',
    width: '0.4',
    columnId: 'name',
    sortable: true
  },
  {
    title: 'ИНН',
    width: '0.3',
    columnId: 'inn',
    sortable: true
  },
  {
    title: 'Количество продуктов',
    width: '0.3',
    columnId: 'productNumber',
    sortable: true
  }
  //   {
  //     title: 'Действия',
  //     width: '0.15'
  //   }
];

const ProductOwners = () => {
  const { setCrumbs } = useCrumbsContext();
  const [legalFaces, setLegalFaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    searchValue: StringParam,
    quantityOnPage: NumberParam,
    roleName: StringParam
  });
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(getCompanyInfo);
  const history = useHistory();
  const [columns, setColumns] = useState(() => {
    try {
      const savedState = sessionStorage.getItem('table_productowners_state');
      if (savedState) {
        const { columnOrder } = JSON.parse(savedState);
        if (columnOrder && Array.isArray(columnOrder)) {
          const columnMap = new Map(tableHeader.map(col => [col.columnId, col]));
          const orderedColumns = columnOrder
            .map(id => columnMap.get(id))
            .filter(Boolean);
          tableHeader.forEach(col => {
            if (!orderedColumns.find(c => c.columnId === col.columnId)) {
              orderedColumns.push(col);
            }
          });
          return orderedColumns;
        }
      }
    } catch (error) {
      console.warn('Error loading saved column order:', error);
    }
    return tableHeader;
  });

  useEffect(() => {
    setCrumbs([]);
    setQuery(query, 'replace');
  }, []);

  const getLegalFaces = async (isActive, isArchived) => {
    setLoading(true);
    //let res = await LegalAPI.getCompanies(query);
    let res = await productsAPI.getAllProductOwners();
    console.log('RES-OWNERS', res);
    setLegalFaces(res);
    setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, currentPage } = usePaginationAndSearch({
    callback: () => getLegalFaces(true, false),
    query,
    setQuery
  });

  const handleColumnReorder = useCallback((sourceIndex, destinationIndex) => {
    const newColumns = [...columns];
    const [removed] = newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, removed);
    setColumns(newColumns);

    const reorderedOwners = legalFaces.map(item => {
      const newItem = {};
      newColumns.forEach(column => {
        newItem[column.columnId] = item[column.columnId];
      });
      return { ...item, ...newItem };
    });
    setLegalFaces(reorderedOwners);
  }, [columns, legalFaces]);

  // Create a sort state object
  const [sortState, setSortState] = useState({
    column: null,
    direction: null,
    getSortDirection: (columnId) => {
      return sortState.column === columnId ? sortState.direction : null;
    }
  });

  const handleSort = useCallback((columnId) => {
    setSortState(prevState => {
      const isAsc = prevState.column === columnId && prevState.direction === 'ascending';
      const newDirection = isAsc ? 'descending' : 'ascending';

      // Sort the data
      const sortedData = [...legalFaces].sort((a, b) => {
        if (a[columnId] === null) return 1;
        if (b[columnId] === null) return -1;

        const compareA = typeof a[columnId] === 'string' ? a[columnId].toLowerCase() : a[columnId];
        const compareB = typeof b[columnId] === 'string' ? b[columnId].toLowerCase() : b[columnId];

        if (compareA < compareB) return isAsc ? 1 : -1;
        if (compareA > compareB) return isAsc ? -1 : 1;
        return 0;
      });

      setLegalFaces(sortedData);

      return {
        ...prevState,
        column: columnId,
        direction: newDirection,
        getSortDirection: (colId) => {
          return colId === columnId ? newDirection : null;
        }
      };
    });
  }, [legalFaces]);

  //   const handleDetailsClick = async (id) => {
  //     setLoading(true);
  //     try {
  //       const legalFace = await LegalAPI.getCompanyById2(id);
  //       dispatch(setCompanyAC(legalFace));
  //       history.push(`/legal/${legalFace.id}`);
  //     } catch (e) {
  //       dispatch(setErrorAC(e?.message));
  //     }
  //     setLoading(false);
  //   };

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <h1 className={g.title}>Владельцы продуктов</h1>
        {/* <SearchBox
          styles={searchBoxStyles}
          placeholder="Поиск по Названию компании"
          value={query.searchValue}
          onClear={() => {
            onSearchTermChanged('');
          }}
          onSearch={(newValue) => onSearchTermChanged(newValue)}
        /> */}
      </div>

      <div className={g.footer} style={{ margin: ' 0.75rem 0  0.75rem 1rem' }}>
        <LinkButton
          isButton={true}
          text="Добавить владельца продукта"
          primary={true}
          onClick={() => history.push(`productowners/add`)}
        />
      </div>

      <div className={s.checkBoxWrapper}></div>
      <Table
        headerItems={columns}
        maxHeight="50vh"
        loading={loading}
        onSort={handleSort}
        sortState={sortState}
        onColumnReorder={handleColumnReorder}
        tableName="productowners"
        customHeaderClass={s.tableHeader}
      >
        {legalFaces?.map((item) => {
          return (
            <div key={item.id} className={s.tableItem}>
              {columns.map(column => {
                let content;
                let tooltipContent;

                switch (column.columnId) {
                  case 'name':
                    content = item.name;
                    tooltipContent = content;
                    break;
                  case 'inn':
                    content = item.inn;
                    tooltipContent = content;
                    break;
                  case 'productNumber':
                    content = item.productNumber;
                    tooltipContent = content;
                    break;
                  default:
                    content = '';
                    tooltipContent = content;
                }

                return (
                  <div
                    key={column.columnId}
                    className={s.tableCell}
                    style={{
                      width: `calc((100vw - 180px - 3.75rem) * ${column.width})`,
                      minWidth: column.minWidth
                    }}
                  >
                    <Tooltip
                      content={tooltipContent}
                      delay={500}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {content}
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          );
        })}
      </Table>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};

export default ProductOwners;
