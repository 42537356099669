import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getToggleMenuData } from 'redux/App/appSelectors';

export const useTableColumnResizing = (initialColumnWidths = {}, headerItems = [], tableName = '', maxTableWidth = null, spaceLeft = 35) => {
  const storageKey = tableName ? `table_${tableName}_state` : 'table_default_state';
  const toggMenu = useSelector(getToggleMenuData)
  const instanceId = useRef(`table-${tableName || 'default'}-${Math.random().toString(36).substring(2, 9)}`);

  useEffect(() => {
    setColumnOrder(initialState.columnOrder)
    setColumnWidths(initialState.columnWidths)
  }, [tableName, toggMenu]);

  const getSavedState = useCallback(() => {
    try {
      const savedState = sessionStorage.getItem(storageKey);
      if (savedState) {
        const parsed = JSON.parse(savedState);
        // Проверяем валидность сохраненных данных и соответствие текущим колонкам
        if (parsed?.columnWidths && Array.isArray(parsed?.columnOrder)) {
          // Проверяем, что сохраненные колонки соответствуют текущим
          const currentColumnIds = headerItems.map(item => item?.columnId).filter(Boolean);
          const savedColumnIds = Object.keys(parsed.columnWidths);

          // Если набор колонок изменился, не используем сохраненное состояние
          if (!currentColumnIds.every(id => savedColumnIds.includes(id)) ||
            !savedColumnIds.every(id => currentColumnIds.includes(id))) {
            return null;
          }
          return parsed;
        }
      }
    } catch (error) {
      console.warn('Error loading saved table state:', error);
    }
    return null;
  }, [storageKey, headerItems]);

  const saveState = useCallback((state) => {
    try {
      if (state.columnOrder && Array.isArray(state.columnOrder)) {
        sessionStorage.setItem(storageKey, JSON.stringify(state));
      }
    } catch (error) {
      console.warn('Error saving table state:', error);
    }
  }, [storageKey]);


  const getTotalWidth = useCallback(() => {
    const tableElement = document.querySelector(`.${instanceId.current}`);
    // Получаем ширину меню
    const menuElement = document.querySelector('[class*="Menu_nav"]');
    const menuWidth = menuElement ? menuElement.offsetWidth + spaceLeft : 0;

    // Вычисляем доступную ширину с учетом меню
    const availableWidth = window.innerWidth - menuWidth;

    const baseWidth = tableElement?.clientWidth && tableElement.clientWidth < availableWidth
      ? tableElement.clientWidth
      : availableWidth;

    if (maxTableWidth) {
      if (typeof maxTableWidth === 'string' && (maxTableWidth.includes('calc') ||
        maxTableWidth.includes('vw') || maxTableWidth.includes('vh') ||
        maxTableWidth.includes('rem') || maxTableWidth.includes('%'))) {

        const tempEl = document.createElement('div');
        tempEl.style.position = 'absolute';
        tempEl.style.visibility = 'hidden';
        tempEl.style.width = maxTableWidth;
        document.body.appendChild(tempEl);

        const computedWidth = tempEl.getBoundingClientRect().width;
        document.body.removeChild(tempEl);

        return Math.min(baseWidth, computedWidth);
      }
      else {
        const numericWidth = parseFloat(maxTableWidth);
        if (!isNaN(numericWidth)) {
          return Math.min(baseWidth, numericWidth);
        }
      }
    }

    return baseWidth;
  }, [maxTableWidth, instanceId]);

  // Мемоизируем headerItems Map для быстрого доступа
  const headerItemsMap = useMemo(() => {
    return new Map(
      headerItems.filter(item => item?.columnId).map(item => [
        item.columnId,
        { minWidth: item.minWidth || 50, width: item.width }
      ])
    );
  }, [headerItems]);

  // Мемоизируем начальные ширины и порядок
  const initialState = useMemo(() => {
    try {
      const savedState = getSavedState();
      const defaultOrder = headerItems.map(item => item?.columnId).filter(Boolean);

      // Если нет сохраненного состояния, создаем начальные ширины
      if (!savedState) {
        const totalWidth = getTotalWidth();
        const totalFlex = headerItems.reduce((sum, item) => sum + (parseFloat(item?.width) || 0), 0);
        const widths = {};

        headerItems.forEach(item => {
          if (!item?.columnId) return;
          const flexRatio = totalFlex === 0
            ? 1 / headerItems.length
            : (parseFloat(item.width || 0) / totalFlex);
          widths[item.columnId] = Math.max(
            item.minWidth || 50,
            totalWidth * flexRatio
          );
        });

        return {
          columnWidths: widths,
          columnOrder: defaultOrder
        };
      }

      // Проверяем валидность сохраненного состояния
      const hasAllColumns = defaultOrder.every(id =>
        savedState.columnOrder.includes(id)
      );
      const hasValidWidths = defaultOrder.every(id =>
        typeof savedState.columnWidths[id] === 'number'
      );

      if (hasAllColumns && hasValidWidths) {
        return savedState;
      }

      // Если сохраненное состояние невалидно, создаем новое
      return {
        columnWidths: initialColumnWidths,
        columnOrder: defaultOrder
      };
    } catch (error) {
      console.warn('Error initializing table state:', error);
      return {
        columnWidths: initialColumnWidths,
        columnOrder: headerItems.map(item => item?.columnId).filter(Boolean)
      };
    }
  }, [getSavedState, initialColumnWidths, headerItems, getTotalWidth]);

  const [columnWidths, setColumnWidths] = useState(initialState.columnWidths);
  const [columnOrder, setColumnOrder] = useState(initialState.columnOrder);
  const [isResizing, setIsResizing] = useState(false);

  // Используем один ref для всего состояния ресайза
  const resizeState = useRef({
    column: null,
    startX: null,
    startWidth: null,
    startWidths: null,
    columnIndex: null
  });

  // Мемоизируем функцию получения ширины колонки
  const getColumnWidth = useCallback((columnId, defaultWidth = 100) => {
    return columnWidths[columnId] ?? defaultWidth;
  }, [columnWidths]);

  // Получаем упорядоченные заголовки на основе сохраненного порядка
  const orderedHeaderItems = useMemo(() => {
    if (!headerItems?.length || !columnOrder?.length) return headerItems;

    // Создаем карту для быстрого доступа к заголовкам
    const headerMap = new Map(headerItems.map(item => [item.columnId, item]));

    // Возвращаем заголовки в порядке, соответствующем columnOrder
    return columnOrder
      .map(columnId => headerMap.get(columnId))
      .filter(Boolean);
  }, [headerItems, columnOrder]);

  // Оптимизированная функция расчета доступного пространства
  const calculateAvailableSpace = useCallback((columns, spaceNeeded, startWidths) => {
    let available = 0;
    const newWidths = {};

    for (const col of columns) {
      if (!col?.columnId) continue;
      const minWidth = headerItemsMap.get(col.columnId)?.minWidth || 50;
      const currentWidth = startWidths[col.columnId];
      const canTake = currentWidth - minWidth;

      if (canTake > 0) {
        const take = Math.min(canTake, spaceNeeded - available);
        newWidths[col.columnId] = currentWidth - take;
        available += take;
        if (available >= spaceNeeded) break;
      }
    }

    return { available, newWidths };
  }, [headerItemsMap]);

  // Обновляем состояние и сохраняем в localStorage
  const updateState = useCallback((newWidths, newOrder) => {
    const validOrder = Array.isArray(newOrder) ? newOrder : columnOrder;
    setColumnWidths(newWidths);
    setColumnOrder(validOrder);
    saveState({ columnWidths: newWidths, columnOrder: validOrder });
  }, [columnOrder, saveState]);

  const handleResizeStart = useCallback((e, columnId) => {
    if (!columnId || !orderedHeaderItems?.length) return;

    const columnIndex = orderedHeaderItems.findIndex(item => item?.columnId === columnId);
    if (columnIndex === -1 || columnIndex === orderedHeaderItems.length - 1) return;

    e.stopPropagation();
    e.preventDefault(); // Prevent default touch behavior

    // Get the correct X coordinate for both mouse and touch events
    const pageX = e.type.includes('touch')
      ? e.touches[0].pageX
      : e.pageX;

    setIsResizing(true);
    resizeState.current = {
      column: columnId,
      startX: pageX,
      startWidth: columnWidths[columnId],
      startWidths: { ...columnWidths },
      columnIndex
    };
  }, [columnWidths, orderedHeaderItems]);

  const handleResizeMove = useCallback((e) => {
    if (!isResizing || !resizeState.current.column) return;

    const { column, startX, startWidth, startWidths, columnIndex } = resizeState.current;

    // Get the correct X coordinate for both mouse and touch events
    const currentX = e.type.includes('touch')
      ? e.touches[0].pageX
      : e.pageX;

    const diff = currentX - startX;
    const isMovingRight = diff > 0;

    try {
      const newWidths = { ...startWidths };
      const totalWidth = getTotalWidth();

      if (isMovingRight) {
        // Движение вправо - забираем пространство у правых колонок
        const rightColumns = orderedHeaderItems.slice(columnIndex + 1);
        const spaceNeeded = Math.max(
          headerItemsMap.get(column)?.minWidth || 50,
          startWidth + diff
        ) - startWidth;

        const { available, newWidths: rightWidths } = calculateAvailableSpace(
          rightColumns,
          spaceNeeded,
          startWidths
        );

        if (available > 0) {
          Object.assign(newWidths, rightWidths);
          newWidths[column] = startWidth + available;
        }
      } else {
        // Движение влево - забираем пространство у левых колонок
        const leftColumns = orderedHeaderItems.slice(0, columnIndex + 1).reverse();
        const nextColumn = orderedHeaderItems[columnIndex + 1];
        const spaceNeeded = Math.abs(diff);

        const { available, newWidths: leftWidths } = calculateAvailableSpace(
          leftColumns,
          spaceNeeded,
          startWidths
        );

        if (available > 0) {
          Object.assign(newWidths, leftWidths);
          newWidths[nextColumn.columnId] = startWidths[nextColumn.columnId] + available;
        }
      }

      // Проверяем общую ширину
      const newTotalWidth = Object.values(newWidths).reduce((sum, w) => sum + w, 0);
      if (Math.abs(newTotalWidth - totalWidth) <= 1) {
        updateState(newWidths, columnOrder);
      }
    } catch (error) {
      console.error('Error in handleResizeMove:', error);
    }
  }, [isResizing, orderedHeaderItems, headerItemsMap, calculateAvailableSpace, getTotalWidth, updateState, columnOrder]);

  const handleResizeEnd = useCallback(() => {
    setIsResizing(false);
    resizeState.current = {
      column: null,
      startX: null,
      startWidth: null,
      startWidths: null,
      columnIndex: null
    };
  }, []);

  useEffect(() => {
    if (!isResizing) return;

    const handleMouseMove = (e) => {
      e.preventDefault();
      requestAnimationFrame(() => {
        const tableElement = document.querySelector(`.${instanceId.current}`);
        if (tableElement) {
          tableElement.style.willChange = 'transform';
        }
        handleResizeMove(e);
      });
    };

    const handleMouseUp = () => {
      // Используем уникальный идентификатор для поиска конкретной таблицы
      const tableElement = document.querySelector(`.${instanceId.current}`);
      if (tableElement) {
        tableElement.style.willChange = 'auto';
      }
      handleResizeEnd();
    };

    const handleBlur = handleMouseUp;

    document.addEventListener('mousemove', handleMouseMove, { passive: false });
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleMouseMove, { passive: false });
    document.addEventListener('touchend', handleMouseUp);
    window.addEventListener('blur', handleBlur);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
      window.removeEventListener('blur', handleBlur);
    };
  }, [isResizing, handleResizeMove, handleResizeEnd, instanceId]);

  useEffect(() => {
    const handleResize = () => {
      const totalWidth = getTotalWidth();
      const totalCurrentWidth = Object.values(columnWidths).reduce((sum, width) => sum + width, 0);
      // Проверяем, нужно ли обновлять ширины
      if (Math.abs(totalCurrentWidth - totalWidth) > 1) {
        const scaleFactor = totalWidth / totalCurrentWidth;

        const newWidths = {};
        headerItems.forEach(item => {
          if (!item?.columnId) return;
          const currentWidth = columnWidths[item.columnId];
          const minWidth = headerItemsMap.get(item.columnId)?.minWidth || 50;
          const newWidth = Math.max(minWidth, currentWidth * scaleFactor);
          newWidths[item.columnId] = newWidth;
        });

        setColumnWidths(newWidths);
      }
    };

    // Добавляем обработчик изменения размера окна
    window.addEventListener('resize', handleResize);
    // Вызываем handleResize один раз после монтирования
    console.log("ento samoye", columnWidths);

    console.log("odin raz nadelol");
    const timeoutId = setTimeout(() => { console.log("chavoi nadelol"); handleResize() }, 100);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, [columnWidths, headerItems, headerItemsMap, getTotalWidth]);

  // Добавляем эффект для обновления ширин при изменении порядка колонок
  useEffect(() => {
    try {
      const newWidths = {};
      const totalWidth = getTotalWidth();
      let currentTotal = 0;

      // Пересчитываем ширины в новом порядке
      headerItems.forEach(item => {
        if (item?.columnId) {
          const width = columnWidths[item.columnId] || 0;
          newWidths[item.columnId] = width;
          currentTotal += width;
        }
      });

      // Если общая ширина изменилась, масштабируем колонки
      if (Math.abs(currentTotal - totalWidth) > 1) {
        const scaleFactor = totalWidth / currentTotal;
        Object.keys(newWidths).forEach(key => {
          const item = headerItems?.find(h => h.columnId === key);
          newWidths[key] = Math.max(
            item?.minWidth || 50,
            newWidths[key] * scaleFactor
          );
        });
      }

      setColumnWidths(newWidths);
    } catch (error) {
      console.error('Error updating widths after reorder:', error);
    }
  }, [getTotalWidth]);

  // Возвращаем дополнительные функции для работы с сохраненным состоянием
  return {
    isResizing,
    handleResizeStart,
    getColumnWidth,
    columnWidths,
    columnOrder: columnOrder || [], // Гарантируем, что всегда возвращается массив
    updateState,
    tableId: instanceId.current, // Возвращаем уникальный идентификатор таблицы
    orderedHeaderItems // Возвращаем упорядоченные заголовки
  };
};
